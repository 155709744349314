import create from 'zustand';
import { persist } from 'zustand/middleware';
import { plot } from './types';
import { getColorfromIndex, getAxesRange } from "./DataFunctions";



/**
 * THIS IS A CENTRAL STATEMANAGEMENT. IT ALLOWS CENTRALIZED DATA, AND CHANGES HERE CAN TRIGGER COMPONENTS UPDATING.
 * 
 * 
 * Note: this is a bit confusing, with few ways to simplify it. Handle with care.
 */




// do I need to store the current live values before they have been submitted? I don't think so, but...
interface SpectraplotState {

    plots: plot[],
    swapPlot: plot,
    consoleLog: string,
    showVoronoi: boolean,
    visibilityToggle: boolean,
    yMax: number,
    setYMax: (newYMax: number) => void,
    setShowVoronoi: (shouldShow: boolean) => void,
    storeNewInputs: (newInputs: plot) => void,
    printState: () => void,
    addOutputToMatchingInput: (outputObject: plot) => void,
    addErrorToMatchingInputs: (inputObject: plot, errorMessage: string) => void,
    clearPlots: () => void,
    clearSinglePlot: (plotToRemove: plot) => void,
    addColorForPlot: (colorToAdd: string, plot: plot) => void,
    changeVisibility: (plotToUnsee: plot) => void,
    setSwapPlot: (plotToSwap: plot) => void,
    clearSwapPlot: () => void,
    changeLegendVisibility: (plotToChange: plot) => void

}

const getIndexOfPlot = (state: SpectraplotState, plotToFind: plot): number => {
    let foundIndex = -1;
    state.plots.forEach((plot, index) => {
        if (plot.species === plotToFind.species && plot.database === plotToFind.database &&
            plot.inputs.map((e, i) => JSON.stringify(plot.inputs[i])).sort().toString() === plotToFind.inputs.map((e, i) => JSON.stringify(plotToFind.inputs[i])).sort().toString()) {

            foundIndex = index;
        }
    });

    return foundIndex;
}

const storeNewInputsHelper = (state: SpectraplotState, newInputs: plot) => {
    const tmp = state.plots;
    const index = getIndexOfPlot(state, newInputs);
    if (index > -1) { // there is alreadt a plot with identical inputs, db, etc...
        tmp[index] = newInputs;
    }
    else {
        tmp.push(newInputs);
    }
    return {
        plots: tmp
    };
}

const clearPlotsHelper = (state: SpectraplotState) => {

    return {
        consoleLog: '',
        plots: [],
        swapPlot: {} as plot
    };
}

const addOutputToMatchingInputHelper = (state: SpectraplotState, outputObject: plot) => {
    //should just be able to iterate through the plots because there shgouldn't be that many.
    //    console.log(outputObject.output?.length);
    
    const tmp = state.plots;
    console.log(state, outputObject);
    console.log('hello');
    console.log(outputObject.notes);
    const index = getIndexOfPlot(state, outputObject);
    if (index > -1) {

        tmp[index].output = outputObject.output;
        tmp[index].isVisible = true;
        tmp[index].color = getColorfromIndex(index);
        tmp[index].axesRange = getAxesRange(tmp);
        if(outputObject.legend) {
            tmp[index].legend = outputObject.legend;
        }
    }

    return {
        consoleLog: outputObject.notes + '\n' + state.consoleLog,
        plots: tmp
    };
}

const addErrorToMatchingInputsHelper = (state: SpectraplotState, inputObject: plot, errorMessage: string) => {
    //   console.log(inputObject);
    const tmp = state.plots;
    const index = getIndexOfPlot(state, inputObject);
    if (index > -1) {


        tmp[index].isVisible = false;
        tmp[index].errorMessage = errorMessage;

    }


    return {
        consoleLog: errorMessage + '\n' + state.consoleLog,
        plots: tmp
    };

}

const addColorForPlotHelper = (state: SpectraplotState, colorToAdd: string, plot: plot) => {
    //this whole pattern is repeated a few times, but I can't think of how to create a separate function
    const tmp = state.plots;
    console.log(colorToAdd, plot);
    const index = getIndexOfPlot(state, plot);
    if (index > -1) {

        tmp[index].color = colorToAdd;
    }

    return {
        plots: tmp,
        visibilityToggle: !state.visibilityToggle
    };

}

const clearSinglePlotHelper = (state: SpectraplotState, plotToRemove: plot) => {

    const tmp = state.plots.filter((plotItem) => {
        return plotToRemove.species !== plotItem.species || plotToRemove.database !== plotItem.database ||
            plotToRemove.inputs.map((e, i) => JSON.stringify(plotToRemove.inputs[i])).sort().toString() !== plotItem.inputs.map((e, i) => JSON.stringify(plotItem.inputs[i])).sort().toString();
    })

    return {
        plots: tmp
    };
}

const setShowVoronoiHelper = (state: SpectraplotState, shouldShow: boolean) => {

    return {
        showVoronoi: shouldShow
    };
}

const printStateHelper = (state: SpectraplotState) => {
    console.log(state);
    return state;
}

const changeVisibilityHelper = (state: SpectraplotState, plotToUnsee: plot) => {

    const tmp = state.plots;
    const index = getIndexOfPlot(state, plotToUnsee);
    if (index > -1) {
        tmp[index].isVisible = !tmp[index].isVisible;
    }

    return {
        plots: tmp,
        visibilityToggle: !state.visibilityToggle
    };
}

const setSwapPlotHelper = (state: SpectraplotState, plotToSwap: plot) => {
//    console.log(plotToSwap);
    return {
        swapPlot: {
            species: plotToSwap.species,
            speciesLongName: plotToSwap.speciesLongName,
            database: plotToSwap.database,
            inputs: plotToSwap.inputs,
            color: plotToSwap.color,
            notes: plotToSwap.notes,
            isVisible: plotToSwap.isVisible,
            errorMessage: plotToSwap.errorMessage
        }
    };
}

const setNewYMaxHelper = (state: SpectraplotState, newYMax: number) => {
    
    return {
        yMax: newYMax
    };
}

const changeLegendVisibilityHelper = (state: SpectraplotState, plotToChange: plot) => {
    const tmp = state.plots;
    const index = getIndexOfPlot(state, plotToChange);
    if (index > -1) {
        if(!tmp[index].showLegend){
            tmp[index].showLegend = true;//have to do it like this because showLegend might be undefined
        }
        else {
            tmp[index].showLegend = false;
        }
    }
    return {
        plots: tmp,
        visibilityToggle: !state.visibilityToggle
    };
}

const useSpectraplotStore = create<SpectraplotState>()(

    persist(
        (set, get) => ({
            plots: [],
            swapPlot: {} as plot,
            consoleLog: '',
            showVoronoi: true,
            visibilityToggle: true,
            yMax: 0,
            setYMax:(newYMax: number) => set(state => setNewYMaxHelper(state, newYMax)),
            setShowVoronoi: (shouldShow: boolean) => set(state => setShowVoronoiHelper(state, shouldShow)),
            storeNewInputs: (newInputs) => set(state => storeNewInputsHelper(state, newInputs)),
            printState: () => set(state => printStateHelper(state)),
            addOutputToMatchingInput: (outputObject) => set(state => addOutputToMatchingInputHelper(state, outputObject)),
            clearPlots: () => set(state => clearPlotsHelper(state)),
            clearSinglePlot: (plotToRemove) => set(state => clearSinglePlotHelper(state, plotToRemove)),
            addColorForPlot: (colorToAdd, plot) => set(state => addColorForPlotHelper(state, colorToAdd, plot)),
            changeVisibility: (plotToUnsee) => set(state => changeVisibilityHelper(state, plotToUnsee)),
            setSwapPlot: (plotToSwap: plot) => set(state => setSwapPlotHelper(state, plotToSwap)),
            clearSwapPlot: () => set(state => ({ swapPlot: {} as plot })),
            addErrorToMatchingInputs: (inputObject: plot, errorMessage: string) => set(state => addErrorToMatchingInputsHelper(state, inputObject, errorMessage)),
            changeLegendVisibility: (plotToChange: plot) => set(state => changeLegendVisibilityHelper(state, plotToChange))
        }),
        {
            name: 'spectraplot-storage'
        }
    )

)

export default useSpectraplotStore;