import React from "react";
import { Autocomplete, TextField, List, ListItemButton, ListItemText, Popper, Paper, CardHeader } from "@mui/material";
import { species, xSec } from "../../Functions/types";
import { createFilterOptions } from '@mui/material';
import useSpectraplotStore from "../../Functions/Store";


type props = {
    sampleSpecies: species[],
    setSelectedSpecies: (species: string) => void,
    setCrossSections: (xSecs: xSec[]) => void,
    setDatabasesFromSpeciesName: (speciesName: string) => void,
    selectedSpecies: string
}
function ListboxComponent(props: any) {
    return (
        <>
            <div className="list-box-container"
                {...props}
            >
            </div>

        </>
    );
}

/**
 * creates a function that compiles all the keywords given for a species. This (essentially) creates a searchable string that allows the user
 * to enter text that will match anything related to the species.
 */
const autocompleteFilterOptions = createFilterOptions({
    stringify: (option: any) => option.name + option.species_search_terms
});

export const SpecInputAutocomplete: React.FC<props> = ({ sampleSpecies, setSelectedSpecies, setCrossSections, setDatabasesFromSpeciesName, selectedSpecies }) => {

    const { swapPlot } = useSpectraplotStore();

    const speciesSelected = (e: React.SyntheticEvent) => {

        const target = e.target as any;
        console.log(target.id);
        setSelectedSpecies(target.id);

        //        setCrossSections([]);

        //setDatabasesFromSpeciesName(target.id);
        //const { Species } = exampleSpecies;

    }



    return <>
        <Autocomplete
            id=""
            key={"species-select"}
            ListboxComponent={ListboxComponent}
            options={(sampleSpecies ? sampleSpecies : [])}
            getOptionLabel={(item) => (item.name ? item.name : item)}
            filterOptions={autocompleteFilterOptions}
            renderInput={(params) => <TextField {...params} size="small" label="Species" />}

            PaperComponent={({ children }) => (
                <Paper >{children}</Paper>
            )}
            renderOption={(props, option, state) => {
                if (sampleSpecies) {
                    return <li {...props}  id={option.name}>

                        <ListItemButton
                 
                            id={option.name}
                            sx={{ margin: 0, padding: 0 }}
                            key={option.xsec_id + option.temperature}
                        >
                            <ListItemText
                                id={option.name}
                                sx={{margin: 0, padding: 0}}
                                secondary={<>
                                    <div id={option.name} className="xSecListContainer">
                                        <p id={option.name} className="xSecList" >{`${option.name} - ${option.species_chem_name}`}</p>
                                   
                                    </div></>}
                            />
                        </ListItemButton>
                    </li>
                }
            }}
            //onClose={(params) => setInputArray(absorptionEmissionHitran)}
            value={(swapPlot.speciesLongName ? swapPlot.speciesLongName : selectedSpecies)}
            onChange={speciesSelected}
            sx={{ marginBottom: "1em", marginTop: '1em' }}
            isOptionEqualToValue={(option, value) => {
                return option.name === value
            }}
        /></>
}

/*

                        <Card id={option.name} sx={{ height: '14em' }}>
                            <CardHeader title={
                                <Typography variant="h5">{option.name}</Typography>
                            } 
                            sx={{ backgroundColor: '#345995dd', marginBottom: '0em', marginTop: '0', paddingBottom: '0em', paddingTop: '0em', color: 'white' }} />

                            <CardContent id={option.name} sx={{ marginTop: '0em' }}>


                                {
                                    option.species_search_terms.map((e: string) => {
                                        return <Typography variant="body2" id={option.name}>{e}</Typography>
                                    })
                                }

                            </CardContent>

                        </Card>








*/