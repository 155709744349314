import React, { useState, useEffect } from "react";
import useSpectraplotStore from '../Functions/Store';
import { Card, Button, Checkbox, TextField, Typography } from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ClearIcon from '@mui/icons-material/Clear';

type props = {
    savePNGCallback: () => void,
    isBlackbody?: boolean
}
export const SpectraplotGraphControl: React.FC<props> = ({savePNGCallback}) => {

    const { clearPlots, setYMax } = useSpectraplotStore();
    const [customYMax, setCustomYMax] = useState<boolean>(false);

    const handleYMaxChange = (e: any /*FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>*/) => {
        if (e.target.value === '') {
            setCustomYMax(false);
            setYMax(0);
        }
        else {
            setYMax(e.target.value);
        }

    }
    const onKeyPress = (e: any) => {
        if (e.key === "Enter") {
            if (e.target.value === '') {
                setYMax(0);
                setCustomYMax(false);
            }
            else {
                setYMax(e.target.value);

            }
            e.preventDefault();
        }
    }

    useEffect(() => {

        if (!customYMax) {
            setYMax(0);
        }

    }, [customYMax])


    return <>
         <div style={{ display: 'flex', flexDirection: 'row',flexWrap: 'wrap', alignItems: "center", margin: '1em 1em 0 0', justifyContent: 'flex-start'}}>
   
         <Button variant="text" sx={{margin: 0, paddingLeft: 0}}> <Checkbox checked={customYMax} sx={{margin: '0 .3em 0 0'}} onChange={(e) => setCustomYMax(e.target.checked)} /><Typography variant="body1" > Fix Y Max</Typography></Button>
      
  
                {customYMax && <TextField style={{ width: 80, margin: 0 }} variant="outlined" label="Y-Max" size="small" margin="dense" onBlur={handleYMaxChange} onKeyDown={onKeyPress} />}

                <Button  onClick={clearPlots}><ClearIcon /><Typography sx={{marginLeft: '1em'}}>Clear Plots</Typography></Button>
         
                <Button  onClick={savePNGCallback}><InsertPhotoIcon /><Typography sx={{marginLeft: '1em'}}> Save PNG</Typography></Button>
  
  
                <Button ><TextSnippetIcon /><Typography sx={{marginLeft: '1em'}}>Save CSV</Typography></Button>
       

                </div>
        </>
}