/*
This component is used by CurrentPlotsDisplay and CurrentPlotsDisplayBlackbody to show the inputs that are used in the plot.
*/
import React from 'react';
import { Typography } from '@mui/material';
import parse from 'html-react-parser';
import '../Pages/style.css';
import { labelTranslator } from '../Functions/DataFunctions';
import { useEffect } from 'react';
import crossSectionsData from "../Functions/Data/HITRAN_XSec_Options_2022.json";
import { input, plot, blackbodyPlot, species } from '../Functions/types';

type props = {
    inputs: input[],
    plot: plot | blackbodyPlot,
    blackbody?: boolean
}

/**
 * 
 * @param param0 inputs for a plot 
 * @returns Used by CurrentPlotsDisplay as a helper component. It lays out the inputs and values. Also, this will
 * give us a chance to filter certain inputs if they need to be displayed differently (e.g., translating the ν from nu).
 */


export const InputDisplayList: React.FC<props> = ({ inputs, blackbody, plot }) => {

    /*   const generateInputs = (plot: plot) => {
           const xsec_ID = plot.inputs.findIndex(e => e.name === 'xsec_ID');
           if (xsec_ID) {
               const xsecs = crossSectionsData[plot.species as keyof typeof crossSectionsData].xsec_options;
               const xsec_entries = Object.entries(xsecs);
               const xsec_data = xsec_entries[xsec_ID][1];
               const tmpInputs = plot.inputs;
               Object.keys(xsec_data).forEach(key => {
                   tmpInputs.push({
                       name: key,
                       value: xsec_data[key as keyof typeof xsec_data]
                   })
               })
               return tmpInputs;
           }
           return plot.inputs;
       }
   */
    const getCrossSectionData = (xsec_ID: number) => {
        
        const tmpPlot = plot as any;
        if (tmpPlot.species) {
            const xsecs = crossSectionsData[tmpPlot.species as keyof typeof crossSectionsData].xsec_options;
        //    console.log(Object.entries(xsecs));
            const xsec_entries = Object.entries(xsecs);
            const xsec_data = xsec_entries[xsec_ID - 1][1];
            return Object.keys(xsec_data).map((e, index) => <Typography variant="body2" sx={{ marginLeft: '1em', marginRight: '1em' }} key={'input-display-actual-input-xsec' + index}>{parse(labelTranslator(e + ''))}: {xsec_data[e as keyof typeof xsec_data]}</Typography>);
        }
    }
    useEffect(() => {
        //     console.log(inputs);
    }, [inputs])


    return <>
        <div className='input-list'>
            {
                inputs.map((e, index) => {

                    if (!blackbody && e && e.name !== 'lend' && e.name !== 'lstart') {
                        if (e.name !== 'nu_step' || (e.name === 'nu_step' && e.value !== 0)) {
                   //         console.log(e.name, e.value);
                            if (e.name === 'xsec_ID' && e.value !== null) {

                                return (getCrossSectionData(e.value));
                            }
                            else {
                                return <Typography variant="body2" sx={{ marginLeft: '1em', marginRight: '1em' }} key={'input-display-actual-input' + index}>{parse(labelTranslator(e.name + ''))}: {e.value}</Typography>
                            }
                        }
                    }
                    else if (blackbody) {
                        return <Typography key={'input-display-actual-input' + index}>{parse(labelTranslator(e.name + ''))}: {e.value}</Typography>
                    }
                })
            }
        </div>
    </>
}