
import "./App.css";

import { Absorption } from "./Pages/Absorption";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AboutPage } from "./Pages/AboutPage";
import { Governance } from "./Pages/Governance";
import { CodeOfConduct } from "./Pages/CodeOfConduct"; 
import { Blackbody } from "./Pages/Blackbody";


const theme = createTheme({
  palette: {
    primary: {
      light: "#00635D",
      main: "#00635D",
      dark: "#00635D",
      contrastText: "#fff",
    },
    warning: {
      light: "#FF495C",
      main: "#FF495C",
      dark: "#FF495C",
      contrastText: "#fff",
    },
  },
});
function App() {
  return (
    <div className="main-bg App-font">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Absorption />} />
            <Route path="/blackbody" element={<Blackbody />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/governance" element={<Governance />} />
            <Route path="/codeofconduct" element={<CodeOfConduct />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
