import React from 'react';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Grid, FormControl, MenuItem, Select, InputLabel, Button, Card, Paper, CardContent, Typography, Divider } from '@mui/material';


import { SpecInputTextField } from '../SpecInputTextField';
import exampleSpeciesObject from "../../Functions/Data/Database_Species_Inputs.json";
import crossSectionsData from "../../Functions/Data/HITRAN_XSec_Options_2022.json";
import "../../Pages/style.css";
import useSpectraplotStore from "../../Functions/Store";
import { input, species, xSec, plot, database } from '../../Functions/types';

import Snackbar from '@mui/material/Snackbar';
import { compareArrays, waveLengthConversion, formInputsIntoArray, generateXSecs, sendInputsToBackend, convertToSigFigs } from "../../Functions/DataFunctions";
import { SpecInputAutocomplete } from './SpecInputAutocomplete';
import 'animate.css';
import { CrossSectionAutoComplete } from './CrossSectionAutoComplete';

type props = {
    pngCallback: () => void
};


/**
 * @returns One of the workhorse components. Handles all of the inputs from the user.
 * TODO - better comments for the intricacies of this component.
 * TODO - This is MUCH too long for a React component. Investigate ways to break it up.
 */
export const SpecInput: React.FC<props> = ({ pngCallback }) => {

    const { storeNewInputs, addOutputToMatchingInput, clearPlots, swapPlot, clearSwapPlot, addErrorToMatchingInputs, setShowVoronoi, showVoronoi } = useSpectraplotStore();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const responsiveHack = (isSmall ? { display: 'flex', flexWrap: 'wrap', justifyContent:'center', marginLeft: '1em', marginBottom: '1em'  } : 
        { display: 'flex', flexWrap: 'wrap', justifyContent:'flex-start', }) as React.CSSProperties; ;
    const waveResponsiveHack = (isSmall ? {display:'flex', justifyContent: 'center'} : {display:'flex', justifyContent: 'flex-start'});
    const exampleSpecies = exampleSpeciesObject.Species as any;
    //DONT DO IT LIKE THIS. ONCE THE FORMAT IS SET, ACTUALLY PUT TOGETHER THE TYPE.

    const [sampleSpecies, setSampleSpecies] = useState<species[]>();
    const [selectedSpecies, setSelectedSpecies] = useState<string>('');
    const [speciesChemName, setSpeciesChemName] = useState<string>('');
    const [inputValues, setInputValues] = useState({});
    const [inputArray, setInputArray] = useState<input[]>();
    const [crossSections, setCrossSections] = useState<xSec[]>();
    const [databases, setDatabases] = useState<string[]>([]);
    const [selectedDatabase, setSelectedDatabase] = useState<string>('');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openXsecDialog, setOpenXsecDialog] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>('');

    const [selectedDatabaseInfo, setSelectedDatabaseInfo] = useState<database>();

    const [selectedCrossSection, setSelectedCrossSection] = useState<xSec>();
    const [selectedCrossSectionID, setSelectedCrossSectionID] = useState<string>('');
    const [throwInputError, setThrowInputError] = useState<string[]>([]);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);


    const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        if (['lstart', 'lend', 'nu_start', 'nu_end'].includes(name)) {
            const convertedObject = waveLengthConversion(name, value);
            setInputValues(prevState => ({
                ...prevState,
                [name]: value,
                [convertedObject.name]: convertedObject.value
            }));
        }
        else {
            setInputValues(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }


    useEffect(() => {

        setThrowInputError([]);
        if (swapPlot.species) {//when swapPlot is changed, it doesn't always change the values if inputs are already loaded. I don't know why.
            console.log(swapPlot);
            if (swapPlot.xsec_ID) {
                setSelectedCrossSectionID(swapPlot.xsec_ID);
            }
            /*
            NEED TO SELECT THIS CROSS SECTION ONCE ALL THE CROSS SECTION OPTIONS ARE LOADED. MAYBE ANOTHER USEEFFECT THAT IS WATCHING FOR THE CHANGE?
            */
            setSpeciesChemName(swapPlot.species);
            setSelectedSpecies(swapPlot.speciesLongName);
            setDatabasesFromSpeciesName(swapPlot.speciesLongName);
            setSelectedDatabase(swapPlot.database);

            // console.log(swapPlot.inputs);
            setInputArray(swapPlot.inputs);
            const tmp = {} as any;
            swapPlot.inputs.forEach(e => {
                let name = e.name;
                tmp[name] = (e.value ? e.value.toString() : '')
            });
            //for some reason, this does not properly fill the lambda values. I have no idea why. Probably should fix this at some point.


            tmp['lend'] = convertToSigFigs((10000 / parseFloat(tmp.nu_end)), 5);


            tmp['lstart'] = convertToSigFigs((10000 / parseFloat(tmp.nu_start)), 5);

            //  console.log(tmp);
            setInputValues(tmp);
            clearSwapPlot();
        }
    }, [swapPlot]);

    //componentDidMount
    /*
    Gets the species names and the keywords that will be used by autocomplete
    to allow users search for a single species with multiple names and the 
    chemical name.
    */
    useEffect(() => {

        clearSwapPlot();
        setSampleSpecies(Object.keys(exampleSpecies).map((e) => {
            const tmp = exampleSpecies[e];
            let returnObject = {
                name: e,
                species_chem_name: tmp.species_chem_name,
                species_search_terms: tmp.species_search_terms,
                databases: tmp.databases
            } as species;
            if (tmp.xsec_options) {
                returnObject.xsec_options = tmp.xsec_options
            }
            return returnObject;
        }));
    }, [])

    const handleAddToPlot = (e: any) => {
        //input validation. Essentially, everything needs to be filled in except possibly for lstart and lend. I think.
        const tmp: string[] = [];
        Object.keys(inputValues).forEach(key => {
            if ((inputValues[key as keyof typeof inputValues] === '' || typeof inputValues[key as keyof typeof inputValues] === 'number') && key !== 'nu_step') {
                tmp.push(key);
            }
        });
        console.log(inputValues);
        if (tmp.length === 0) {
            setThrowInputError([]);
            getOutputFromInputs(inputValues as plot);
        }
        else {
            setThrowInputError(tmp);
            setOpenErrorSnackbar(true);
        }
    }


    //Snackbar functions
    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const getOutputFromInputs = (inputs: plot) => {//eventually move this somewhere else
        const tmp = exampleSpeciesObject as any;
        const url = tmp.Databases[selectedDatabase].url;
        const temporaryInputs = inputs;
        if (tmp.Databases[selectedDatabase].is_cross_section) {
            temporaryInputs.xsec_ID = selectedCrossSectionID
        }
        const inputObject = {
            inputData: {
                species: selectedSpecies,
                species_chem_name: tmp.Species[selectedSpecies].species_chem_name,
                speciesLongName: selectedSpecies,
                database: selectedDatabase,
                inputs: formInputsIntoArray(temporaryInputs),
                isVisible: true,
            }
        };
        const tmp2 = inputObject;
        console.log(tmp2);
        storeNewInputs(inputObject.inputData as plot);
        sendInputsToBackend(inputObject, url, snackBarFunction, addOutputToMatchingInput, addErrorToMatchingInputs)

    }
    // helper function to act as a callback for sendInputsToBackend
    const snackBarFunction = (message: string) => {
        setSnackBarMessage(message)
        setOpenSnackBar(true);
    }

    const setDatabasesFromSpeciesName = (speciesName: string) => {
        console.log(selectedSpecies, speciesName);
        if(speciesName !== ''){
        const tmp = exampleSpeciesObject as any;
        setSpeciesChemName(tmp.Species[speciesName].species_chem_name);
        if (speciesName) {
            const speciesInfo = exampleSpecies[speciesName as keyof typeof exampleSpecies];
            if (typeof speciesInfo.databases === "string") {
                setDatabases([speciesInfo.databases]);
            }
            else {
                setDatabases(speciesInfo.databases);
            }
        }
    }
    }

    useEffect(() => {
        if (selectedSpecies) {
            setDatabasesFromSpeciesName(selectedSpecies);

        }
    }, [selectedSpecies]);

    useEffect(() => {
        processDatabase();
    }, [databases]);

    useEffect(() => {
        processDatabase();
    }, [selectedDatabase]);


    const processDatabase = () => {
        if (selectedDatabase.length > 0) {
            setSelectedCrossSectionID('');
            setSelectedCrossSection({} as xSec);
            const { Databases } = exampleSpeciesObject;

            const databaseInfo = Databases[selectedDatabase as keyof typeof Databases];
            setSelectedDatabaseInfo(databaseInfo as database);
            console.log(databaseInfo);
            //this is not a cross section
            if (!databaseInfo.is_cross_section) {
                if (!compareArrays(databaseInfo.inputs, inputArray as any[])) {
                    setInputArray(databaseInfo.inputs.map(n => ({ name: n, value: null } as input)));

                }
                setCrossSections([]);
            }
            else {//this is a cross section database

                let tmpArray = databaseInfo.inputs.filter((v: any, i: any, a: any) => {
                    return v !== 'xsec_ID';
                });
                setInputArray(tmpArray.map(n => ({ name: n, value: null } as input)));

                const speciesInfo = crossSectionsData[selectedSpecies as keyof typeof crossSectionsData];
                if (speciesInfo && speciesInfo.xsec_options) {
                    const tmpXSec = generateXSecs(speciesInfo.xsec_options);//throws an error because there might not be a xsec, even though we won't get to that point.
                    setCrossSections(tmpXSec);
                    if (selectedCrossSectionID) {
                        setSelectedCrossSection(tmpXSec[parseInt(selectedCrossSectionID)])
                    }
                }
            }
            let tmpInputValues = {} as any;
            databaseInfo.inputs.forEach(n => {
                if (n !== 'xsec_ID') {
                    if (Object.keys(inputValues).includes(n)) {// this value is already inputted.
                        tmpInputValues[n] = inputValues[n as keyof typeof inputValues];
                    }
                    else {
                        tmpInputValues[n] = 0;
                    }
                }
            });
            tmpInputValues['lend'] = (inputValues['lend' as keyof typeof inputValues] ? inputValues['lend' as keyof typeof inputValues] : '');
            tmpInputValues['lstart'] = (inputValues['lstart' as keyof typeof inputValues] ? inputValues['lstart' as keyof typeof inputValues] : '');
            setInputValues(tmpInputValues);

        }
    }

    const clearOptions = () => {

        clearPlots();
        // setSelectedSpecies('');
        // setCrossSections([]);
        // setInputArray([]);
        // setDatabases([]);
    }

    const handleCloseXsecDialog = (value: string) => {
        setOpenXsecDialog(false);
        console.log('add to input vaues xsec: ' + value);
        if (crossSections && crossSections[parseInt(value)]) {
            setSelectedCrossSectionID(value);
            setSelectedCrossSection(crossSections[parseInt(value)]);
        }
    }

    useEffect(() => {
        if (selectedCrossSectionID && crossSections) {
            setSelectedCrossSection(crossSections[parseInt(selectedCrossSectionID)]);
        }
    }, [selectedCrossSectionID]);

    useEffect(() => {
        console.log(selectedCrossSection);
    }, [selectedCrossSection]);

    /*
    
    */
    return <>
        <Paper sx={{ marginLeft: '1em', marginRight: '1em' }}  >
            <Grid container >

                <Grid item xs={12} sm={12} md={3} sx={{}}>
                    <div style={{ padding: '1em' }}>

                        <SpecInputAutocomplete
                            sampleSpecies={sampleSpecies ? sampleSpecies : []}
                            setSelectedSpecies={setSelectedSpecies}
                            setCrossSections={setCrossSections}
                            setDatabasesFromSpeciesName={setDatabasesFromSpeciesName}
                            selectedSpecies={selectedSpecies}

                        />
                        {
                            databases.length > 0 &&
                            <FormControl fullWidth sx={{}} className="animate__animated animate__fadeIn">
                                <InputLabel size='small' key={'Databases'} id="database-select-menu">Databases</InputLabel>
                                <Select
                                    id="database-select-menu"
                                    key="database-select-menu"
                                    label="Databases"
                                    size='small'
                                    defaultValue={(selectedDatabase)}
                                    value={selectedDatabase}
                                    onChange={(e) => setSelectedDatabase(e.target.value as string)}>
                                    {
                                        databases.map((db) => {
                                            return <MenuItem
                                                key={db}
                                                value={db}>{db}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        }
                    </div>

                </Grid>

                <Grid item sm={12} xs={12} md={9} className="inputs-row" >
                    {inputArray &&

                        <Grid container className="animate__animated animate__fadeIn" spacing={1} style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em', }}>
                            <Grid item md={5} >
                                <div >
                                    {
                                        crossSections && crossSections.length > 0 &&
                                        <>
                                            <CrossSectionAutoComplete crossSections={crossSections} crossSectionSelected={setSelectedCrossSectionID} selectedCrossSectionID={selectedCrossSectionID} />
                                        </>
                                    }
                                    {/*<Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>Inputs</Typography>*/}
                                    <p style={{ margin: 0 }}>
                                        {
                                            Object.keys(inputValues) && Object.keys(inputValues).map((key, index) => {
                                                if (!["nu_start", "nu_end", "nu_step", "lstart", "lend", "xsec_ID"].includes(key)) {
                                                    return <>
                                                        <SpecInputTextField key={key + index} valueChange={valueChange} label={key} inputValues={inputValues} margin=".3em" waveConversion={false} throwError={throwInputError} speciesChemName={speciesChemName} />
                                                    </>
                                                }
                                            })

                                        }
                                    </p>

                                </div>
                            </Grid>

                            <Grid item md={4} className="animate__animated animate__fadeIn" >
                                <div style={responsiveHack}>

                                    {/*<Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>Wavelength</Typography>*/}
                                    <Grid container spacing={1} style={waveResponsiveHack}>
                                        {inputArray && selectedDatabaseInfo && !selectedDatabaseInfo.is_cross_section &&
                                            ["nu_start", "nu_end", "nu_step"].map((key, index) => {
                                                return <Grid item xs={3} ><SpecInputTextField key={key + index} valueChange={valueChange} label={key} inputValues={inputValues} margin=".3em" waveConversion={false} throwError={throwInputError} /></Grid>
                                            })
                                        }
                                        {
                                            inputArray && crossSections && crossSections.length > 0 &&
                                            ["nu_start", "nu_end"].map((key, index) => {
                                                return <Grid item xs={3}><SpecInputTextField key={key + index} valueChange={valueChange} label={key} inputValues={inputValues} margin=".3em" waveConversion={false} throwError={throwInputError} /></Grid>
                                            })
                                        }
                                    </Grid>
                                    {inputArray &&
                               
                                            <Grid container spacing={1} style={waveResponsiveHack}>
                                                <Grid item xs={3} ><SpecInputTextField valueChange={valueChange} label={"lstart"} inputValues={inputValues} margin=".3em" waveConversion={true} throwError={throwInputError} /></Grid>

                                                <Grid item xs={3}><SpecInputTextField valueChange={valueChange} label={"lend"} inputValues={inputValues} margin=".3em" waveConversion={true} throwError={throwInputError} /></Grid>
                                            </Grid>
                                  
                                    }


                                </div>
                            </Grid>
                            <Grid item md={2} sm={12} style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>

                                <Button variant="outlined" onClick={handleAddToPlot} sx={{ marginRight: '1em' }}>Add to Plot</Button>


                            </Grid>


                        </Grid>




                    }
                </Grid>

                {/*<Grid item xs={12} sm={12} md={4} sx={{ paddingRight: '1em', paddingBottom: '1em', paddingLeft: '1em', paddingTop: '1em' }} >
                    {/* <Card variant="outlined" sx={{ padding: '1em' }}>*/}

                {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Chart Actions</Typography>*/}

                {/* <Button variant="outlined" onClick={handleAddToPlot} sx={{ marginRight: '1em' }}>Add to Plot</Button>*/}
                {/*<Button variant="outlined" onClick={clearOptions}>Clear Plots</Button>
                    {/*   <FormGroup>
                                <FormControlLabel control={<Checkbox checked={showVoronoi} onChange={(e) => setShowVoronoi(e.target.checked)} />} label="Show Points at Cursor" />
                            </FormGroup> */}


                {/* </Card>*/}
                {/*</Grid> */}

                <Grid item md={10}></Grid>
            </Grid>
        </Paper>
        <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            message={snackBarMessage}
            onClose={handleCloseSnackBar}
        />
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={openErrorSnackbar}
            onClose={(e) => setOpenErrorSnackbar(false)}
            autoHideDuration={4000}
            key={'top-center'}>
            <Alert severity="error" sx={{ width: '100%' }}>Please enter all required inputs.</Alert>
        </Snackbar>
    </>
}