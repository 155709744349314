
import React from 'react';
import { Grid, SpeedDial, SpeedDialAction, Card } from '@mui/material';
import { useState, useRef } from 'react';
import { SpecInput } from '../Components/Absorption/SpecInput';
import { CurrentPlotsDisplay } from '../Components/Absorption/CurrentPlotsDisplay';
import SaveIcon from '@mui/icons-material/Save';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import * as htmlToImage from 'html-to-image';
import FileSaver from 'file-saver';
import './style.css';
import useSpectraplotStore from "../Functions/Store";
import { SpecConsole } from '../Components/SpecConsole';
import { SpecHeader } from '../Components/SpecHeader';
import { SpectraplotGraph } from '../Components/Absorption/SpectraplotGraph';
import { SpectraplotGraphControl } from '../Components/SpectraplotGraphControl';
import { SponsorSection } from '../Components/SponsorSection';


type props = {};


/**
 * 
 * @returns Component for absorption. At this point, this does nothing except for call the graph. It's morese a placeholder for when other kinds are
 * implemented.
 */
export const Absorption: React.FC<props> = () => {
    const { consoleLog } = useSpectraplotStore();
    const ref = useRef<HTMLDivElement>(null);
    const [openDial, setOpenDial] = useState(false);
    const handleOpen = () => setOpenDial(true);
    const handleDialClose = () => setOpenDial(false);
    const handleClose = () => {

        setOpenDial(false);
    }



    const handleDownload = () => {
        if (ref) {
            htmlToImage.toBlob(ref.current!, { cacheBust: true, })
                .then(function (dataUrl) {
                    if (dataUrl) {
                        FileSaver.saveAs(dataUrl, 'my-node.png');
                    }
                });
            /*  toPng(ref.current!)
                  .then((dataUrl) => {
                      const link = document.createElement('a')
                      link.download = 'my-image-name.png'
                      link.href = dataUrl
                      link.click()
                  })
                  .catch((err) => {
                      console.log(err)
                  });*/
        }
        /*  const tmp = document.getElementById("png-div");
          if (tmp) {
              htmlToImage.toBlob(tmp)
                  .then(function (dataUrl) {
                      if (dataUrl) {
                          FileSaver.saveAs(dataUrl, 'my-node.png');
                      }
                  });
  
          }*/
    }

    const actions = [

        { icon: <InsertPhotoIcon />, name: 'Save PNG', action: handleDownload },
        { icon: <TextSnippetIcon />, name: 'Save CSV', action: handleClose }
    ];
    return <>
        <SpecHeader Title={"Absorption"} />
        <Grid container>
            <Grid item xs={12}>

                <SpecInput pngCallback={handleDownload} />

            </Grid>
        </Grid>
        <Card sx={{ margin: "1em", paddingBottom: '1em' }}>
            <Grid container>


                <Grid item md={10.5} xs={12} ref={ref} >


                    <SpectraplotGraph />




                </Grid>

                <Grid item md={1.5} xs={12} >

                    <SpectraplotGraphControl savePNGCallback={handleDownload} />


                </Grid>

                <Grid item xs={12}>
                    <CurrentPlotsDisplay />
                </Grid>


                <Grid item xs={12} sx={{ margin: "1em 1em 0 1em" }}>

                    <SpecConsole message={consoleLog} />


                </Grid>
                <Grid item xs={12} sx={{margin: '0 1em 1em 1em'}}>
                    <SponsorSection />
                </Grid>

                {/*    <SpeedDial
                ariaLabel="SpeedDial"
                sx={{ position: 'fixed', bottom: 16, right: 30 }}
                icon={<SaveIcon />}
                onClose={handleDialClose}
                onOpen={handleOpen}
                open={openDial}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => action.action()}
                    />
                ))}
                </SpeedDial>*/}
            </Grid>
        </Card>

    </>


}