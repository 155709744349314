import React, {useEffect} from "react"
import { SponsorCard } from "./SponsorCard";
import { Grid } from "@mui/material";
import daylightSolutions from "../Media/DaylightSolutions.png";
import irSweep from "../Media/IRsweep.png";
import m2 from "../Media/M2.jpeg";
import thorLabs from "../Media/Thorlabs.png";
import eblana from "../Media/Eblana_photonics.png";
import hammatsu from "../Media/Hamamatsu.png";
import mirsense from "../Media/Mirsense.png";
import norcada from "../Media/norcada.webp";


export const SponsorSection: React.FC = () => {


    return <>
        <Grid container spacing={2}>
            <Grid item md={3}>
                <SponsorCard name="daylight-solutions" imageURL={daylightSolutions}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="IRSweep" imageURL={irSweep}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="M2" imageURL={m2}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="thor-labs" imageURL={thorLabs}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="eblana" imageURL={eblana}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="hammatsu" imageURL={hammatsu}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="mirsense" imageURL={mirsense}/>
            </Grid>
            <Grid item md={3}>
                <SponsorCard name="norcada" imageURL={norcada}/>
            </Grid>
        </Grid>
    </>
}