import React from 'react';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { labelTranslator } from '../Functions/DataFunctions';
import ErrorIcon from '@mui/icons-material/Error';
import InputAdornment from '@mui/material/InputAdornment';

type props = {
    valueChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    label: string,
    inputValues: {},
    margin: string,
    waveConversion: boolean,
    throwError: string[],
    speciesChemName?: string
}

export const SpecInputTextField: React.FC<props> = ({ valueChange, label, inputValues, margin, waveConversion, throwError, speciesChemName }) => {

    const [errorIcon, setErrorIcon] = useState({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        valueChange(e);

    }
    const [width, setWidth] = useState<number>(140);
  
    useEffect(() => {
        if (throwError.includes(label)) {
            setErrorIcon({
                endAdornment:
                    <InputAdornment position="start">
                        <ErrorIcon color="error" />
                    </InputAdornment>

            })
        }
        else {
            setErrorIcon({});
        }
    }, [throwError]);

    //component did mount
    useEffect(() => {
        console.log(speciesChemName);
        if(['lend','lstart','nu_start','nu_end','nu_step'].includes(label)){
            setWidth(100);
        }

    }, [])
  
  /*  useEffect(() => {
        console.log(inputValues);
    }, [inputValues]);
*/
    /*useEffect(() => {
        console.log(inputValues);
    }, [inputValues[label as keyof typeof inputValues]]);
    */
    /*const getValue = () => {
   
        if(!waveConversion){
            return inputValues[label as keyof typeof inputValues] ? inputValues[label as keyof typeof inputValues] : ''
        }
        else if (waveConversion && nuLabel){
            return (inputValues[nuLabel as keyof typeof inputValues] ? inputValues[nuLabel as keyof typeof inputValues] : '')
        }<ErrorIcon color="error"/>
    }*/

    return <TextField
        sx={{ marginLeft: { margin }, marginRight: { margin }, width: 80 }}
        variant="standard"
        type="number"
        size="small"
        label={(speciesChemName && label === 'molefraction' ? parse(labelTranslator(label, speciesChemName)) : parse(labelTranslator(label)) )}// add optional parameter, and parse with χ
        onChange={handleChange}
        key={label}
        name={label}
        value={(inputValues[label as keyof typeof inputValues] ? inputValues[label as keyof typeof inputValues] : '')}
        InputProps={errorIcon}
    ></TextField>
}