import create from 'zustand';
import { persist } from 'zustand/middleware';
import { plot, blackbodyPlot } from './types';
import { getColorfromIndex, getAxesRange } from "./DataFunctions";



/**
 * THIS IS A CENTRAL STATEMANAGEMENT. IT ALLOWS CENTRALIZED DATA, AND CHANGES HERE CAN TRIGGER COMPONENTS UPDATING.
 * 
 * 
 * Note: this is a bit confusing, with few ways to simplify it. Handle with care.
 */




// do I need to store the current live values before they have been submitted? I don't think so, but...
interface SpectraplotBlackbodyState {

    plots: blackbodyPlot[],
    swapPlot: blackbodyPlot,
    consoleLog: string,
    showVoronoi: boolean,
    visibilityToggle: boolean,
    yMax: number,
    setYMax: (newYMax: number) => void,
    setShowVoronoi: (shouldShow: boolean) => void,
    storeNewInputs: (newInputs: blackbodyPlot) => void,
    printState: () => void,
    addOutputToMatchingInput: (outputObject: blackbodyPlot) => void,
    addErrorToMatchingInputs: (inputObject: blackbodyPlot, errorMessage: string) => void,
    clearPlots: () => void,
    clearSinglePlot: (plotToRemove: blackbodyPlot) => void,
    addColorForPlot: (colorToAdd: string, plot: blackbodyPlot) => void,
    changeVisibility: (plotToUnsee: blackbodyPlot) => void,
    setSwapPlot: (plotToSwap: blackbodyPlot) => void,
    clearSwapPlot: () => void,

}

const getIndexOfPlot = (state: SpectraplotBlackbodyState, plotToFind: blackbodyPlot): number => {
    let foundIndex = -1;
    state.plots.forEach((plot, index) => {
        if (plot.inputs.map((e, i) => JSON.stringify(plot.inputs[i])).sort().toString() === plotToFind.inputs.map((e, i) => JSON.stringify(plotToFind.inputs[i])).sort().toString()) {

            foundIndex = index;
        }
    });

    return foundIndex;
}

const storeNewInputsHelper = (state: SpectraplotBlackbodyState, newInputs: blackbodyPlot) => {
    const tmp = state.plots;
    const index = getIndexOfPlot(state, newInputs);
    if (index > -1) { // there is alreadt a plot with identical inputs, db, etc...
        tmp[index] = newInputs;
    }
    else {
        tmp.push(newInputs);
    }
    return {
        plots: tmp
    };
}

const clearPlotsHelper = (state: SpectraplotBlackbodyState) => {

    return {
        consoleLog: '',
        plots: [],
        swapPlot: {} as plot
    };
}

const addOutputToMatchingInputHelper = (state: SpectraplotBlackbodyState, outputObject: blackbodyPlot) => {
    //should just be able to iterate through the plots because there shgouldn't be that many.
    //    console.log(outputObject.output?.length);

    const tmp = state.plots;

    const index = getIndexOfPlot(state, outputObject);
    if (index > -1) {

        tmp[index].output = outputObject.output;
        tmp[index].isVisible = true;
        tmp[index].color = getColorfromIndex(index);
     //   tmp[index].axesRange = getAxesRange(tmp);
    }

    return {
        consoleLog: 'Simulation was successful.\n' + state.consoleLog,
        plots: tmp
    };
}

const addErrorToMatchingInputsHelper = (state: SpectraplotBlackbodyState, inputObject: blackbodyPlot, errorMessage: string) => {
    //   console.log(inputObject);
    const tmp = state.plots;
    const index = getIndexOfPlot(state, inputObject);
    if (index > -1) {


        tmp[index].isVisible = false;
        tmp[index].errorMessage = errorMessage;

    }


    return {
        consoleLog: errorMessage + '\n' + state.consoleLog,
        plots: tmp
    };

}

const addColorForPlotHelper = (state: SpectraplotBlackbodyState, colorToAdd: string, plot: blackbodyPlot) => {
    //this whole pattern is repeated a few times, but I can't think of how to create a separate function
    const tmp = state.plots;
    console.log(colorToAdd, plot);
    const index = getIndexOfPlot(state, plot);
    if (index > -1) {

        tmp[index].color = colorToAdd;
    }

    return {
        plots: tmp,
        visibilityToggle: !state.visibilityToggle
    };

}

const clearSinglePlotHelper = (state: SpectraplotBlackbodyState, plotToRemove: blackbodyPlot) => {

    const tmp = state.plots.filter((plotItem) => {
        return plotToRemove.inputs.map((e, i) => JSON.stringify(plotToRemove.inputs[i])).sort().toString() !== plotItem.inputs.map((e, i) => JSON.stringify(plotItem.inputs[i])).sort().toString();
    })

    return {
        plots: tmp
    };
}

const setShowVoronoiHelper = (state: SpectraplotBlackbodyState, shouldShow: boolean) => {

    return {
        showVoronoi: shouldShow
    };
}

const printStateHelper = (state: SpectraplotBlackbodyState) => {
    console.log(state);
    return state;
}

const changeVisibilityHelper = (state: SpectraplotBlackbodyState, plotToUnsee: blackbodyPlot) => {
    console.log(';dflkgsdl;fkgds')
    const tmp = state.plots;
    const index = getIndexOfPlot(state, plotToUnsee);
    if (index > -1) {

        tmp[index].isVisible = !tmp[index].isVisible;
    }

    return {
        plots: tmp,
        visibilityToggle: !state.visibilityToggle
    };
}

const setSwapPlotHelper = (state: SpectraplotBlackbodyState, plotToSwap: blackbodyPlot) => {
//    console.log(plotToSwap);
    return {
        swapPlot: {
            inputs: plotToSwap.inputs,
            color: plotToSwap.color,
            notes: plotToSwap.notes,
            isVisible: plotToSwap.isVisible,
            errorMessage: plotToSwap.errorMessage
        }
    };
}
const setNewYMaxHelper = (state: SpectraplotBlackbodyState, newYMax: number) => {
    
    return {
        yMax: newYMax
    };
}
const useSpectraplotBlackbodyState = create<SpectraplotBlackbodyState>()(

    persist(
        (set, get) => ({
            plots: [],
            swapPlot: {} as plot,
            consoleLog: '',
            showVoronoi: true,
            visibilityToggle: true,
            yMax: 0,
            setYMax:(newYMax: number) => set(state => setNewYMaxHelper(state, newYMax)),
            setShowVoronoi: (shouldShow: boolean) => set(state => setShowVoronoiHelper(state, shouldShow)),
            storeNewInputs: (newInputs) => set(state => storeNewInputsHelper(state, newInputs)),
            printState: () => set(state => printStateHelper(state)),
            addOutputToMatchingInput: (outputObject) => set(state => addOutputToMatchingInputHelper(state, outputObject)),
            clearPlots: () => set(state => clearPlotsHelper(state)),
            clearSinglePlot: (plotToRemove) => set(state => clearSinglePlotHelper(state, plotToRemove)),
            addColorForPlot: (colorToAdd, plot) => set(state => addColorForPlotHelper(state, colorToAdd, plot)),
            changeVisibility: (plotToUnsee) => set(state => changeVisibilityHelper(state, plotToUnsee)),
            setSwapPlot: (plotToSwap: blackbodyPlot) => set(state => setSwapPlotHelper(state, plotToSwap)),
            clearSwapPlot: () => set(state => ({ swapPlot: {} as plot })),
            addErrorToMatchingInputs: (inputObject: blackbodyPlot, errorMessage: string) => set(state => addErrorToMatchingInputsHelper(state, inputObject, errorMessage)),

        }),
        {
            name: 'spectraplot-blackbody-storage'
        }
    )

)

export default useSpectraplotBlackbodyState;