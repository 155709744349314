import React from "react";
import { useState, useEffect } from "react";
import { Autocomplete, TextField, Popper } from "@mui/material";
import { xSec } from "../../Functions/types";
import { createFilterOptions } from '@mui/material';
import { ListItemText, ListItemButton } from '@mui/material';
import '../../Pages/style.css';


type props = {
    crossSections: xSec[],
    crossSectionSelected: (value: string) => void,
    selectedCrossSectionID: string
}


/**
 * creates a function that compiles all the keywords given for a species. This (essentially) creates a searchable string that allows the user
 * to enter text that will match anything related to the species.
 *     xsec_id: number,
    database?: string,
    temperature: number,
    pressure: number,
    wavenumber_range: string,
    broadener: string
 */
const autocompleteFilterOptions = createFilterOptions({
    stringify: (option: any) => (`temperature: ${option.temperature} pressure: ${option.pressure} wavenumber range: ${option.wavenumber_range} broadener: ${option.broadener}, Cross section id: ${option.xsec_id}`)
});


export const CrossSectionAutoComplete: React.FC<props> = ({ crossSections, crossSectionSelected, selectedCrossSectionID }) => {

    const [value, setValue] = useState<xSec>();
    const selected = (e: React.SyntheticEvent, data: xSec) => {

        setValue(data);
        crossSectionSelected(data.xsec_id.toString());

    }
    
    useEffect(() => {
        console.log(selectedCrossSectionID);
    },[]);

    return <>
        <Autocomplete
            id="xsec-select"
            key={selectedCrossSectionID + 'xsec-select'}
            options={crossSections}
            getOptionLabel={(item) => (item ? `T: ${item.temperature}, P: ${item.pressure}, B: ${item.broadener}, WR: ${item.wavenumber_range}` : '')}
            filterOptions={autocompleteFilterOptions}
            renderInput={(params) => <TextField  
                                        {...params} 
                                        label="Cross Sections" 
                                        size="small" 
                                        style={{ maxWidth: 400, minWidth: 300, fontSize: 10 }} 
                                        />}
            PopperComponent={(params) => <Popper {...params} style={{}} placement="bottom-start" />}

            isOptionEqualToValue={(option, value) => {

                return option.xsec_id === value.xsec_id;
            }
            }
            renderOption={(props, option, state) => {
                return <li {...props}>
                    <ListItemText
                        sx={{ margin: 0, padding: 0 }}
                        key={option.xsec_id + option.temperature}
                        secondary={<>
                            <div className="xSecListContainer">
                                <p className="xSecList" ><b >ID:</b> {option.xsec_id}</p>
                                <p className="xSecList" ><b >temperature:</b> {option.temperature}</p>
                                <p className="xSecList"  ><b >broadener:</b> {option.broadener}</p>
                                <p className="xSecList" ><b >pressure:</b> {option.pressure}</p>
                                <p className="xSecList" ><b >wavenumber range:</b> {option.wavenumber_range}</p>
                            </div></>}
                    />
              </li>


            }}
            //onClose={(params) => setInputArray(absorptionEmissionHitran)}
            value={(value ? value : '')}
            onChange={(e, data) => selected(e, data)}
            sx={{  marginTop: '1em', }}
            autoSelect
        /></>

}