import React from "react";
import { useState, useEffect } from "react";
import {
    Card, Button,
    TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper, CircularProgress, Popover, 
} from "@mui/material";
import { InputDisplayList } from "../InputDisplayList";

import useSpectraplotBlackbodyState from "../../Functions/BlackbodyStore";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorIcon from '@mui/icons-material/Error';
import { HexColorPicker, HexColorInput } from "react-colorful";

type props = {

}

/**
 * 
 * @returns The bottom information panel displaying the current plots that are live in the graph.
 * Clicking on a plot *should* repopulate the fields with that data so they can change data in 
 * small ways. User can also change the visibility of that plot (removing it visually) or delete
 * the plot/data outright.
 */

export const CurrentPlotsDisplayBlackbody: React.FC<props> = () => {
    const { plots, clearSinglePlot, changeVisibility, setSwapPlot, addColorForPlot } = useSpectraplotBlackbodyState();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [plotIndexSelected, setPlotIndexSelected] = useState<string>('');
    const [color, setColor] = useState<string | undefined>("#000");

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as any;
      
        setColor(plots[parseInt(target.id)].color)
        setPlotIndexSelected(target.id);
        setAnchorEl(event.currentTarget);
    };
    
    useEffect(() => {
        console.log(color);
    }, [color])
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    

    const handleColorChange = (color: string, index: number) => {
        setColor(color);
        console.log(color, plotIndexSelected);
        addColorForPlot(color, plots[parseInt(plotIndexSelected)]);
    }


    return <>
        {
            plots.length > 0 &&
            <Card style={{ marginTop: "1em", marginLeft: "1em", marginRight: "1em", overflow: 'hidden' }}>
                <TableContainer component={Paper} sx={{ width: '100%' }} >
                    <Table sx={{ minWidth: 1400 }} aria-label="table of current plots" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Color</TableCell>
                                <TableCell align="center">Inputs</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                plots.map((plot, index) => {
                                    if (plot) {
                                        // console.log(plot.inputs);
                                        return <>
                                            <TableRow
                                                hover={true}
                                                key={'current-plot-display' + index}
                                            >
                                                <TableCell align="center" key={'current-plot-display-color' + index}>
                                                    <Button id={index.toString()}  variant="outlined" onClick={handlePopoverOpen}><div id={index.toString()} onClick={handlePopoverOpen} style={{ width: '20px', height: '20px', backgroundColor: `${plot.color}` }}></div></Button>
                                                    <Popover
                                                        id="mouse-over-popover"
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handlePopoverClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <HexColorPicker color={plot.color} onChange={(color) => handleColorChange(color, index)} />
                                                        <HexColorInput color={plot.color} onChange={(color) => handleColorChange(color, index)} />
                                                    </Popover></TableCell>
                                                <TableCell key={'current-plot-display-inputs' + index} onClick={(e) => setSwapPlot(plot)}><InputDisplayList plot={plot} blackbody={true} inputs={plot.inputs} /></TableCell>
                                                <TableCell align="center" key={'current-plot-display-status' + index}>
                                                    {
                                                        (plot.output && plot.output.length > 0 ?
                                                            <Button onClick={(e) => changeVisibility(plot)}>
                                                                {
                                                                    (plot.isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />)
                                                                }
                                                            </Button> : (plot.errorMessage ? <><ErrorIcon color="error" /><p>(see console)</p></> : <CircularProgress />))
                                                    }
                                                </TableCell>
                                                <TableCell align="center" key={'current-plot-display-delete' + index}><Button onClick={(e) => clearSinglePlot(plot)}><DeleteIcon /></Button></TableCell>
                                            </TableRow>
                                        </>

                                    }
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        }

    </>


}