import React from "react"
import { Card, CardMedia, CardActionArea } from '@mui/material';


type props = {
    imageURL: string,
    name: string
}

export const SponsorCard: React.FC<props> = ({ imageURL, name }) => {
    return <>
        <Card sx={{ padding: '1em' }}>
            <CardActionArea
            component="a"
            target={'_blank'}
            href="http://google.com">
                <CardMedia
                    component="img"
                    height={50}
                    image={imageURL}
                    alt={name}
                    sx={{ objectFit: 'contain' }}
                />
            </CardActionArea>
        </Card>
    </>
}