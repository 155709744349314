import { Card, CardContent, Grid } from "@mui/material"

import { SpecHeader } from "../Components/SpecHeader"


const diagram = require('./diagram.png');
export const Governance: React.FC = () => {
    return <>
        <SpecHeader Title={"Governance"} />
        <div>
            <Card>
                <CardContent>
                    <Grid container alignItems="top" justifyItems="middle" textAlign="left">
                        <Grid item xs={12} md={12}>
                            <h1>Governance</h1>
                                <p>Policies and procedures governing the SpectraPlot community</p>
                                <p>Last updated: October 1, 2022</p>
                                <h2>SpectraPlot Governance</h2>
                                    <p>This document outlines the policies and procedures that manage the SpectraPlot community.</p>
                                    <h3>Code of Conduct</h3>
                                        <p>All online and in-person interactions and communications related to SpectraPlot are governed by the <a href="/codeofconduct">SpectraPlot Code of Conduct</a>. This code of conduct sets expectations for the community to ensure that users and contributors are able to participate in a respectful and welcoming environment.</p>
                                <h2>Teams & Roles</h2>
                                    <h3>Project Management Committee</h3>
                                        <p>This is the governing body of SpectraPlot, making decisions regarding the structure, operations, and long-term direction of the project. Each member has one vote for important project decisions.</p>
                                    <h3>Committers</h3>
                                        <p>Members of the committers team have commit/push access to the SpectraPlot source code, and can merge pull requests.</p>
                                    <h3>SpectraPlot User Group moderators</h3>
                                        <p>Members of the moderators team moderate the User Group (Slack Workspace), and have power to enable/disable posting of community members at their discretion.</p>
                                <h2>Current Project Management Committee</h2>
                                    <p>The role of the Project Management Committee is to ensure the long-term health of SpectraPlot. This includes overseeing the development of the SpectraPlot code and community in a way that most benefits the entire community of users and contributors. The Project Management Committee can be contacted at info@spectraplot.com. The current Project Management Committee (in alphabetical order) is:</p>
                                    <ul>
                                        <li>Christopher S. Goldenstein (2022–)</li>
                                        <li>Garrett Matthews (2022–)</li>
                                        <li>Victor A. Miller (2022–)</li>
                                        <li>Daniel I. Pineda (2022–)</li>
                                        <li>R. Mitchell Spearrin (2022–)</li>
                                        <li>Christopher L. Strand (2022–)</li>
                                        <li>Chuyu Wei (2022–)</li>
                                    </ul>
                                    <p>The Project Management Committee should have at least five members. Members serve in two-year cycles (starting in October 2022), with the ability to reaffirm their membership at the end of each cycle without a committee vote or leave the committee at their discretion. Members can also choose to leave the committee at any time.</p>
                                <h2>Voting</h2>
                                    <p>The Project Management Committee will vote on the following topics, with passing contingent on a 2/3 majority. All members should vote, but abstentions are permitted.</p>
                                    <ul>
                                        <li>Modifications of the governance procedures</li>
                                        <li>Adding/removing project management committee members</li>
                                        <li>Spending project funds</li>
                                        <li>Adding/removing people with commit rights to GitHub repositories</li>
                                        <li>Adding/removing moderators of the User’s Group</li>
                                        <li>Adding/removing people to private communication channels</li>
                                        <li>Adding/removing people with rights to post as SpectraPlot on social media</li>
                                        <li>Establishing sub-committees and roles</li>
                                        <li>Adding/removing members from the Non-Profit Board</li>
                                    </ul>
                                    <p>The Project Management Committee will maintain a Google Doc that records all votes (but not discussion). Access to the Google Doc will be limited to members of the Project Management Committee.</p>
                                <h2>Acknowledgements</h2>
                                    <p>This document was inspired by the Cantera governance guidelines. </p>
                    
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
        </>
}