import axios from "axios";
import { plot, blackbodyPlot, input } from "./types";
import { emptyData } from "./emptyData";
import parse from "html-react-parser";
import { format } from "d3-format";
import { Diversity3 } from "@mui/icons-material";
/**
 *
 * NOTE THIS USED TO BE A BIGGER FILE, AND MOVING FORWARD, FUNCTIONS THAT CAN BE PUSHED HERE FROM COMPONENTS SHOULD BE.
 *
 */

export const getAxesRange = (dataArray: plot[]) => {
  let xValues: number[] = [];
  let yValues: number[] = [];
  let lValues: number[] = [];
  dataArray.forEach((plot) => {
    if (plot.output && plot.output.length > 0) {
      let tmp = plot.output as any;
      //xValues = xValues.concat(tmp.map((e: any) => e.x));
      yValues = yValues.concat(tmp.map((e: any) => e.y));
      plot.inputs.forEach((e) => {
        if (e.value) {
          if (e.name === "nu_start" || e.name === "nu_end") {
            xValues.push(e.value as number);
          } else if (e.name === "lstart" || e.name === "lend") {
            lValues.push(e.value as number);
          }
        }
      });
    }
  });
  xValues.sort((a, b) => a - b);
  yValues.sort((a, b) => a - b);
  lValues.sort((a, b) => a - b);
  return {
    xLow: xValues[0],
    xHigh: xValues[xValues.length - 1],
    yLow: yValues[0],
    yHigh: yValues[yValues.length - 1],
    lLow: lValues[0],
    lHigh: lValues[lValues.length - 1],
  };
};

export const getColorfromIndex = (index: number): string => {
  const colors = [
    "#2a7221",
    "#931621",
    "#2c8c99",
    "#869D7A",
    "#152614",
    "#1e441e",
    "#124E78",
    "#3C91E6",
  ];
  if (index - 1 > colors.length) {
    return "#000000";
  }

  return colors[index];
};

/**
 * Just a helper function to free up space in another component.
 *
 * @param array1 first array to compare
 * @param array2 second array to compare
 * @returns whether or not these are arrays are equal.
 */
export const compareArrays = (array1: any[], array2: any[]): boolean => {
  if (!array2) {
    return false;
  }

  return (
    array1
      .map((e, i) => JSON.stringify(array1[i]))
      .sort()
      .toString() ===
    array2
      .map((e, i) => JSON.stringify(array2[i]))
      .sort()
      .toString()
  );
};

export const labelTranslator = (label: string, species?: string): string => {
  //   console.log(label, species);
  if (species) {
    return `χ<span class="minified"><sub>${species}</sub></span>`;
  }
  switch (label) {
    case "nu_start":
      return "ν<sub><span class='minified'>start</span></sub> <span >(cm⁻¹)</span>";
    case "nu_end":
      return "ν<sub><span class='minified'>end</span></sub> <span >(cm⁻¹)</span>";
    case "nu_step":
      return "ν<sub>step</sub><span >(cm⁻¹)</span>";
    case "temperature":
      return "T (K)";
    case "pressure":
      return "P (atm)";
    case "pathlength":
      return "L (m)";
    case "molefraction":
      return "χ";
    case "lstart":
      return 'λ<span class="minified"><sub>start</sub></span> (μm)';
    case "lend":
      return 'λ<span class="minified"><sub>end</sub></span> (μm)';
    case "T_elec":
      return 'T<span class="minified"><sub>elec</sub></span> (K)';
    case "gamma_T0":
      return 'γ<span class="minified"><sub>To</sub></span>';
    case "emissivity":
      return "ε";
    case "wavenumber_range":
      return "WR";
    default:
      return label;
  }
};

export const formInputsIntoArray = (inputs: any) => {
  const tmpArray = [] as any;
  console.log(inputs);
  Object.keys(inputs).forEach((e, i) => {
    tmpArray.push({
      name: e,
      value: parseFloat(inputs[e]),
    });
  });

  return tmpArray;
};

export const generateXSecs = (xSectionObjects: any) => {
  //type this you lazy ass.
  const keys = Object.keys(xSectionObjects);
  let xSec = [] as any;
  keys.forEach((k) => {
    xSec.push(xSectionObjects[k as keyof typeof xSectionObjects]);
  });
  return xSec;
};

export const sendInputsToBackend = (
  inputObject: any,
  url: string,
  snackBarFunction: (message: string) => void,
  addOutputToMatchingInput: (outputObject: plot) => void,
  addErrorToMatchingInputs: (inputObject: plot, errorMessage: string) => void
) => {
  const headers = {
    "Content-Type": "text/plain",
  };
  axios
    .post(url, inputObject, { headers })
    .then((response) => {
      console.log(response);
      if (response.data.simGood) {
        console.log(response.data);
        snackBarFunction(response.data.notes);
        addOutputToMatchingInput(response.data);
      } else {
        addErrorToMatchingInputs(inputObject.inputData, response.data.notes);
        snackBarFunction(response.data.notes);
      }
    })
    .catch((error) => {
      console.log(error);
      addErrorToMatchingInputs(inputObject.inputData, error.message);
      snackBarFunction(error.message);
    });
};
export const sendInputsToBackendBlackbody = (
  inputObject: any,
  url: string,
  snackBarFunction: (message: string) => void,
  addOutputToMatchingInput: (outputObject: blackbodyPlot) => void,
  addErrorToMatchingInputs: (
    inputObject: blackbodyPlot,
    errorMessage: string
  ) => void
) => {
  const headers = {
    "Content-Type": "text/plain",
  };
  console.log(inputObject);
  axios
    .post(url, inputObject, { headers })
    .then((response) => {
      console.log(response);
      if (response.data.simGood) {
        console.log(response.data);
        snackBarFunction(response.data.notes);
        addOutputToMatchingInput(response.data);
      } else {
        addErrorToMatchingInputs(inputObject.inputData, response.data.notes);
        snackBarFunction(response.data.notes);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const convertToSigFigs = (number: number, sigFigs: number): string => {
  //  console.log(number, sigFigs);
  let numberOfDigits = String(number).replace(".", "").length;
  if (sigFigs >= numberOfDigits) {
    return String(number);
  } else if (sigFigs < numberOfDigits) {
    return String(number.toPrecision(sigFigs));
  }
  return "";
};

export const waveLengthConversion = (label: string, value: string) => {
  let labelConvert = "";
  let valueConvert = "";

  if (label === "lstart") {
    labelConvert = "nu_start";
    valueConvert = convertToSigFigs(10000 / parseFloat(value), 6);
  } else if (label === "lend") {
    labelConvert = "nu_end";
    valueConvert = convertToSigFigs(10000 / parseFloat(value), 6);
  } else if (label === "nu_start") {
    labelConvert = "lstart";
    valueConvert = convertToSigFigs(10000 / parseFloat(value), 5);
  } else if (label === "nu_end") {
    labelConvert = "lend";
    valueConvert = convertToSigFigs(10000 / parseFloat(value), 5);
  }
  return { name: labelConvert, value: valueConvert };
};

const labelMaker = (inputs: input[]) => {
  let tmp = "";
  inputs.forEach((e, index) => {
    if (e.name !== "lstart" && e.name !== "lend") {
      if (index !== inputs.length - 1) {
        tmp += `${e.name}: ${e.value}, `;
      } else {
        tmp += `${e.name}: ${e.value}`;
      }
    }
  });

  return tmp;
};

export const createGraphOptions = (plots: plot[], zoom:[number, number]) => {
  const series = [] as any;
  if (plots.length !== 0) {
    plots.forEach((plot) => {
      const plotToAdd = { type: "line" } as any;
      const outputArray = [] as any;
      if (plot.output && plot.isVisible) {
        plot.output.forEach((output) => {
          outputArray.push([output.x, output.y]);
        });
        plotToAdd["data"] = outputArray;
        if (plot.showLegend) {
          plotToAdd["name"] = plot.legend;
        } else {
          plotToAdd["name"] = "";
        }
        const linestyle = {
          color: plot.color,
        };
        plotToAdd["lineStyle"] = linestyle;
        plotToAdd["itemStyle"] = linestyle;
        plotToAdd["showSymbol"] = false;
        plotToAdd["animation"] = false;
        series.push(plotToAdd);
     //   console.log(series);
      }
    });
    const range = getAxesRange(plots);
    const option = {
      xAxis: [
        {
          zLevel: 10,
          min: range.xLow,
          max: range.xHigh,
          name: parse("Wavenumber (cm⁻¹)"),
          nameLocation: "middle",
          position: "bottom",
          nameGap: 20,
          nameTextStyle: {
            fontSize: 17,
          },
          axisLabel: {
            formatter: function (value: any) {
              return value;
            },

            interval: 20,
          },
        },
        {
          min: range.xLow,
          max: range.xHigh,
          position: "top",
          name: "Wavelength (μm)",
          nameLocation: "middle",
          nameGap: 25,
          nameTextStyle: {
            fontSize: 17,
          },
          axisLabel: {
            formatter: function (value: any) {
              return (10000 / value).toPrecision(5);
            },
          },
        },
      ],
      legend: {
        show: true,
        left: "10%",
        top: "15%",
        orient: "vertical",
        backgroundColor: "none",
        //shadowColor: "rgba(0, 0, 0, 0.3)",
        //shadowBlur: 10,
      }, //'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);'
      tooltip: {
        trigger: "axis",

        /*     formatter: function (params: any){
          console.log(params);
          let tmp = '';
          params.forEach((e:any) => {
            tmp += `<span style="color:${e.color}">${e.data[0]} - ${e.data[1].toExponential(3)}</span> <br />`
          });
          return tmp;
        },*/
        
        valueFormatter: (value: number | string) => {
          if (typeof value === "number") {
            return format("3.2g")(value);
          } else {
            return format("3.2g")(parseFloat(value));
          }
        },
        position: ["10%", "55%"],
        show: true,
        padding: 5,
        

      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        name: "Absorbance",
        nameGap: 55,
        nameTextStyle: {
          fontSize: 17,
        },
        axisLabel: {
          formatter:((value: any) => {
            return format("3.2g")(parseFloat(value));
          }),
        },
      },
      series: series,
      toolbox: {
        feature: {
          /*         saveAsImage: {
            name: 'spectraplot',
            title: 'Save as PNG'
          },*/
        },
      },

      dataZoom: [
        {
          type: "slider",
          xAxisIndex: [0, 1],
          height: 40, //Change the height of the slider here
          start: zoom[0],
          end: zoom[1],
          filterMode: "none",
          selectedDataBackground: {
            lineStyle: {
              cap: "square",
              miterLimit: 1000,
              join: "miter",
            },
          },
        },
      ],
      grid: {
        bottom: 90,
        left: 100,
        right: 40,
        top: 50,
        height: "auto",
      },
    };
    return option;
  } else {
    const plotToAdd = { type: "line" } as any;
    const outputArray = [] as any;

    emptyData.forEach((output) => {
      outputArray.push([output.x, output.y]);
    });
    plotToAdd["data"] = outputArray;

    const linestyle = {
      color: "#00635D",
    };
    plotToAdd["lineStyle"] = linestyle;
    plotToAdd["itemStyle"] = linestyle;
    plotToAdd["showSymbol"] = false;
    series.push(plotToAdd);
    const option = {
      xAxis: [
        {
          type: "value",
          position: "bottom",
          min: 2195,
          max: 2220,
        },
      ],
      tooltip: {
        trigger: "axis",
        position: ["80%", "10%"],
        show: true,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        name: "Absorbance",
        nameGap: 55,
        nameTextStyle: {
          fontSize: 20,
        },
      },
      series: series,
      toolbox: {
        feature: {
          /*     saveAsImage: {
            name: 'spectraplot',
            title: 'Save as PNG'
          },
          dataZoom: {
            show: false
          }*/
        },
      },
      dataZoom: [
        {
          type: "slider",
        },
      ],
    };
    return option;
  }
};

export const getAxesRangeBlackbody = (dataArray: blackbodyPlot[]) => {
  let xValues: number[] = [];
  let yValues: number[] = [];
  let lValues: number[] = [];
  dataArray.forEach((plot) => {
    if (plot.output && plot.output.length > 0) {
      let tmp = plot.output as any;
      //xValues = xValues.concat(tmp.map((e: any) => e.x));
      yValues = yValues.concat(tmp.map((e: any) => e.y));
      plot.inputs.forEach((e) => {
        if (e.value) {
          if (e.name === "nu_start" || e.name === "nu_end") {
            xValues.push(e.value as number);
          } else if (e.name === "lstart" || e.name === "lend") {
            lValues.push(e.value as number);
          }
        }
      });
    }
  });
  xValues.sort((a, b) => a - b);
  yValues.sort((a, b) => a - b);
  lValues.sort((a, b) => a - b);
  return {
    xLow: xValues[0],
    xHigh: xValues[xValues.length - 1],
    yLow: yValues[0],
    yHigh: yValues[yValues.length - 1],
    lLow: lValues[0],
    lHigh: lValues[lValues.length - 1],
  };
};

export const createGraphOptionsBlackbody = (plots: blackbodyPlot[]) => {
  console.log("fired");
  const series = [] as any;
  if (plots.length !== 0) {
    plots.forEach((plot) => {
      const plotToAdd = { type: "line" } as any;
      const outputArray = [] as any;
      if (plot.output && plot.isVisible) {
        plot.output.forEach((output) => {
          outputArray.push([output.x, output.y]);
        });
        plotToAdd["data"] = outputArray;

        const linestyle = {
          color: plot.color,
        };
        plotToAdd["lineStyle"] = linestyle;
        plotToAdd["itemStyle"] = linestyle;
        plotToAdd["showSymbol"] = false;
        series.push(plotToAdd);
        console.log(series);
      }
    });
    const range = getAxesRangeBlackbody(plots);
    const option = {
      xAxis: [
        {
          min: range.lLow,
          max: range.lHigh,
          position: "bottom",
          axisLabel: {
            formatter: function (value: any) {
              console.log(value);
              return value;
            },
          },
        },
        {
          min: range.lLow,
          max: range.lHigh,
          position: "top",
          axisLabel: {
            formatter: function (value: any) {
              return (10000 / value).toPrecision(5);
            },
            interval: 20,
          },
        },
      ],
      tooltip: {
        trigger: "axis",

        position: ["10%", "15%"],

        show: true,
        padding: 5,
      },
      yAxis: {
        type: "value",
      },
      series: series,
      toolbox: {
        feature: {
          /*         saveAsImage: {
            name: 'spectraplot',
            title: 'Save as PNG'
          },*/
        },
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: [0, 1],
        },
      ],
    };
    return option;
  } else {
    const plotToAdd = { type: "line" } as any;
    const outputArray = [] as any;

    emptyData.forEach((output) => {
      outputArray.push([output.x, output.y]);
    });
    plotToAdd["data"] = outputArray;

    const linestyle = {
      color: "#00635D",
    };
    plotToAdd["lineStyle"] = linestyle;
    plotToAdd["itemStyle"] = linestyle;
    plotToAdd["showSymbol"] = false;
    series.push(plotToAdd);
    const option = {
      xAxis: [
        {
          type: "value",
          position: "bottom",
          min: 2195,
          max: 2220,
        },
      ],
      tooltip: {
        trigger: "axis",
        position: ["80%", "10%"],
        show: true,
      },
      yAxis: {
        type: "value",
        zlevel: 9,
      },
      series: series,
      toolbox: {
        feature: {
          /*     saveAsImage: {
            name: 'spectraplot',
            title: 'Save as PNG'
          },
          dataZoom: {
            show: false
          }*/
        },
      },
      dataZoom: [
        {
          type: "slider",
        },
      ],
    };
    return option;
  }
};
