import React, { useState, useEffect, useRef } from "react";
import useSpectraplotStore from '../../Functions/Store';
import { createGraphOptions } from '../../Functions/DataFunctions';
import { TextField, Checkbox } from "@mui/material";
//import 'echarts-gl';
import ReactEcharts from "echarts-for-react";
import { CurrentPlotsDisplay } from './CurrentPlotsDisplay';

let localZoom: [number, number] = [0, 100];

export const SpectraplotGraph: React.FC = () => {

    const [option, setOption] = useState<any>({});
    const eChartsRef = useRef(null as any);
    const { plots, consoleLog, visibilityToggle, yMax} = useSpectraplotStore();
   
   
    const onZoom = (e:any) => {
        //setZoom([e.start, e.end]);
       // setTest(1);
       console.log(localZoom);
       localZoom = [e.start, e.end];
       
      //  console.log('zoom');
    }
    const onFinished = () => {
        if (eChartsRef && eChartsRef.current) {
            const dataZoom = eChartsRef.current?.getEchartsInstance().getOption().dataZoom;
           // console.log(zoom);
            //console.log(dataZoom[0].start, dataZoom[0].end);
            if(dataZoom && (dataZoom[0]?.start !== localZoom[0] || dataZoom[0]?.end !== localZoom[1])){
                eChartsRef.current?.getEchartsInstance().dispatchAction({type: 'dataZoom',start: localZoom[0], end: localZoom[1]});
            }
        }
    }
    const onEvents = {
        'dataZoom': onZoom,
   //     'finished': onFinished
    }

    useEffect(() => {
        console.log('component did mount');
        if (eChartsRef && eChartsRef.current) {
        //    console.log(eChartsRef.current?.getEchartsInstance());
     /*       eChartsRef.current.on('dataZoom', function() {
            var option =  eChartsRef.current?.getOption();
            console.log(option.dataZoom[0].startValue, option.dataZoom[0].endValue);
          });*/
        }
    }, [])


    useEffect(() => {
        if(yMax === 0){
            if (eChartsRef && eChartsRef.current) {
                const tmp = eChartsRef.current?.getEchartsInstance().getOption();
              //  console.log(option);
                eChartsRef.current?.getEchartsInstance().setOption(option, true);
                //eChartsRef.current?.getEchartsInstance().dispatchAction({type: 'dataZoom', start: 50, end: 70});

            }
        }
        else {
            if(eChartsRef && eChartsRef.current) {
                eChartsRef.current?.getEchartsInstance().setOption(
                    {
                        yAxis: {
                            max: yMax,
                            type: "value",
                        }
                    });
            }
        }
    }, [yMax]);

    useEffect(() => {
        console.log('lkfdjghlskdfjhglkdsjfhglksdjfhgldksf');

      //  console.log(option);
        setOption(createGraphOptions(plots, localZoom));
    }, [plots, consoleLog, visibilityToggle]);

    useEffect(() => {
        
        if (eChartsRef && eChartsRef.current) {
            eChartsRef.current?.getEchartsInstance().setOption(option);
        }
    }, [option]);
    return <>

        {
            <ReactEcharts
                option={option}
                lazyUpdate={true}
                onEvents={onEvents}
                style={{ height: '30em' }}
                ref={eChartsRef} />
        }
       
        {//these need to be here in order to trigger the redraw if they change. This is due to using echarts which is not react.
            plots.some(e => e.output && e.output.length > 0) &&
            plots.map((plot, index) => {
                if (plot.output && plot.isVisible) {
                    return <div key={"dummy" + index}></div>
                }
            })
        }
    </>
}