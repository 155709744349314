export const emptyData = [
  {
    x: 2195,
    y: 0.00035309791564941406,
  },
  {
    x: 2195.01,
    y: 0.00035691261291503906,
  },
  {
    x: 2195.0200000000004,
    y: 0.0003609657287597656,
  },
  {
    x: 2195.0300000000007,
    y: 0.0003650188446044922,
  },
  {
    x: 2195.040000000001,
    y: 0.00036907196044921875,
  },
  {
    x: 2195.050000000001,
    y: 0.0003733634948730469,
  },
  {
    x: 2195.0600000000013,
    y: 0.000377655029296875,
  },
  {
    x: 2195.0700000000015,
    y: 0.0003819465637207031,
  },
  {
    x: 2195.0800000000017,
    y: 0.0003864765167236328,
  },
  {
    x: 2195.090000000002,
    y: 0.0003910064697265625,
  },
  {
    x: 2195.100000000002,
    y: 0.0003955364227294922,
  },
  {
    x: 2195.1100000000024,
    y: 0.00040030479431152344,
  },
  {
    x: 2195.1200000000026,
    y: 0.0004050731658935547,
  },
  {
    x: 2195.130000000003,
    y: 0.00040984153747558594,
  },
  {
    x: 2195.140000000003,
    y: 0.00041484832763671875,
  },
  {
    x: 2195.1500000000033,
    y: 0.00041985511779785156,
  },
  {
    x: 2195.1600000000035,
    y: 0.00042510032653808594,
  },
  {
    x: 2195.1700000000037,
    y: 0.0004303455352783203,
  },
  {
    x: 2195.180000000004,
    y: 0.00043582916259765625,
  },
  {
    x: 2195.190000000004,
    y: 0.0004413127899169922,
  },
  {
    x: 2195.2000000000044,
    y: 0.0004467964172363281,
  },
  {
    x: 2195.2100000000046,
    y: 0.0004525184631347656,
  },
  {
    x: 2195.220000000005,
    y: 0.0004584789276123047,
  },
  {
    x: 2195.230000000005,
    y: 0.00046443939208984375,
  },
  {
    x: 2195.2400000000052,
    y: 0.0004703998565673828,
  },
  {
    x: 2195.2500000000055,
    y: 0.00047659873962402344,
  },
  {
    x: 2195.2600000000057,
    y: 0.0004830360412597656,
  },
  {
    x: 2195.270000000006,
    y: 0.0004892349243164062,
  },
  {
    x: 2195.280000000006,
    y: 0.00049591064453125,
  },
  {
    x: 2195.2900000000063,
    y: 0.0005025863647460938,
  },
  {
    x: 2195.3000000000065,
    y: 0.0005097389221191406,
  },
  {
    x: 2195.3100000000068,
    y: 0.0005168914794921875,
  },
  {
    x: 2195.320000000007,
    y: 0.0005240440368652344,
  },
  {
    x: 2195.330000000007,
    y: 0.0005311965942382812,
  },
  {
    x: 2195.3400000000074,
    y: 0.0005388259887695312,
  },
  {
    x: 2195.3500000000076,
    y: 0.0005464553833007812,
  },
  {
    x: 2195.360000000008,
    y: 0.0005540847778320312,
  },
  {
    x: 2195.370000000008,
    y: 0.0005621910095214844,
  },
  {
    x: 2195.3800000000083,
    y: 0.0005707740783691406,
  },
  {
    x: 2195.3900000000085,
    y: 0.0005788803100585938,
  },
  {
    x: 2195.4000000000087,
    y: 0.00058746337890625,
  },
  {
    x: 2195.410000000009,
    y: 0.0005965232849121094,
  },
  {
    x: 2195.420000000009,
    y: 0.0006055831909179688,
  },
  {
    x: 2195.4300000000094,
    y: 0.0006146430969238281,
  },
  {
    x: 2195.4400000000096,
    y: 0.0006241798400878906,
  },
  {
    x: 2195.45000000001,
    y: 0.0006341934204101562,
  },
  {
    x: 2195.46000000001,
    y: 0.0006437301635742188,
  },
  {
    x: 2195.4700000000103,
    y: 0.0006542205810546875,
  },
  {
    x: 2195.4800000000105,
    y: 0.0006647109985351562,
  },
  {
    x: 2195.4900000000107,
    y: 0.000675201416015625,
  },
  {
    x: 2195.500000000011,
    y: 0.0006861686706542969,
  },
  {
    x: 2195.510000000011,
    y: 0.0006976127624511719,
  },
  {
    x: 2195.5200000000114,
    y: 0.00070953369140625,
  },
  {
    x: 2195.5300000000116,
    y: 0.0007214546203613281,
  },
  {
    x: 2195.540000000012,
    y: 0.0007333755493164062,
  },
  {
    x: 2195.550000000012,
    y: 0.0007462501525878906,
  },
  {
    x: 2195.560000000012,
    y: 0.000759124755859375,
  },
  {
    x: 2195.5700000000124,
    y: 0.0007724761962890625,
  },
  {
    x: 2195.5800000000127,
    y: 0.0007863044738769531,
  },
  {
    x: 2195.590000000013,
    y: 0.0008001327514648438,
  },
  {
    x: 2195.600000000013,
    y: 0.0008149147033691406,
  },
  {
    x: 2195.6100000000133,
    y: 0.0008301734924316406,
  },
  {
    x: 2195.6200000000135,
    y: 0.0008459091186523438,
  },
  {
    x: 2195.6300000000138,
    y: 0.00086212158203125,
  },
  {
    x: 2195.640000000014,
    y: 0.0008788108825683594,
  },
  {
    x: 2195.650000000014,
    y: 0.000896453857421875,
  },
  {
    x: 2195.6600000000144,
    y: 0.0009150505065917969,
  },
  {
    x: 2195.6700000000146,
    y: 0.0009341239929199219,
  },
  {
    x: 2195.680000000015,
    y: 0.0009531974792480469,
  },
  {
    x: 2195.690000000015,
    y: 0.0009722709655761719,
  },
  {
    x: 2195.7000000000153,
    y: 0.0009908676147460938,
  },
  {
    x: 2195.7100000000155,
    y: 0.0010099411010742188,
  },
  {
    x: 2195.7200000000157,
    y: 0.0010290145874023438,
  },
  {
    x: 2195.730000000016,
    y: 0.001049041748046875,
  },
  {
    x: 2195.740000000016,
    y: 0.0010700225830078125,
  },
  {
    x: 2195.7500000000164,
    y: 0.0010919570922851562,
  },
  {
    x: 2195.7600000000166,
    y: 0.0011157989501953125,
  },
  {
    x: 2195.770000000017,
    y: 0.0011396408081054688,
  },
  {
    x: 2195.780000000017,
    y: 0.0011653900146484375,
  },
  {
    x: 2195.7900000000172,
    y: 0.0011920928955078125,
  },
  {
    x: 2195.8000000000175,
    y: 0.0012197494506835938,
  },
  {
    x: 2195.8100000000177,
    y: 0.001247406005859375,
  },
  {
    x: 2195.820000000018,
    y: 0.0012760162353515625,
  },
  {
    x: 2195.830000000018,
    y: 0.00130462646484375,
  },
  {
    x: 2195.8400000000183,
    y: 0.0013332366943359375,
  },
  {
    x: 2195.8500000000186,
    y: 0.0013637542724609375,
  },
  {
    x: 2195.8600000000188,
    y: 0.0013952255249023438,
  },
  {
    x: 2195.870000000019,
    y: 0.0014276504516601562,
  },
  {
    x: 2195.880000000019,
    y: 0.0014619827270507812,
  },
  {
    x: 2195.8900000000194,
    y: 0.0014982223510742188,
  },
  {
    x: 2195.9000000000196,
    y: 0.0015363693237304688,
  },
  {
    x: 2195.91000000002,
    y: 0.001575469970703125,
  },
  {
    x: 2195.92000000002,
    y: 0.0016155242919921875,
  },
  {
    x: 2195.9300000000203,
    y: 0.0016584396362304688,
  },
  {
    x: 2195.9400000000205,
    y: 0.0017032623291015625,
  },
  {
    x: 2195.9500000000207,
    y: 0.0017490386962890625,
  },
  {
    x: 2195.960000000021,
    y: 0.0017976760864257812,
  },
  {
    x: 2195.970000000021,
    y: 0.0018482208251953125,
  },
  {
    x: 2195.9800000000214,
    y: 0.0019006729125976562,
  },
  {
    x: 2195.9900000000216,
    y: 0.0019550323486328125,
  },
  {
    x: 2196.000000000022,
    y: 0.0020122528076171875,
  },
  {
    x: 2196.010000000022,
    y: 0.0020732879638671875,
  },
  {
    x: 2196.0200000000223,
    y: 0.00213623046875,
  },
  {
    x: 2196.0300000000225,
    y: 0.0022029876708984375,
  },
  {
    x: 2196.0400000000227,
    y: 0.0022716522216796875,
  },
  {
    x: 2196.050000000023,
    y: 0.0023441314697265625,
  },
  {
    x: 2196.060000000023,
    y: 0.0024204254150390625,
  },
  {
    x: 2196.0700000000234,
    y: 0.0025005340576171875,
  },
  {
    x: 2196.0800000000236,
    y: 0.0025844573974609375,
  },
  {
    x: 2196.090000000024,
    y: 0.0026721954345703125,
  },
  {
    x: 2196.100000000024,
    y: 0.002765655517578125,
  },
  {
    x: 2196.1100000000242,
    y: 0.002864837646484375,
  },
  {
    x: 2196.1200000000244,
    y: 0.00296783447265625,
  },
  {
    x: 2196.1300000000247,
    y: 0.0030765533447265625,
  },
  {
    x: 2196.140000000025,
    y: 0.003192901611328125,
  },
  {
    x: 2196.150000000025,
    y: 0.003314971923828125,
  },
  {
    x: 2196.1600000000253,
    y: 0.003444671630859375,
  },
  {
    x: 2196.1700000000255,
    y: 0.003582000732421875,
  },
  {
    x: 2196.1800000000258,
    y: 0.0037288665771484375,
  },
  {
    x: 2196.190000000026,
    y: 0.00388336181640625,
  },
  {
    x: 2196.200000000026,
    y: 0.00405120849609375,
  },
  {
    x: 2196.2100000000264,
    y: 0.0042266845703125,
  },
  {
    x: 2196.2200000000266,
    y: 0.004413604736328125,
  },
  {
    x: 2196.230000000027,
    y: 0.00461578369140625,
  },
  {
    x: 2196.240000000027,
    y: 0.00482940673828125,
  },
  {
    x: 2196.2500000000273,
    y: 0.005062103271484375,
  },
  {
    x: 2196.2600000000275,
    y: 0.00531005859375,
  },
  {
    x: 2196.2700000000277,
    y: 0.00557708740234375,
  },
  {
    x: 2196.280000000028,
    y: 0.005863189697265625,
  },
  {
    x: 2196.290000000028,
    y: 0.00617218017578125,
  },
  {
    x: 2196.3000000000284,
    y: 0.00650787353515625,
  },
  {
    x: 2196.3100000000286,
    y: 0.00687408447265625,
  },
  {
    x: 2196.320000000029,
    y: 0.007266998291015625,
  },
  {
    x: 2196.330000000029,
    y: 0.00769805908203125,
  },
  {
    x: 2196.3400000000292,
    y: 0.00817108154296875,
  },
  {
    x: 2196.3500000000295,
    y: 0.0086822509765625,
  },
  {
    x: 2196.3600000000297,
    y: 0.009246826171875,
  },
  {
    x: 2196.37000000003,
    y: 0.00986480712890625,
  },
  {
    x: 2196.38000000003,
    y: 0.01055145263671875,
  },
  {
    x: 2196.3900000000303,
    y: 0.0113067626953125,
  },
  {
    x: 2196.4000000000306,
    y: 0.01214599609375,
  },
  {
    x: 2196.410000000031,
    y: 0.01308441162109375,
  },
  {
    x: 2196.420000000031,
    y: 0.01413726806640625,
  },
  {
    x: 2196.430000000031,
    y: 0.01531219482421875,
  },
  {
    x: 2196.4400000000314,
    y: 0.0166473388671875,
  },
  {
    x: 2196.4500000000317,
    y: 0.018157958984375,
  },
  {
    x: 2196.460000000032,
    y: 0.0198822021484375,
  },
  {
    x: 2196.470000000032,
    y: 0.0218505859375,
  },
  {
    x: 2196.4800000000323,
    y: 0.02410888671875,
  },
  {
    x: 2196.4900000000325,
    y: 0.0267486572265625,
  },
  {
    x: 2196.5000000000327,
    y: 0.029815673828125,
  },
  {
    x: 2196.510000000033,
    y: 0.033416748046875,
  },
  {
    x: 2196.520000000033,
    y: 0.037689208984375,
  },
  {
    x: 2196.5300000000334,
    y: 0.04278564453125,
  },
  {
    x: 2196.5400000000336,
    y: 0.048919677734375,
  },
  {
    x: 2196.550000000034,
    y: 0.056365966796875,
  },
  {
    x: 2196.560000000034,
    y: 0.06549072265625,
  },
  {
    x: 2196.5700000000343,
    y: 0.07666015625,
  },
  {
    x: 2196.5800000000345,
    y: 0.090576171875,
  },
  {
    x: 2196.5900000000347,
    y: 0.10784912109375,
  },
  {
    x: 2196.600000000035,
    y: 0.1292724609375,
  },
  {
    x: 2196.610000000035,
    y: 0.155517578125,
  },
  {
    x: 2196.6200000000354,
    y: 0.1866455078125,
  },
  {
    x: 2196.6300000000356,
    y: 0.22119140625,
  },
  {
    x: 2196.640000000036,
    y: 0.25537109375,
  },
  {
    x: 2196.650000000036,
    y: 0.2822265625,
  },
  {
    x: 2196.6600000000362,
    y: 0.2939453125,
  },
  {
    x: 2196.6700000000365,
    y: 0.286376953125,
  },
  {
    x: 2196.6800000000367,
    y: 0.26220703125,
  },
  {
    x: 2196.690000000037,
    y: 0.2288818359375,
  },
  {
    x: 2196.700000000037,
    y: 0.1939697265625,
  },
  {
    x: 2196.7100000000373,
    y: 0.161865234375,
  },
  {
    x: 2196.7200000000375,
    y: 0.1346435546875,
  },
  {
    x: 2196.7300000000378,
    y: 0.1121826171875,
  },
  {
    x: 2196.740000000038,
    y: 0.09405517578125,
  },
  {
    x: 2196.750000000038,
    y: 0.0794677734375,
  },
  {
    x: 2196.7600000000384,
    y: 0.06768798828125,
  },
  {
    x: 2196.7700000000386,
    y: 0.058197021484375,
  },
  {
    x: 2196.780000000039,
    y: 0.050445556640625,
  },
  {
    x: 2196.790000000039,
    y: 0.044036865234375,
  },
  {
    x: 2196.8000000000393,
    y: 0.03875732421875,
  },
  {
    x: 2196.8100000000395,
    y: 0.0343017578125,
  },
  {
    x: 2196.8200000000397,
    y: 0.0305633544921875,
  },
  {
    x: 2196.83000000004,
    y: 0.0273895263671875,
  },
  {
    x: 2196.84000000004,
    y: 0.0246734619140625,
  },
  {
    x: 2196.8500000000404,
    y: 0.0223236083984375,
  },
  {
    x: 2196.8600000000406,
    y: 0.020294189453125,
  },
  {
    x: 2196.870000000041,
    y: 0.018524169921875,
  },
  {
    x: 2196.880000000041,
    y: 0.0169830322265625,
  },
  {
    x: 2196.8900000000413,
    y: 0.0156097412109375,
  },
  {
    x: 2196.9000000000415,
    y: 0.014404296875,
  },
  {
    x: 2196.9100000000417,
    y: 0.01332855224609375,
  },
  {
    x: 2196.920000000042,
    y: 0.01236724853515625,
  },
  {
    x: 2196.930000000042,
    y: 0.011505126953125,
  },
  {
    x: 2196.9400000000423,
    y: 0.01073455810546875,
  },
  {
    x: 2196.9500000000426,
    y: 0.01003265380859375,
  },
  {
    x: 2196.960000000043,
    y: 0.0093994140625,
  },
  {
    x: 2196.970000000043,
    y: 0.00882720947265625,
  },
  {
    x: 2196.980000000043,
    y: 0.00830078125,
  },
  {
    x: 2196.9900000000434,
    y: 0.0078277587890625,
  },
  {
    x: 2197.0000000000437,
    y: 0.007389068603515625,
  },
  {
    x: 2197.010000000044,
    y: 0.006988525390625,
  },
  {
    x: 2197.020000000044,
    y: 0.006618499755859375,
  },
  {
    x: 2197.0300000000443,
    y: 0.00627899169921875,
  },
  {
    x: 2197.0400000000445,
    y: 0.005962371826171875,
  },
  {
    x: 2197.0500000000447,
    y: 0.005672454833984375,
  },
  {
    x: 2197.060000000045,
    y: 0.005401611328125,
  },
  {
    x: 2197.070000000045,
    y: 0.00514984130859375,
  },
  {
    x: 2197.0800000000454,
    y: 0.004917144775390625,
  },
  {
    x: 2197.0900000000456,
    y: 0.00469970703125,
  },
  {
    x: 2197.100000000046,
    y: 0.004497528076171875,
  },
  {
    x: 2197.110000000046,
    y: 0.004306793212890625,
  },
  {
    x: 2197.1200000000463,
    y: 0.00412750244140625,
  },
  {
    x: 2197.1300000000465,
    y: 0.003963470458984375,
  },
  {
    x: 2197.1400000000467,
    y: 0.0038051605224609375,
  },
  {
    x: 2197.150000000047,
    y: 0.003658294677734375,
  },
  {
    x: 2197.160000000047,
    y: 0.003520965576171875,
  },
  {
    x: 2197.1700000000474,
    y: 0.0033893585205078125,
  },
  {
    x: 2197.1800000000476,
    y: 0.0032672882080078125,
  },
  {
    x: 2197.190000000048,
    y: 0.00315093994140625,
  },
  {
    x: 2197.200000000048,
    y: 0.003040313720703125,
  },
  {
    x: 2197.2100000000482,
    y: 0.00293731689453125,
  },
  {
    x: 2197.2200000000485,
    y: 0.002838134765625,
  },
  {
    x: 2197.2300000000487,
    y: 0.00274658203125,
  },
  {
    x: 2197.240000000049,
    y: 0.0026569366455078125,
  },
  {
    x: 2197.250000000049,
    y: 0.0025730133056640625,
  },
  {
    x: 2197.2600000000493,
    y: 0.0024929046630859375,
  },
  {
    x: 2197.2700000000495,
    y: 0.00241851806640625,
  },
  {
    x: 2197.2800000000498,
    y: 0.002346038818359375,
  },
  {
    x: 2197.29000000005,
    y: 0.002277374267578125,
  },
  {
    x: 2197.30000000005,
    y: 0.0022106170654296875,
  },
  {
    x: 2197.3100000000504,
    y: 0.0021495819091796875,
  },
  {
    x: 2197.3200000000506,
    y: 0.0020885467529296875,
  },
  {
    x: 2197.330000000051,
    y: 0.0020313262939453125,
  },
  {
    x: 2197.340000000051,
    y: 0.0019779205322265625,
  },
  {
    x: 2197.3500000000513,
    y: 0.0019254684448242188,
  },
  {
    x: 2197.3600000000515,
    y: 0.0018758773803710938,
  },
  {
    x: 2197.3700000000517,
    y: 0.0018291473388671875,
  },
  {
    x: 2197.380000000052,
    y: 0.0017833709716796875,
  },
  {
    x: 2197.390000000052,
    y: 0.001739501953125,
  },
  {
    x: 2197.4000000000524,
    y: 0.001697540283203125,
  },
  {
    x: 2197.4100000000526,
    y: 0.0016565322875976562,
  },
  {
    x: 2197.420000000053,
    y: 0.0016183853149414062,
  },
  {
    x: 2197.430000000053,
    y: 0.0015802383422851562,
  },
  {
    x: 2197.4400000000533,
    y: 0.001544952392578125,
  },
  {
    x: 2197.4500000000535,
    y: 0.0015106201171875,
  },
  {
    x: 2197.4600000000537,
    y: 0.0014772415161132812,
  },
  {
    x: 2197.470000000054,
    y: 0.0014448165893554688,
  },
  {
    x: 2197.480000000054,
    y: 0.0014142990112304688,
  },
  {
    x: 2197.4900000000544,
    y: 0.001384735107421875,
  },
  {
    x: 2197.5000000000546,
    y: 0.0013570785522460938,
  },
  {
    x: 2197.510000000055,
    y: 0.0013294219970703125,
  },
  {
    x: 2197.520000000055,
    y: 0.0013027191162109375,
  },
  {
    x: 2197.530000000055,
    y: 0.001277923583984375,
  },
  {
    x: 2197.5400000000554,
    y: 0.0012531280517578125,
  },
  {
    x: 2197.5500000000557,
    y: 0.0012292861938476562,
  },
  {
    x: 2197.560000000056,
    y: 0.0012073516845703125,
  },
  {
    x: 2197.570000000056,
    y: 0.0011854171752929688,
  },
  {
    x: 2197.5800000000563,
    y: 0.001163482666015625,
  },
  {
    x: 2197.5900000000565,
    y: 0.0011434555053710938,
  },
  {
    x: 2197.6000000000568,
    y: 0.0011234283447265625,
  },
  {
    x: 2197.610000000057,
    y: 0.0011043548583984375,
  },
  {
    x: 2197.620000000057,
    y: 0.0010862350463867188,
  },
  {
    x: 2197.6300000000574,
    y: 0.001068115234375,
  },
  {
    x: 2197.6400000000576,
    y: 0.0010509490966796875,
  },
  {
    x: 2197.650000000058,
    y: 0.001033782958984375,
  },
  {
    x: 2197.660000000058,
    y: 0.001018524169921875,
  },
  {
    x: 2197.6700000000583,
    y: 0.0010023117065429688,
  },
  {
    x: 2197.6800000000585,
    y: 0.0009870529174804688,
  },
  {
    x: 2197.6900000000587,
    y: 0.000972747802734375,
  },
  {
    x: 2197.700000000059,
    y: 0.0009584426879882812,
  },
  {
    x: 2197.710000000059,
    y: 0.0009450912475585938,
  },
  {
    x: 2197.7200000000594,
    y: 0.0009317398071289062,
  },
  {
    x: 2197.7300000000596,
    y: 0.0009188652038574219,
  },
  {
    x: 2197.74000000006,
    y: 0.0009064674377441406,
  },
  {
    x: 2197.75000000006,
    y: 0.0008945465087890625,
  },
  {
    x: 2197.7600000000602,
    y: 0.0008831024169921875,
  },
  {
    x: 2197.7700000000605,
    y: 0.0008716583251953125,
  },
  {
    x: 2197.7800000000607,
    y: 0.0008606910705566406,
  },
  {
    x: 2197.790000000061,
    y: 0.0008502006530761719,
  },
  {
    x: 2197.800000000061,
    y: 0.0008401870727539062,
  },
  {
    x: 2197.8100000000613,
    y: 0.0008301734924316406,
  },
  {
    x: 2197.8200000000616,
    y: 0.0008206367492675781,
  },
  {
    x: 2197.830000000062,
    y: 0.0008111000061035156,
  },
  {
    x: 2197.840000000062,
    y: 0.0008020401000976562,
  },
  {
    x: 2197.850000000062,
    y: 0.00079345703125,
  },
  {
    x: 2197.8600000000624,
    y: 0.0007853507995605469,
  },
  {
    x: 2197.8700000000626,
    y: 0.0007772445678710938,
  },
  {
    x: 2197.880000000063,
    y: 0.0007691383361816406,
  },
  {
    x: 2197.890000000063,
    y: 0.0007615089416503906,
  },
  {
    x: 2197.9000000000633,
    y: 0.0007543563842773438,
  },
  {
    x: 2197.9100000000635,
    y: 0.0007472038269042969,
  },
  {
    x: 2197.9200000000637,
    y: 0.00074005126953125,
  },
  {
    x: 2197.930000000064,
    y: 0.0007333755493164062,
  },
  {
    x: 2197.940000000064,
    y: 0.0007271766662597656,
  },
  {
    x: 2197.9500000000644,
    y: 0.000720977783203125,
  },
  {
    x: 2197.9600000000646,
    y: 0.0007147789001464844,
  },
  {
    x: 2197.970000000065,
    y: 0.0007090568542480469,
  },
  {
    x: 2197.980000000065,
    y: 0.0007033348083496094,
  },
  {
    x: 2197.9900000000653,
    y: 0.000698089599609375,
  },
  {
    x: 2198.0000000000655,
    y: 0.0006928443908691406,
  },
  {
    x: 2198.0100000000657,
    y: 0.0006880760192871094,
  },
  {
    x: 2198.020000000066,
    y: 0.000682830810546875,
  },
  {
    x: 2198.030000000066,
    y: 0.0006785392761230469,
  },
  {
    x: 2198.0400000000664,
    y: 0.0006737709045410156,
  },
  {
    x: 2198.0500000000666,
    y: 0.0006699562072753906,
  },
  {
    x: 2198.060000000067,
    y: 0.0006656646728515625,
  },
  {
    x: 2198.070000000067,
    y: 0.0006618499755859375,
  },
  {
    x: 2198.0800000000672,
    y: 0.0006580352783203125,
  },
  {
    x: 2198.0900000000674,
    y: 0.0006542205810546875,
  },
  {
    x: 2198.1000000000677,
    y: 0.0006508827209472656,
  },
  {
    x: 2198.110000000068,
    y: 0.0006480216979980469,
  },
  {
    x: 2198.120000000068,
    y: 0.000644683837890625,
  },
  {
    x: 2198.1300000000683,
    y: 0.0006418228149414062,
  },
  {
    x: 2198.1400000000685,
    y: 0.0006394386291503906,
  },
  {
    x: 2198.1500000000688,
    y: 0.000637054443359375,
  },
  {
    x: 2198.160000000069,
    y: 0.0006351470947265625,
  },
  {
    x: 2198.170000000069,
    y: 0.00063323974609375,
  },
  {
    x: 2198.1800000000694,
    y: 0.0006313323974609375,
  },
  {
    x: 2198.1900000000696,
    y: 0.0006303787231445312,
  },
  {
    x: 2198.20000000007,
    y: 0.0006289482116699219,
  },
  {
    x: 2198.21000000007,
    y: 0.0006275177001953125,
  },
  {
    x: 2198.2200000000703,
    y: 0.0006256103515625,
  },
  {
    x: 2198.2300000000705,
    y: 0.0006237030029296875,
  },
  {
    x: 2198.2400000000707,
    y: 0.0006213188171386719,
  },
  {
    x: 2198.250000000071,
    y: 0.0006194114685058594,
  },
  {
    x: 2198.260000000071,
    y: 0.0006175041198730469,
  },
  {
    x: 2198.2700000000714,
    y: 0.0006155967712402344,
  },
  {
    x: 2198.2800000000716,
    y: 0.0006146430969238281,
  },
  {
    x: 2198.290000000072,
    y: 0.0006132125854492188,
  },
  {
    x: 2198.300000000072,
    y: 0.0006122589111328125,
  },
  {
    x: 2198.3100000000723,
    y: 0.0006117820739746094,
  },
  {
    x: 2198.3200000000725,
    y: 0.0006113052368164062,
  },
  {
    x: 2198.3300000000727,
    y: 0.0006108283996582031,
  },
  {
    x: 2198.340000000073,
    y: 0.0006103515625,
  },
  {
    x: 2198.350000000073,
    y: 0.0006103515625,
  },
  {
    x: 2198.3600000000733,
    y: 0.0006103515625,
  },
  {
    x: 2198.3700000000736,
    y: 0.0006108283996582031,
  },
  {
    x: 2198.380000000074,
    y: 0.0006113052368164062,
  },
  {
    x: 2198.390000000074,
    y: 0.0006117820739746094,
  },
  {
    x: 2198.400000000074,
    y: 0.0006122589111328125,
  },
  {
    x: 2198.4100000000744,
    y: 0.0006127357482910156,
  },
  {
    x: 2198.4200000000747,
    y: 0.0006136894226074219,
  },
  {
    x: 2198.430000000075,
    y: 0.0006146430969238281,
  },
  {
    x: 2198.440000000075,
    y: 0.0006160736083984375,
  },
  {
    x: 2198.4500000000753,
    y: 0.0006175041198730469,
  },
  {
    x: 2198.4600000000755,
    y: 0.0006189346313476562,
  },
  {
    x: 2198.4700000000757,
    y: 0.0006203651428222656,
  },
  {
    x: 2198.480000000076,
    y: 0.0006222724914550781,
  },
  {
    x: 2198.490000000076,
    y: 0.0006241798400878906,
  },
  {
    x: 2198.5000000000764,
    y: 0.0006260871887207031,
  },
  {
    x: 2198.5100000000766,
    y: 0.0006284713745117188,
  },
  {
    x: 2198.520000000077,
    y: 0.0006303787231445312,
  },
  {
    x: 2198.530000000077,
    y: 0.00063323974609375,
  },
  {
    x: 2198.5400000000773,
    y: 0.0006356239318847656,
  },
  {
    x: 2198.5500000000775,
    y: 0.0006384849548339844,
  },
  {
    x: 2198.5600000000777,
    y: 0.0006418228149414062,
  },
  {
    x: 2198.570000000078,
    y: 0.000644683837890625,
  },
  {
    x: 2198.580000000078,
    y: 0.0006480216979980469,
  },
  {
    x: 2198.5900000000784,
    y: 0.0006518363952636719,
  },
  {
    x: 2198.6000000000786,
    y: 0.0006556510925292969,
  },
  {
    x: 2198.610000000079,
    y: 0.0006594657897949219,
  },
  {
    x: 2198.620000000079,
    y: 0.00066375732421875,
  },
  {
    x: 2198.6300000000792,
    y: 0.0006685256958007812,
  },
  {
    x: 2198.6400000000795,
    y: 0.0006732940673828125,
  },
  {
    x: 2198.6500000000797,
    y: 0.00067901611328125,
  },
  {
    x: 2198.66000000008,
    y: 0.0006842613220214844,
  },
  {
    x: 2198.67000000008,
    y: 0.0006899833679199219,
  },
  {
    x: 2198.6800000000803,
    y: 0.0006952285766601562,
  },
  {
    x: 2198.6900000000805,
    y: 0.0006999969482421875,
  },
  {
    x: 2198.7000000000808,
    y: 0.0007047653198242188,
  },
  {
    x: 2198.710000000081,
    y: 0.00070953369140625,
  },
  {
    x: 2198.720000000081,
    y: 0.0007143020629882812,
  },
  {
    x: 2198.7300000000814,
    y: 0.0007195472717285156,
  },
  {
    x: 2198.7400000000816,
    y: 0.0007252693176269531,
  },
  {
    x: 2198.750000000082,
    y: 0.0007314682006835938,
  },
  {
    x: 2198.760000000082,
    y: 0.0007381439208984375,
  },
  {
    x: 2198.7700000000823,
    y: 0.0007448196411132812,
  },
  {
    x: 2198.7800000000825,
    y: 0.000751495361328125,
  },
  {
    x: 2198.7900000000827,
    y: 0.000759124755859375,
  },
  {
    x: 2198.800000000083,
    y: 0.000766754150390625,
  },
  {
    x: 2198.810000000083,
    y: 0.0007748603820800781,
  },
  {
    x: 2198.8200000000834,
    y: 0.0007829666137695312,
  },
  {
    x: 2198.8300000000836,
    y: 0.0007915496826171875,
  },
  {
    x: 2198.840000000084,
    y: 0.0008006095886230469,
  },
  {
    x: 2198.850000000084,
    y: 0.0008096694946289062,
  },
  {
    x: 2198.8600000000843,
    y: 0.0008192062377929688,
  },
  {
    x: 2198.8700000000845,
    y: 0.0008292198181152344,
  },
  {
    x: 2198.8800000000847,
    y: 0.0008392333984375,
  },
  {
    x: 2198.890000000085,
    y: 0.0008497238159179688,
  },
  {
    x: 2198.900000000085,
    y: 0.0008606910705566406,
  },
  {
    x: 2198.9100000000853,
    y: 0.0008721351623535156,
  },
  {
    x: 2198.9200000000856,
    y: 0.0008840560913085938,
  },
  {
    x: 2198.930000000086,
    y: 0.0008959770202636719,
  },
  {
    x: 2198.940000000086,
    y: 0.0009088516235351562,
  },
  {
    x: 2198.950000000086,
    y: 0.0009217262268066406,
  },
  {
    x: 2198.9600000000864,
    y: 0.0009355545043945312,
  },
  {
    x: 2198.9700000000867,
    y: 0.0009493827819824219,
  },
  {
    x: 2198.980000000087,
    y: 0.0009636878967285156,
  },
  {
    x: 2198.990000000087,
    y: 0.0009784698486328125,
  },
  {
    x: 2199.0000000000873,
    y: 0.0009946823120117188,
  },
  {
    x: 2199.0100000000875,
    y: 0.001010894775390625,
  },
  {
    x: 2199.0200000000877,
    y: 0.0010271072387695312,
  },
  {
    x: 2199.030000000088,
    y: 0.00104522705078125,
  },
  {
    x: 2199.040000000088,
    y: 0.0010623931884765625,
  },
  {
    x: 2199.0500000000884,
    y: 0.0010814666748046875,
  },
  {
    x: 2199.0600000000886,
    y: 0.0011005401611328125,
  },
  {
    x: 2199.070000000089,
    y: 0.0011205673217773438,
  },
  {
    x: 2199.080000000089,
    y: 0.0011415481567382812,
  },
  {
    x: 2199.0900000000893,
    y: 0.001163482666015625,
  },
  {
    x: 2199.1000000000895,
    y: 0.001186370849609375,
  },
  {
    x: 2199.1100000000897,
    y: 0.001209259033203125,
  },
  {
    x: 2199.12000000009,
    y: 0.0012340545654296875,
  },
  {
    x: 2199.13000000009,
    y: 0.00125885009765625,
  },
  {
    x: 2199.1400000000904,
    y: 0.001285552978515625,
  },
  {
    x: 2199.1500000000906,
    y: 0.0013132095336914062,
  },
  {
    x: 2199.160000000091,
    y: 0.0013418197631835938,
  },
  {
    x: 2199.170000000091,
    y: 0.0013713836669921875,
  },
  {
    x: 2199.1800000000912,
    y: 0.0014019012451171875,
  },
  {
    x: 2199.1900000000915,
    y: 0.001434326171875,
  },
  {
    x: 2199.2000000000917,
    y: 0.0014677047729492188,
  },
  {
    x: 2199.210000000092,
    y: 0.00150299072265625,
  },
  {
    x: 2199.220000000092,
    y: 0.0015392303466796875,
  },
  {
    x: 2199.2300000000923,
    y: 0.0015773773193359375,
  },
  {
    x: 2199.2400000000926,
    y: 0.001617431640625,
  },
  {
    x: 2199.2500000000928,
    y: 0.001659393310546875,
  },
  {
    x: 2199.260000000093,
    y: 0.0017032623291015625,
  },
  {
    x: 2199.270000000093,
    y: 0.0017490386962890625,
  },
  {
    x: 2199.2800000000934,
    y: 0.001796722412109375,
  },
  {
    x: 2199.2900000000936,
    y: 0.0018463134765625,
  },
  {
    x: 2199.300000000094,
    y: 0.0018987655639648438,
  },
  {
    x: 2199.310000000094,
    y: 0.0019550323486328125,
  },
  {
    x: 2199.3200000000943,
    y: 0.0020122528076171875,
  },
  {
    x: 2199.3300000000945,
    y: 0.0020732879638671875,
  },
  {
    x: 2199.3400000000947,
    y: 0.00213623046875,
  },
  {
    x: 2199.350000000095,
    y: 0.00220489501953125,
  },
  {
    x: 2199.360000000095,
    y: 0.0022754669189453125,
  },
  {
    x: 2199.3700000000954,
    y: 0.002349853515625,
  },
  {
    x: 2199.3800000000956,
    y: 0.002429962158203125,
  },
  {
    x: 2199.390000000096,
    y: 0.0025119781494140625,
  },
  {
    x: 2199.400000000096,
    y: 0.00260162353515625,
  },
  {
    x: 2199.4100000000963,
    y: 0.00269317626953125,
  },
  {
    x: 2199.4200000000965,
    y: 0.0027923583984375,
  },
  {
    x: 2199.4300000000967,
    y: 0.0028972625732421875,
  },
  {
    x: 2199.440000000097,
    y: 0.0030078887939453125,
  },
  {
    x: 2199.450000000097,
    y: 0.0031261444091796875,
  },
  {
    x: 2199.4600000000974,
    y: 0.0032520294189453125,
  },
  {
    x: 2199.4700000000976,
    y: 0.003387451171875,
  },
  {
    x: 2199.480000000098,
    y: 0.003528594970703125,
  },
  {
    x: 2199.490000000098,
    y: 0.0036830902099609375,
  },
  {
    x: 2199.500000000098,
    y: 0.00384521484375,
  },
  {
    x: 2199.5100000000984,
    y: 0.00402069091796875,
  },
  {
    x: 2199.5200000000987,
    y: 0.004207611083984375,
  },
  {
    x: 2199.530000000099,
    y: 0.0044097900390625,
  },
  {
    x: 2199.540000000099,
    y: 0.004627227783203125,
  },
  {
    x: 2199.5500000000993,
    y: 0.00485992431640625,
  },
  {
    x: 2199.5600000000995,
    y: 0.005115509033203125,
  },
  {
    x: 2199.5700000000998,
    y: 0.0053863525390625,
  },
  {
    x: 2199.5800000001,
    y: 0.00568389892578125,
  },
  {
    x: 2199.5900000001,
    y: 0.006008148193359375,
  },
  {
    x: 2199.6000000001004,
    y: 0.006359100341796875,
  },
  {
    x: 2199.6100000001006,
    y: 0.006744384765625,
  },
  {
    x: 2199.620000000101,
    y: 0.007167816162109375,
  },
  {
    x: 2199.630000000101,
    y: 0.00762939453125,
  },
  {
    x: 2199.6400000001013,
    y: 0.00814056396484375,
  },
  {
    x: 2199.6500000001015,
    y: 0.00870513916015625,
  },
  {
    x: 2199.6600000001017,
    y: 0.0093231201171875,
  },
  {
    x: 2199.670000000102,
    y: 0.01001739501953125,
  },
  {
    x: 2199.680000000102,
    y: 0.01079559326171875,
  },
  {
    x: 2199.6900000001024,
    y: 0.01166534423828125,
  },
  {
    x: 2199.7000000001026,
    y: 0.01264190673828125,
  },
  {
    x: 2199.710000000103,
    y: 0.0137481689453125,
  },
  {
    x: 2199.720000000103,
    y: 0.0149993896484375,
  },
  {
    x: 2199.7300000001032,
    y: 0.0164337158203125,
  },
  {
    x: 2199.7400000001035,
    y: 0.01806640625,
  },
  {
    x: 2199.7500000001037,
    y: 0.0199737548828125,
  },
  {
    x: 2199.760000000104,
    y: 0.0221710205078125,
  },
  {
    x: 2199.770000000104,
    y: 0.024749755859375,
  },
  {
    x: 2199.7800000001043,
    y: 0.0277862548828125,
  },
  {
    x: 2199.7900000001046,
    y: 0.031402587890625,
  },
  {
    x: 2199.800000000105,
    y: 0.03570556640625,
  },
  {
    x: 2199.810000000105,
    y: 0.040924072265625,
  },
  {
    x: 2199.820000000105,
    y: 0.047271728515625,
  },
  {
    x: 2199.8300000001054,
    y: 0.0550537109375,
  },
  {
    x: 2199.8400000001056,
    y: 0.064697265625,
  },
  {
    x: 2199.850000000106,
    y: 0.07666015625,
  },
  {
    x: 2199.860000000106,
    y: 0.09161376953125,
  },
  {
    x: 2199.8700000001063,
    y: 0.11016845703125,
  },
  {
    x: 2199.8800000001065,
    y: 0.1328125,
  },
  {
    x: 2199.8900000001067,
    y: 0.159423828125,
  },
  {
    x: 2199.900000000107,
    y: 0.1884765625,
  },
  {
    x: 2199.910000000107,
    y: 0.2159423828125,
  },
  {
    x: 2199.9200000001074,
    y: 0.2357177734375,
  },
  {
    x: 2199.9300000001076,
    y: 0.2412109375,
  },
  {
    x: 2199.940000000108,
    y: 0.2305908203125,
  },
  {
    x: 2199.950000000108,
    y: 0.20751953125,
  },
  {
    x: 2199.9600000001083,
    y: 0.1788330078125,
  },
  {
    x: 2199.9700000001085,
    y: 0.150390625,
  },
  {
    x: 2199.9800000001087,
    y: 0.12493896484375,
  },
  {
    x: 2199.990000000109,
    y: 0.1036376953125,
  },
  {
    x: 2200.000000000109,
    y: 0.08636474609375,
  },
  {
    x: 2200.0100000001094,
    y: 0.07244873046875,
  },
  {
    x: 2200.0200000001096,
    y: 0.061309814453125,
  },
  {
    x: 2200.03000000011,
    y: 0.05230712890625,
  },
  {
    x: 2200.04000000011,
    y: 0.0450439453125,
  },
  {
    x: 2200.0500000001102,
    y: 0.039093017578125,
  },
  {
    x: 2200.0600000001104,
    y: 0.034210205078125,
  },
  {
    x: 2200.0700000001107,
    y: 0.0301361083984375,
  },
  {
    x: 2200.080000000111,
    y: 0.0267333984375,
  },
  {
    x: 2200.090000000111,
    y: 0.02386474609375,
  },
  {
    x: 2200.1000000001113,
    y: 0.0214080810546875,
  },
  {
    x: 2200.1100000001115,
    y: 0.019317626953125,
  },
  {
    x: 2200.1200000001118,
    y: 0.0175018310546875,
  },
  {
    x: 2200.130000000112,
    y: 0.01593017578125,
  },
  {
    x: 2200.140000000112,
    y: 0.01456451416015625,
  },
  {
    x: 2200.1500000001124,
    y: 0.01336669921875,
  },
  {
    x: 2200.1600000001126,
    y: 0.012298583984375,
  },
  {
    x: 2200.170000000113,
    y: 0.01136016845703125,
  },
  {
    x: 2200.180000000113,
    y: 0.010528564453125,
  },
  {
    x: 2200.1900000001133,
    y: 0.0097808837890625,
  },
  {
    x: 2200.2000000001135,
    y: 0.0091094970703125,
  },
  {
    x: 2200.2100000001137,
    y: 0.00850677490234375,
  },
  {
    x: 2200.220000000114,
    y: 0.00795745849609375,
  },
  {
    x: 2200.230000000114,
    y: 0.007465362548828125,
  },
  {
    x: 2200.2400000001144,
    y: 0.00701904296875,
  },
  {
    x: 2200.2500000001146,
    y: 0.0066070556640625,
  },
  {
    x: 2200.260000000115,
    y: 0.00623321533203125,
  },
  {
    x: 2200.270000000115,
    y: 0.005893707275390625,
  },
  {
    x: 2200.2800000001153,
    y: 0.00557708740234375,
  },
  {
    x: 2200.2900000001155,
    y: 0.00528717041015625,
  },
  {
    x: 2200.3000000001157,
    y: 0.0050201416015625,
  },
  {
    x: 2200.310000000116,
    y: 0.004772186279296875,
  },
  {
    x: 2200.320000000116,
    y: 0.004543304443359375,
  },
  {
    x: 2200.3300000001163,
    y: 0.00433349609375,
  },
  {
    x: 2200.3400000001166,
    y: 0.0041351318359375,
  },
  {
    x: 2200.350000000117,
    y: 0.0039520263671875,
  },
  {
    x: 2200.360000000117,
    y: 0.0037784576416015625,
  },
  {
    x: 2200.370000000117,
    y: 0.003620147705078125,
  },
  {
    x: 2200.3800000001174,
    y: 0.0034694671630859375,
  },
  {
    x: 2200.3900000001177,
    y: 0.003330230712890625,
  },
  {
    x: 2200.400000000118,
    y: 0.0031986236572265625,
  },
  {
    x: 2200.410000000118,
    y: 0.00307464599609375,
  },
  {
    x: 2200.4200000001183,
    y: 0.0029582977294921875,
  },
  {
    x: 2200.4300000001185,
    y: 0.002849578857421875,
  },
  {
    x: 2200.4400000001187,
    y: 0.00274658203125,
  },
  {
    x: 2200.450000000119,
    y: 0.0026493072509765625,
  },
  {
    x: 2200.460000000119,
    y: 0.00255584716796875,
  },
  {
    x: 2200.4700000001194,
    y: 0.0024700164794921875,
  },
  {
    x: 2200.4800000001196,
    y: 0.00238800048828125,
  },
  {
    x: 2200.49000000012,
    y: 0.0023097991943359375,
  },
  {
    x: 2200.50000000012,
    y: 0.00223541259765625,
  },
  {
    x: 2200.5100000001203,
    y: 0.002166748046875,
  },
  {
    x: 2200.5200000001205,
    y: 0.0020999908447265625,
  },
  {
    x: 2200.5300000001207,
    y: 0.0020351409912109375,
  },
  {
    x: 2200.540000000121,
    y: 0.00197601318359375,
  },
  {
    x: 2200.550000000121,
    y: 0.001918792724609375,
  },
  {
    x: 2200.5600000001214,
    y: 0.0018634796142578125,
  },
  {
    x: 2200.5700000001216,
    y: 0.001811981201171875,
  },
  {
    x: 2200.580000000122,
    y: 0.00176239013671875,
  },
  {
    x: 2200.590000000122,
    y: 0.0017147064208984375,
  },
  {
    x: 2200.6000000001222,
    y: 0.0016689300537109375,
  },
  {
    x: 2200.6100000001225,
    y: 0.0016260147094726562,
  },
  {
    x: 2200.6200000001227,
    y: 0.0015850067138671875,
  },
  {
    x: 2200.630000000123,
    y: 0.001544952392578125,
  },
  {
    x: 2200.640000000123,
    y: 0.0015077590942382812,
  },
  {
    x: 2200.6500000001233,
    y: 0.0014715194702148438,
  },
  {
    x: 2200.6600000001235,
    y: 0.0014362335205078125,
  },
  {
    x: 2200.6700000001238,
    y: 0.00140380859375,
  },
  {
    x: 2200.680000000124,
    y: 0.0013713836669921875,
  },
  {
    x: 2200.690000000124,
    y: 0.0013418197631835938,
  },
  {
    x: 2200.7000000001244,
    y: 0.001312255859375,
  },
  {
    x: 2200.7100000001246,
    y: 0.0012836456298828125,
  },
  {
    x: 2200.720000000125,
    y: 0.001255035400390625,
  },
  {
    x: 2200.730000000125,
    y: 0.00122833251953125,
  },
  {
    x: 2200.7400000001253,
    y: 0.0012025833129882812,
  },
  {
    x: 2200.7500000001255,
    y: 0.0011777877807617188,
  },
  {
    x: 2200.7600000001257,
    y: 0.0011539459228515625,
  },
  {
    x: 2200.770000000126,
    y: 0.0011310577392578125,
  },
  {
    x: 2200.780000000126,
    y: 0.0011091232299804688,
  },
  {
    x: 2200.7900000001264,
    y: 0.0010881423950195312,
  },
  {
    x: 2200.8000000001266,
    y: 0.001068115234375,
  },
  {
    x: 2200.810000000127,
    y: 0.0010480880737304688,
  },
  {
    x: 2200.820000000127,
    y: 0.0010290145874023438,
  },
  {
    x: 2200.8300000001273,
    y: 0.001010894775390625,
  },
  {
    x: 2200.8400000001275,
    y: 0.0009927749633789062,
  },
  {
    x: 2200.8500000001277,
    y: 0.0009760856628417969,
  },
  {
    x: 2200.860000000128,
    y: 0.0009593963623046875,
  },
  {
    x: 2200.870000000128,
    y: 0.0009436607360839844,
  },
  {
    x: 2200.8800000001283,
    y: 0.0009284019470214844,
  },
  {
    x: 2200.8900000001286,
    y: 0.0009136199951171875,
  },
  {
    x: 2200.900000000129,
    y: 0.0008988380432128906,
  },
  {
    x: 2200.910000000129,
    y: 0.000885009765625,
  },
  {
    x: 2200.920000000129,
    y: 0.0008716583251953125,
  },
  {
    x: 2200.9300000001294,
    y: 0.0008587837219238281,
  },
  {
    x: 2200.9400000001297,
    y: 0.0008463859558105469,
  },
  {
    x: 2200.95000000013,
    y: 0.0008339881896972656,
  },
  {
    x: 2200.96000000013,
    y: 0.0008220672607421875,
  },
  {
    x: 2200.9700000001303,
    y: 0.0008106231689453125,
  },
  {
    x: 2200.9800000001305,
    y: 0.0007996559143066406,
  },
  {
    x: 2200.9900000001307,
    y: 0.0007891654968261719,
  },
  {
    x: 2201.000000000131,
    y: 0.0007786750793457031,
  },
  {
    x: 2201.010000000131,
    y: 0.0007686614990234375,
  },
  {
    x: 2201.0200000001314,
    y: 0.000759124755859375,
  },
  {
    x: 2201.0300000001316,
    y: 0.0007495880126953125,
  },
  {
    x: 2201.040000000132,
    y: 0.0007405281066894531,
  },
  {
    x: 2201.050000000132,
    y: 0.0007319450378417969,
  },
  {
    x: 2201.0600000001323,
    y: 0.0007233619689941406,
  },
  {
    x: 2201.0700000001325,
    y: 0.0007147789001464844,
  },
  {
    x: 2201.0800000001327,
    y: 0.0007071495056152344,
  },
  {
    x: 2201.090000000133,
    y: 0.0006990432739257812,
  },
  {
    x: 2201.100000000133,
    y: 0.0006918907165527344,
  },
  {
    x: 2201.1100000001334,
    y: 0.0006842613220214844,
  },
  {
    x: 2201.1200000001336,
    y: 0.0006771087646484375,
  },
  {
    x: 2201.130000000134,
    y: 0.0006704330444335938,
  },
  {
    x: 2201.140000000134,
    y: 0.00066375732421875,
  },
  {
    x: 2201.1500000001342,
    y: 0.0006575584411621094,
  },
  {
    x: 2201.1600000001345,
    y: 0.0006513595581054688,
  },
  {
    x: 2201.1700000001347,
    y: 0.0006451606750488281,
  },
  {
    x: 2201.180000000135,
    y: 0.0006394386291503906,
  },
  {
    x: 2201.190000000135,
    y: 0.0006341934204101562,
  },
  {
    x: 2201.2000000001353,
    y: 0.0006284713745117188,
  },
  {
    x: 2201.2100000001356,
    y: 0.0006232261657714844,
  },
  {
    x: 2201.2200000001358,
    y: 0.0006184577941894531,
  },
  {
    x: 2201.230000000136,
    y: 0.0006136894226074219,
  },
  {
    x: 2201.240000000136,
    y: 0.0006089210510253906,
  },
  {
    x: 2201.2500000001364,
    y: 0.0006041526794433594,
  },
  {
    x: 2201.2600000001366,
    y: 0.0005998611450195312,
  },
  {
    x: 2201.270000000137,
    y: 0.0005955696105957031,
  },
  {
    x: 2201.280000000137,
    y: 0.000591278076171875,
  },
  {
    x: 2201.2900000001373,
    y: 0.00058746337890625,
  },
  {
    x: 2201.3000000001375,
    y: 0.000583648681640625,
  },
  {
    x: 2201.3100000001377,
    y: 0.000579833984375,
  },
  {
    x: 2201.320000000138,
    y: 0.0005764961242675781,
  },
  {
    x: 2201.330000000138,
    y: 0.0005731582641601562,
  },
  {
    x: 2201.3400000001384,
    y: 0.0005698204040527344,
  },
  {
    x: 2201.3500000001386,
    y: 0.0005669593811035156,
  },
  {
    x: 2201.360000000139,
    y: 0.0005640983581542969,
  },
  {
    x: 2201.370000000139,
    y: 0.0005612373352050781,
  },
  {
    x: 2201.3800000001393,
    y: 0.0005583763122558594,
  },
  {
    x: 2201.3900000001395,
    y: 0.0005559921264648438,
  },
  {
    x: 2201.4000000001397,
    y: 0.0005536079406738281,
  },
  {
    x: 2201.41000000014,
    y: 0.0005512237548828125,
  },
  {
    x: 2201.42000000014,
    y: 0.00054931640625,
  },
  {
    x: 2201.4300000001404,
    y: 0.0005474090576171875,
  },
  {
    x: 2201.4400000001406,
    y: 0.000545501708984375,
  },
  {
    x: 2201.450000000141,
    y: 0.0005440711975097656,
  },
  {
    x: 2201.460000000141,
    y: 0.0005426406860351562,
  },
  {
    x: 2201.470000000141,
    y: 0.00054168701171875,
  },
  {
    x: 2201.4800000001414,
    y: 0.0005407333374023438,
  },
  {
    x: 2201.4900000001417,
    y: 0.0005397796630859375,
  },
  {
    x: 2201.500000000142,
    y: 0.0005393028259277344,
  },
  {
    x: 2201.510000000142,
    y: 0.000537872314453125,
  },
  {
    x: 2201.5200000001423,
    y: 0.0005364418029785156,
  },
  {
    x: 2201.5300000001425,
    y: 0.0005350112915039062,
  },
  {
    x: 2201.5400000001428,
    y: 0.0005335807800292969,
  },
  {
    x: 2201.550000000143,
    y: 0.0005321502685546875,
  },
  {
    x: 2201.560000000143,
    y: 0.0005311965942382812,
  },
  {
    x: 2201.5700000001434,
    y: 0.000530242919921875,
  },
  {
    x: 2201.5800000001436,
    y: 0.0005297660827636719,
  },
  {
    x: 2201.590000000144,
    y: 0.0005292892456054688,
  },
  {
    x: 2201.600000000144,
    y: 0.0005288124084472656,
  },
  {
    x: 2201.6100000001443,
    y: 0.0005283355712890625,
  },
  {
    x: 2201.6200000001445,
    y: 0.0005283355712890625,
  },
  {
    x: 2201.6300000001447,
    y: 0.0005283355712890625,
  },
  {
    x: 2201.640000000145,
    y: 0.0005288124084472656,
  },
  {
    x: 2201.650000000145,
    y: 0.0005288124084472656,
  },
  {
    x: 2201.6600000001454,
    y: 0.0005292892456054688,
  },
  {
    x: 2201.6700000001456,
    y: 0.0005297660827636719,
  },
  {
    x: 2201.680000000146,
    y: 0.0005307197570800781,
  },
  {
    x: 2201.690000000146,
    y: 0.0005311965942382812,
  },
  {
    x: 2201.7000000001462,
    y: 0.0005321502685546875,
  },
  {
    x: 2201.7100000001465,
    y: 0.0005331039428710938,
  },
  {
    x: 2201.7200000001467,
    y: 0.0005340576171875,
  },
  {
    x: 2201.730000000147,
    y: 0.0005354881286621094,
  },
  {
    x: 2201.740000000147,
    y: 0.0005369186401367188,
  },
  {
    x: 2201.7500000001473,
    y: 0.0005383491516113281,
  },
  {
    x: 2201.7600000001476,
    y: 0.0005397796630859375,
  },
  {
    x: 2201.770000000148,
    y: 0.00054168701171875,
  },
  {
    x: 2201.780000000148,
    y: 0.0005435943603515625,
  },
  {
    x: 2201.790000000148,
    y: 0.000545501708984375,
  },
  {
    x: 2201.8000000001484,
    y: 0.0005474090576171875,
  },
  {
    x: 2201.8100000001486,
    y: 0.0005497932434082031,
  },
  {
    x: 2201.820000000149,
    y: 0.0005521774291992188,
  },
  {
    x: 2201.830000000149,
    y: 0.0005545616149902344,
  },
  {
    x: 2201.8400000001493,
    y: 0.0005574226379394531,
  },
  {
    x: 2201.8500000001495,
    y: 0.0005598068237304688,
  },
  {
    x: 2201.8600000001497,
    y: 0.0005626678466796875,
  },
  {
    x: 2201.87000000015,
    y: 0.0005660057067871094,
  },
  {
    x: 2201.88000000015,
    y: 0.0005693435668945312,
  },
  {
    x: 2201.8900000001504,
    y: 0.0005726814270019531,
  },
  {
    x: 2201.9000000001506,
    y: 0.000576019287109375,
  },
  {
    x: 2201.910000000151,
    y: 0.000579833984375,
  },
  {
    x: 2201.920000000151,
    y: 0.000583648681640625,
  },
  {
    x: 2201.9300000001513,
    y: 0.00058746337890625,
  },
  {
    x: 2201.9400000001515,
    y: 0.0005917549133300781,
  },
  {
    x: 2201.9500000001517,
    y: 0.0005960464477539062,
  },
  {
    x: 2201.960000000152,
    y: 0.0006003379821777344,
  },
  {
    x: 2201.970000000152,
    y: 0.0006051063537597656,
  },
  {
    x: 2201.9800000001524,
    y: 0.0006098747253417969,
  },
  {
    x: 2201.9900000001526,
    y: 0.0006151199340820312,
  },
  {
    x: 2202.000000000153,
    y: 0.0006203651428222656,
  },
  {
    x: 2202.010000000153,
    y: 0.0006256103515625,
  },
  {
    x: 2202.0200000001532,
    y: 0.0006313323974609375,
  },
  {
    x: 2202.0300000001534,
    y: 0.000637054443359375,
  },
  {
    x: 2202.0400000001537,
    y: 0.0006432533264160156,
  },
  {
    x: 2202.050000000154,
    y: 0.0006494522094726562,
  },
  {
    x: 2202.060000000154,
    y: 0.0006561279296875,
  },
  {
    x: 2202.0700000001543,
    y: 0.0006628036499023438,
  },
  {
    x: 2202.0800000001545,
    y: 0.0006699562072753906,
  },
  {
    x: 2202.0900000001548,
    y: 0.0006771087646484375,
  },
  {
    x: 2202.100000000155,
    y: 0.0006847381591796875,
  },
  {
    x: 2202.110000000155,
    y: 0.0006928443908691406,
  },
  {
    x: 2202.1200000001554,
    y: 0.0007009506225585938,
  },
  {
    x: 2202.1300000001556,
    y: 0.00070953369140625,
  },
  {
    x: 2202.140000000156,
    y: 0.0007181167602539062,
  },
  {
    x: 2202.150000000156,
    y: 0.0007271766662597656,
  },
  {
    x: 2202.1600000001563,
    y: 0.000736236572265625,
  },
  {
    x: 2202.1700000001565,
    y: 0.0007462501525878906,
  },
  {
    x: 2202.1800000001567,
    y: 0.0007562637329101562,
  },
  {
    x: 2202.190000000157,
    y: 0.000766754150390625,
  },
  {
    x: 2202.200000000157,
    y: 0.0007772445678710938,
  },
  {
    x: 2202.2100000001574,
    y: 0.0007886886596679688,
  },
  {
    x: 2202.2200000001576,
    y: 0.0008001327514648438,
  },
  {
    x: 2202.230000000158,
    y: 0.000812530517578125,
  },
  {
    x: 2202.240000000158,
    y: 0.0008249282836914062,
  },
  {
    x: 2202.2500000001583,
    y: 0.0008378028869628906,
  },
  {
    x: 2202.2600000001585,
    y: 0.0008511543273925781,
  },
  {
    x: 2202.2700000001587,
    y: 0.0008649826049804688,
  },
  {
    x: 2202.280000000159,
    y: 0.0008797645568847656,
  },
  {
    x: 2202.290000000159,
    y: 0.0008945465087890625,
  },
  {
    x: 2202.3000000001593,
    y: 0.0009102821350097656,
  },
  {
    x: 2202.3100000001596,
    y: 0.0009264945983886719,
  },
  {
    x: 2202.32000000016,
    y: 0.0009431838989257812,
  },
  {
    x: 2202.33000000016,
    y: 0.0009608268737792969,
  },
  {
    x: 2202.34000000016,
    y: 0.0009784698486328125,
  },
  {
    x: 2202.3500000001604,
    y: 0.0009975433349609375,
  },
  {
    x: 2202.3600000001607,
    y: 0.0010175704956054688,
  },
  {
    x: 2202.370000000161,
    y: 0.00103759765625,
  },
  {
    x: 2202.380000000161,
    y: 0.0010595321655273438,
  },
  {
    x: 2202.3900000001613,
    y: 0.0010814666748046875,
  },
  {
    x: 2202.4000000001615,
    y: 0.0011043548583984375,
  },
  {
    x: 2202.4100000001617,
    y: 0.0011281967163085938,
  },
  {
    x: 2202.420000000162,
    y: 0.0011539459228515625,
  },
  {
    x: 2202.430000000162,
    y: 0.0011796951293945312,
  },
  {
    x: 2202.4400000001624,
    y: 0.0012073516845703125,
  },
  {
    x: 2202.4500000001626,
    y: 0.0012359619140625,
  },
  {
    x: 2202.460000000163,
    y: 0.0012655258178710938,
  },
  {
    x: 2202.470000000163,
    y: 0.0012969970703125,
  },
  {
    x: 2202.4800000001633,
    y: 0.0013294219970703125,
  },
  {
    x: 2202.4900000001635,
    y: 0.0013628005981445312,
  },
  {
    x: 2202.5000000001637,
    y: 0.0013990402221679688,
  },
  {
    x: 2202.510000000164,
    y: 0.0014362335205078125,
  },
  {
    x: 2202.520000000164,
    y: 0.0014753341674804688,
  },
  {
    x: 2202.5300000001644,
    y: 0.0015163421630859375,
  },
  {
    x: 2202.5400000001646,
    y: 0.0015592575073242188,
  },
  {
    x: 2202.550000000165,
    y: 0.0016050338745117188,
  },
  {
    x: 2202.560000000165,
    y: 0.001651763916015625,
  },
  {
    x: 2202.5700000001652,
    y: 0.0017023086547851562,
  },
  {
    x: 2202.5800000001655,
    y: 0.0017547607421875,
  },
  {
    x: 2202.5900000001657,
    y: 0.0018100738525390625,
  },
  {
    x: 2202.600000000166,
    y: 0.00186920166015625,
  },
  {
    x: 2202.610000000166,
    y: 0.00193023681640625,
  },
  {
    x: 2202.6200000001663,
    y: 0.001995086669921875,
  },
  {
    x: 2202.6300000001665,
    y: 0.002063751220703125,
  },
  {
    x: 2202.6400000001668,
    y: 0.0021381378173828125,
  },
  {
    x: 2202.650000000167,
    y: 0.0022144317626953125,
  },
  {
    x: 2202.660000000167,
    y: 0.00229644775390625,
  },
  {
    x: 2202.6700000001674,
    y: 0.002384185791015625,
  },
  {
    x: 2202.6800000001676,
    y: 0.002475738525390625,
  },
  {
    x: 2202.690000000168,
    y: 0.002574920654296875,
  },
  {
    x: 2202.700000000168,
    y: 0.0026798248291015625,
  },
  {
    x: 2202.7100000001683,
    y: 0.0027923583984375,
  },
  {
    x: 2202.7200000001685,
    y: 0.002910614013671875,
  },
  {
    x: 2202.7300000001687,
    y: 0.0030384063720703125,
  },
  {
    x: 2202.740000000169,
    y: 0.0031757354736328125,
  },
  {
    x: 2202.750000000169,
    y: 0.003322601318359375,
  },
  {
    x: 2202.7600000001694,
    y: 0.0034809112548828125,
  },
  {
    x: 2202.7700000001696,
    y: 0.003650665283203125,
  },
  {
    x: 2202.78000000017,
    y: 0.003833770751953125,
  },
  {
    x: 2202.79000000017,
    y: 0.004032135009765625,
  },
  {
    x: 2202.8000000001703,
    y: 0.004245758056640625,
  },
  {
    x: 2202.8100000001705,
    y: 0.004482269287109375,
  },
  {
    x: 2202.8200000001707,
    y: 0.004734039306640625,
  },
  {
    x: 2202.830000000171,
    y: 0.005008697509765625,
  },
  {
    x: 2202.840000000171,
    y: 0.00531005859375,
  },
  {
    x: 2202.8500000001713,
    y: 0.005641937255859375,
  },
  {
    x: 2202.8600000001716,
    y: 0.00600433349609375,
  },
  {
    x: 2202.870000000172,
    y: 0.006404876708984375,
  },
  {
    x: 2202.880000000172,
    y: 0.006847381591796875,
  },
  {
    x: 2202.890000000172,
    y: 0.007335662841796875,
  },
  {
    x: 2202.9000000001724,
    y: 0.00788116455078125,
  },
  {
    x: 2202.9100000001727,
    y: 0.00849151611328125,
  },
  {
    x: 2202.920000000173,
    y: 0.0091705322265625,
  },
  {
    x: 2202.930000000173,
    y: 0.00994110107421875,
  },
  {
    x: 2202.9400000001733,
    y: 0.01080322265625,
  },
  {
    x: 2202.9500000001735,
    y: 0.01178741455078125,
  },
  {
    x: 2202.9600000001737,
    y: 0.01291656494140625,
  },
  {
    x: 2202.970000000174,
    y: 0.0142059326171875,
  },
  {
    x: 2202.980000000174,
    y: 0.0157012939453125,
  },
  {
    x: 2202.9900000001744,
    y: 0.0174407958984375,
  },
  {
    x: 2203.0000000001746,
    y: 0.01947021484375,
  },
  {
    x: 2203.010000000175,
    y: 0.0218505859375,
  },
  {
    x: 2203.020000000175,
    y: 0.0247039794921875,
  },
  {
    x: 2203.0300000001753,
    y: 0.028106689453125,
  },
  {
    x: 2203.0400000001755,
    y: 0.0322265625,
  },
  {
    x: 2203.0500000001757,
    y: 0.0372314453125,
  },
  {
    x: 2203.060000000176,
    y: 0.04339599609375,
  },
  {
    x: 2203.070000000176,
    y: 0.051055908203125,
  },
  {
    x: 2203.0800000001764,
    y: 0.060577392578125,
  },
  {
    x: 2203.0900000001766,
    y: 0.072509765625,
  },
  {
    x: 2203.100000000177,
    y: 0.08734130859375,
  },
  {
    x: 2203.110000000177,
    y: 0.1055908203125,
  },
  {
    x: 2203.1200000001772,
    y: 0.1270751953125,
  },
  {
    x: 2203.1300000001775,
    y: 0.1507568359375,
  },
  {
    x: 2203.1400000001777,
    y: 0.1732177734375,
  },
  {
    x: 2203.150000000178,
    y: 0.1893310546875,
  },
  {
    x: 2203.160000000178,
    y: 0.1937255859375,
  },
  {
    x: 2203.1700000001783,
    y: 0.1849365234375,
  },
  {
    x: 2203.1800000001786,
    y: 0.1658935546875,
  },
  {
    x: 2203.1900000001788,
    y: 0.142578125,
  },
  {
    x: 2203.200000000179,
    y: 0.11944580078125,
  },
  {
    x: 2203.210000000179,
    y: 0.0989990234375,
  },
  {
    x: 2203.2200000001794,
    y: 0.08197021484375,
  },
  {
    x: 2203.2300000001796,
    y: 0.06817626953125,
  },
  {
    x: 2203.24000000018,
    y: 0.057098388671875,
  },
  {
    x: 2203.25000000018,
    y: 0.048248291015625,
  },
  {
    x: 2203.2600000001803,
    y: 0.041168212890625,
  },
  {
    x: 2203.2700000001805,
    y: 0.035400390625,
  },
  {
    x: 2203.2800000001807,
    y: 0.030731201171875,
  },
  {
    x: 2203.290000000181,
    y: 0.0268707275390625,
  },
  {
    x: 2203.300000000181,
    y: 0.0236663818359375,
  },
  {
    x: 2203.3100000001814,
    y: 0.02099609375,
  },
  {
    x: 2203.3200000001816,
    y: 0.0187225341796875,
  },
  {
    x: 2203.330000000182,
    y: 0.0167999267578125,
  },
  {
    x: 2203.340000000182,
    y: 0.01515960693359375,
  },
  {
    x: 2203.3500000001823,
    y: 0.01374053955078125,
  },
  {
    x: 2203.3600000001825,
    y: 0.01250457763671875,
  },
  {
    x: 2203.3700000001827,
    y: 0.0114288330078125,
  },
  {
    x: 2203.380000000183,
    y: 0.01049041748046875,
  },
  {
    x: 2203.390000000183,
    y: 0.0096588134765625,
  },
  {
    x: 2203.4000000001834,
    y: 0.00891876220703125,
  },
  {
    x: 2203.4100000001836,
    y: 0.00826263427734375,
  },
  {
    x: 2203.420000000184,
    y: 0.007678985595703125,
  },
  {
    x: 2203.430000000184,
    y: 0.007152557373046875,
  },
  {
    x: 2203.440000000184,
    y: 0.006679534912109375,
  },
  {
    x: 2203.4500000001844,
    y: 0.006252288818359375,
  },
  {
    x: 2203.4600000001847,
    y: 0.00586700439453125,
  },
  {
    x: 2203.470000000185,
    y: 0.00551605224609375,
  },
  {
    x: 2203.480000000185,
    y: 0.005191802978515625,
  },
  {
    x: 2203.4900000001853,
    y: 0.004901885986328125,
  },
  {
    x: 2203.5000000001855,
    y: 0.00463104248046875,
  },
  {
    x: 2203.5100000001858,
    y: 0.00438690185546875,
  },
  {
    x: 2203.520000000186,
    y: 0.00415802001953125,
  },
  {
    x: 2203.530000000186,
    y: 0.003948211669921875,
  },
  {
    x: 2203.5400000001864,
    y: 0.0037555694580078125,
  },
  {
    x: 2203.5500000001866,
    y: 0.0035762786865234375,
  },
  {
    x: 2203.560000000187,
    y: 0.00341033935546875,
  },
  {
    x: 2203.570000000187,
    y: 0.0032558441162109375,
  },
  {
    x: 2203.5800000001873,
    y: 0.00311279296875,
  },
  {
    x: 2203.5900000001875,
    y: 0.0029773712158203125,
  },
  {
    x: 2203.6000000001877,
    y: 0.0028533935546875,
  },
  {
    x: 2203.610000000188,
    y: 0.002735137939453125,
  },
  {
    x: 2203.620000000188,
    y: 0.0026264190673828125,
  },
  {
    x: 2203.6300000001884,
    y: 0.0025234222412109375,
  },
  {
    x: 2203.6400000001886,
    y: 0.0024261474609375,
  },
  {
    x: 2203.650000000189,
    y: 0.0023345947265625,
  },
  {
    x: 2203.660000000189,
    y: 0.0022487640380859375,
  },
  {
    x: 2203.6700000001892,
    y: 0.0021686553955078125,
  },
  {
    x: 2203.6800000001895,
    y: 0.0020923614501953125,
  },
  {
    x: 2203.6900000001897,
    y: 0.0020198822021484375,
  },
  {
    x: 2203.70000000019,
    y: 0.0019521713256835938,
  },
  {
    x: 2203.71000000019,
    y: 0.0018873214721679688,
  },
  {
    x: 2203.7200000001903,
    y: 0.0018262863159179688,
  },
  {
    x: 2203.7300000001906,
    y: 0.0017690658569335938,
  },
  {
    x: 2203.740000000191,
    y: 0.0017137527465820312,
  },
  {
    x: 2203.750000000191,
    y: 0.0016613006591796875,
  },
  {
    x: 2203.760000000191,
    y: 0.0016117095947265625,
  },
  {
    x: 2203.7700000001914,
    y: 0.0015649795532226562,
  },
  {
    x: 2203.7800000001916,
    y: 0.0015201568603515625,
  },
  {
    x: 2203.790000000192,
    y: 0.0014772415161132812,
  },
  {
    x: 2203.800000000192,
    y: 0.0014362335205078125,
  },
  {
    x: 2203.8100000001923,
    y: 0.0013971328735351562,
  },
  {
    x: 2203.8200000001925,
    y: 0.0013599395751953125,
  },
  {
    x: 2203.8300000001927,
    y: 0.0013246536254882812,
  },
  {
    x: 2203.840000000193,
    y: 0.0012903213500976562,
  },
  {
    x: 2203.850000000193,
    y: 0.0012578964233398438,
  },
  {
    x: 2203.8600000001934,
    y: 0.0012273788452148438,
  },
  {
    x: 2203.8700000001936,
    y: 0.0011968612670898438,
  },
  {
    x: 2203.880000000194,
    y: 0.0011682510375976562,
  },
  {
    x: 2203.890000000194,
    y: 0.0011415481567382812,
  },
  {
    x: 2203.9000000001943,
    y: 0.0011148452758789062,
  },
  {
    x: 2203.9100000001945,
    y: 0.0010900497436523438,
  },
  {
    x: 2203.9200000001947,
    y: 0.0010652542114257812,
  },
  {
    x: 2203.930000000195,
    y: 0.0010423660278320312,
  },
  {
    x: 2203.940000000195,
    y: 0.0010194778442382812,
  },
  {
    x: 2203.9500000001954,
    y: 0.0009984970092773438,
  },
  {
    x: 2203.9600000001956,
    y: 0.0009775161743164062,
  },
  {
    x: 2203.970000000196,
    y: 0.0009579658508300781,
  },
  {
    x: 2203.980000000196,
    y: 0.00093841552734375,
  },
  {
    x: 2203.9900000001962,
    y: 0.0009202957153320312,
  },
  {
    x: 2204.0000000001965,
    y: 0.0009021759033203125,
  },
  {
    x: 2204.0100000001967,
    y: 0.000885009765625,
  },
  {
    x: 2204.020000000197,
    y: 0.0008687973022460938,
  },
  {
    x: 2204.030000000197,
    y: 0.0008525848388671875,
  },
  {
    x: 2204.0400000001973,
    y: 0.0008373260498046875,
  },
  {
    x: 2204.0500000001975,
    y: 0.0008225440979003906,
  },
  {
    x: 2204.0600000001978,
    y: 0.0008082389831542969,
  },
  {
    x: 2204.070000000198,
    y: 0.0007944107055664062,
  },
  {
    x: 2204.080000000198,
    y: 0.0007810592651367188,
  },
  {
    x: 2204.0900000001984,
    y: 0.0007681846618652344,
  },
  {
    x: 2204.1000000001986,
    y: 0.0007557868957519531,
  },
  {
    x: 2204.110000000199,
    y: 0.000743865966796875,
  },
  {
    x: 2204.120000000199,
    y: 0.0007319450378417969,
  },
  {
    x: 2204.1300000001993,
    y: 0.000720977783203125,
  },
  {
    x: 2204.1400000001995,
    y: 0.0007100105285644531,
  },
  {
    x: 2204.1500000001997,
    y: 0.0006995201110839844,
  },
  {
    x: 2204.1600000002,
    y: 0.0006895065307617188,
  },
  {
    x: 2204.1700000002,
    y: 0.0006794929504394531,
  },
  {
    x: 2204.1800000002004,
    y: 0.0006699562072753906,
  },
  {
    x: 2204.1900000002006,
    y: 0.0006608963012695312,
  },
  {
    x: 2204.200000000201,
    y: 0.0006518363952636719,
  },
  {
    x: 2204.210000000201,
    y: 0.0006437301635742188,
  },
  {
    x: 2204.2200000002013,
    y: 0.0006351470947265625,
  },
  {
    x: 2204.2300000002015,
    y: 0.0006275177001953125,
  },
  {
    x: 2204.2400000002017,
    y: 0.0006194114685058594,
  },
  {
    x: 2204.250000000202,
    y: 0.0006122589111328125,
  },
  {
    x: 2204.260000000202,
    y: 0.0006051063537597656,
  },
  {
    x: 2204.2700000002023,
    y: 0.0005984306335449219,
  },
  {
    x: 2204.2800000002026,
    y: 0.0005922317504882812,
  },
  {
    x: 2204.290000000203,
    y: 0.0005855560302734375,
  },
  {
    x: 2204.300000000203,
    y: 0.0005788803100585938,
  },
  {
    x: 2204.310000000203,
    y: 0.0005726814270019531,
  },
  {
    x: 2204.3200000002034,
    y: 0.0005660057067871094,
  },
  {
    x: 2204.3300000002037,
    y: 0.0005598068237304688,
  },
  {
    x: 2204.340000000204,
    y: 0.0005536079406738281,
  },
  {
    x: 2204.350000000204,
    y: 0.0005478858947753906,
  },
  {
    x: 2204.3600000002043,
    y: 0.0005421638488769531,
  },
  {
    x: 2204.3700000002045,
    y: 0.0005369186401367188,
  },
  {
    x: 2204.3800000002047,
    y: 0.0005316734313964844,
  },
  {
    x: 2204.390000000205,
    y: 0.0005269050598144531,
  },
  {
    x: 2204.400000000205,
    y: 0.0005221366882324219,
  },
  {
    x: 2204.4100000002054,
    y: 0.0005173683166503906,
  },
  {
    x: 2204.4200000002056,
    y: 0.0005130767822265625,
  },
  {
    x: 2204.430000000206,
    y: 0.0005087852478027344,
  },
  {
    x: 2204.440000000206,
    y: 0.0005044937133789062,
  },
  {
    x: 2204.4500000002063,
    y: 0.0005006790161132812,
  },
  {
    x: 2204.4600000002065,
    y: 0.0004968643188476562,
  },
  {
    x: 2204.4700000002067,
    y: 0.0004930496215820312,
  },
  {
    x: 2204.480000000207,
    y: 0.0004892349243164062,
  },
  {
    x: 2204.490000000207,
    y: 0.0004858970642089844,
  },
  {
    x: 2204.5000000002074,
    y: 0.0004825592041015625,
  },
  {
    x: 2204.5100000002076,
    y: 0.0004794597625732422,
  },
  {
    x: 2204.520000000208,
    y: 0.0004763603210449219,
  },
  {
    x: 2204.530000000208,
    y: 0.0004734992980957031,
  },
  {
    x: 2204.5400000002082,
    y: 0.0004706382751464844,
  },
  {
    x: 2204.5500000002085,
    y: 0.0004677772521972656,
  },
  {
    x: 2204.5600000002087,
    y: 0.00046515464782714844,
  },
  {
    x: 2204.570000000209,
    y: 0.00046253204345703125,
  },
  {
    x: 2204.580000000209,
    y: 0.0004601478576660156,
  },
  {
    x: 2204.5900000002093,
    y: 0.00045800209045410156,
  },
  {
    x: 2204.6000000002095,
    y: 0.00045561790466308594,
  },
  {
    x: 2204.6100000002098,
    y: 0.00045371055603027344,
  },
  {
    x: 2204.62000000021,
    y: 0.0004515647888183594,
  },
  {
    x: 2204.63000000021,
    y: 0.0004496574401855469,
  },
  {
    x: 2204.6400000002104,
    y: 0.00044798851013183594,
  },
  {
    x: 2204.6500000002106,
    y: 0.00044608116149902344,
  },
  {
    x: 2204.660000000211,
    y: 0.00044465065002441406,
  },
  {
    x: 2204.670000000211,
    y: 0.0004429817199707031,
  },
  {
    x: 2204.6800000002113,
    y: 0.00044155120849609375,
  },
  {
    x: 2204.6900000002115,
    y: 0.00044035911560058594,
  },
  {
    x: 2204.7000000002117,
    y: 0.00043892860412597656,
  },
  {
    x: 2204.710000000212,
    y: 0.0004379749298095703,
  },
  {
    x: 2204.720000000212,
    y: 0.0004367828369140625,
  },
  {
    x: 2204.7300000002124,
    y: 0.00043582916259765625,
  },
  {
    x: 2204.7400000002126,
    y: 0.00043511390686035156,
  },
  {
    x: 2204.750000000213,
    y: 0.0004341602325439453,
  },
  {
    x: 2204.760000000213,
    y: 0.0004334449768066406,
  },
  {
    x: 2204.7700000002133,
    y: 0.0004329681396484375,
  },
  {
    x: 2204.7800000002135,
    y: 0.0004324913024902344,
  },
  {
    x: 2204.7900000002137,
    y: 0.00043201446533203125,
  },
  {
    x: 2204.800000000214,
    y: 0.0004315376281738281,
  },
  {
    x: 2204.810000000214,
    y: 0.00043129920959472656,
  },
  {
    x: 2204.8200000002143,
    y: 0.000431060791015625,
  },
  {
    x: 2204.8300000002146,
    y: 0.000431060791015625,
  },
  {
    x: 2204.840000000215,
    y: 0.000431060791015625,
  },
  {
    x: 2204.850000000215,
    y: 0.00043129920959472656,
  },
  {
    x: 2204.860000000215,
    y: 0.00043129920959472656,
  },
  {
    x: 2204.8700000002154,
    y: 0.0004317760467529297,
  },
  {
    x: 2204.8800000002157,
    y: 0.00043201446533203125,
  },
  {
    x: 2204.890000000216,
    y: 0.0004324913024902344,
  },
  {
    x: 2204.900000000216,
    y: 0.0004329681396484375,
  },
  {
    x: 2204.9100000002163,
    y: 0.0004336833953857422,
  },
  {
    x: 2204.9200000002165,
    y: 0.0004343986511230469,
  },
  {
    x: 2204.9300000002168,
    y: 0.00043511390686035156,
  },
  {
    x: 2204.940000000217,
    y: 0.0004360675811767578,
  },
  {
    x: 2204.950000000217,
    y: 0.0004372596740722656,
  },
  {
    x: 2204.9600000002174,
    y: 0.0004382133483886719,
  },
  {
    x: 2204.9700000002176,
    y: 0.0004394054412841797,
  },
  {
    x: 2204.980000000218,
    y: 0.00044083595275878906,
  },
  {
    x: 2204.990000000218,
    y: 0.00044226646423339844,
  },
  {
    x: 2205.0000000002183,
    y: 0.0004436969757080078,
  },
  {
    x: 2205.0100000002185,
    y: 0.00044536590576171875,
  },
  {
    x: 2205.0200000002187,
    y: 0.0004470348358154297,
  },
  {
    x: 2205.030000000219,
    y: 0.0004489421844482422,
  },
  {
    x: 2205.040000000219,
    y: 0.0004508495330810547,
  },
  {
    x: 2205.0500000002194,
    y: 0.0004527568817138672,
  },
  {
    x: 2205.0600000002196,
    y: 0.00045490264892578125,
  },
  {
    x: 2205.07000000022,
    y: 0.0004572868347167969,
  },
  {
    x: 2205.08000000022,
    y: 0.0004596710205078125,
  },
  {
    x: 2205.0900000002202,
    y: 0.0004620552062988281,
  },
  {
    x: 2205.1000000002205,
    y: 0.0004646778106689453,
  },
  {
    x: 2205.1100000002207,
    y: 0.00046753883361816406,
  },
  {
    x: 2205.120000000221,
    y: 0.0004703998565673828,
  },
  {
    x: 2205.130000000221,
    y: 0.00047326087951660156,
  },
  {
    x: 2205.1400000002213,
    y: 0.00047659873962402344,
  },
  {
    x: 2205.1500000002216,
    y: 0.00047969818115234375,
  },
  {
    x: 2205.1600000002218,
    y: 0.0004832744598388672,
  },
  {
    x: 2205.170000000222,
    y: 0.0004868507385253906,
  },
  {
    x: 2205.180000000222,
    y: 0.0004906654357910156,
  },
  {
    x: 2205.1900000002224,
    y: 0.0004944801330566406,
  },
  {
    x: 2205.2000000002226,
    y: 0.0004982948303222656,
  },
  {
    x: 2205.210000000223,
    y: 0.0005025863647460938,
  },
  {
    x: 2205.220000000223,
    y: 0.0005068778991699219,
  },
  {
    x: 2205.2300000002233,
    y: 0.00051116943359375,
  },
  {
    x: 2205.2400000002235,
    y: 0.0005159378051757812,
  },
  {
    x: 2205.2500000002237,
    y: 0.0005207061767578125,
  },
  {
    x: 2205.260000000224,
    y: 0.0005259513854980469,
  },
  {
    x: 2205.270000000224,
    y: 0.0005311965942382812,
  },
  {
    x: 2205.2800000002244,
    y: 0.0005364418029785156,
  },
  {
    x: 2205.2900000002246,
    y: 0.0005421638488769531,
  },
  {
    x: 2205.300000000225,
    y: 0.0005478858947753906,
  },
  {
    x: 2205.310000000225,
    y: 0.0005540847778320312,
  },
  {
    x: 2205.3200000002253,
    y: 0.0005602836608886719,
  },
  {
    x: 2205.3300000002255,
    y: 0.0005669593811035156,
  },
  {
    x: 2205.3400000002257,
    y: 0.0005736351013183594,
  },
  {
    x: 2205.350000000226,
    y: 0.0005807876586914062,
  },
  {
    x: 2205.360000000226,
    y: 0.0005879402160644531,
  },
  {
    x: 2205.3700000002264,
    y: 0.0005955696105957031,
  },
  {
    x: 2205.3800000002266,
    y: 0.0006031990051269531,
  },
  {
    x: 2205.390000000227,
    y: 0.0006113052368164062,
  },
  {
    x: 2205.400000000227,
    y: 0.0006198883056640625,
  },
  {
    x: 2205.4100000002272,
    y: 0.0006284713745117188,
  },
  {
    x: 2205.4200000002274,
    y: 0.0006375312805175781,
  },
  {
    x: 2205.4300000002277,
    y: 0.0006470680236816406,
  },
  {
    x: 2205.440000000228,
    y: 0.0006566047668457031,
  },
  {
    x: 2205.450000000228,
    y: 0.0006670951843261719,
  },
  {
    x: 2205.4600000002283,
    y: 0.0006775856018066406,
  },
  {
    x: 2205.4700000002285,
    y: 0.0006885528564453125,
  },
  {
    x: 2205.4800000002288,
    y: 0.0006995201110839844,
  },
  {
    x: 2205.490000000229,
    y: 0.0007114410400390625,
  },
  {
    x: 2205.500000000229,
    y: 0.0007238388061523438,
  },
  {
    x: 2205.5100000002294,
    y: 0.0007367134094238281,
  },
  {
    x: 2205.5200000002296,
    y: 0.0007495880126953125,
  },
  {
    x: 2205.53000000023,
    y: 0.0007638931274414062,
  },
  {
    x: 2205.54000000023,
    y: 0.0007781982421875,
  },
  {
    x: 2205.5500000002303,
    y: 0.0007929801940917969,
  },
  {
    x: 2205.5600000002305,
    y: 0.0008087158203125,
  },
  {
    x: 2205.5700000002307,
    y: 0.0008249282836914062,
  },
  {
    x: 2205.580000000231,
    y: 0.0008411407470703125,
  },
  {
    x: 2205.590000000231,
    y: 0.0008587837219238281,
  },
  {
    x: 2205.6000000002314,
    y: 0.0008769035339355469,
  },
  {
    x: 2205.6100000002316,
    y: 0.0008955001831054688,
  },
  {
    x: 2205.620000000232,
    y: 0.0009150505065917969,
  },
  {
    x: 2205.630000000232,
    y: 0.0009355545043945312,
  },
  {
    x: 2205.6400000002322,
    y: 0.0009570121765136719,
  },
  {
    x: 2205.6500000002325,
    y: 0.0009794235229492188,
  },
  {
    x: 2205.6600000002327,
    y: 0.001003265380859375,
  },
  {
    x: 2205.670000000233,
    y: 0.0010280609130859375,
  },
  {
    x: 2205.680000000233,
    y: 0.0010538101196289062,
  },
  {
    x: 2205.6900000002333,
    y: 0.0010805130004882812,
  },
  {
    x: 2205.7000000002336,
    y: 0.0011081695556640625,
  },
  {
    x: 2205.710000000234,
    y: 0.0011377334594726562,
  },
  {
    x: 2205.720000000234,
    y: 0.0011692047119140625,
  },
  {
    x: 2205.730000000234,
    y: 0.001201629638671875,
  },
  {
    x: 2205.7400000002344,
    y: 0.0012359619140625,
  },
  {
    x: 2205.7500000002346,
    y: 0.0012722015380859375,
  },
  {
    x: 2205.760000000235,
    y: 0.0013093948364257812,
  },
  {
    x: 2205.770000000235,
    y: 0.0013494491577148438,
  },
  {
    x: 2205.7800000002353,
    y: 0.0013914108276367188,
  },
  {
    x: 2205.7900000002355,
    y: 0.0014352798461914062,
  },
  {
    x: 2205.8000000002357,
    y: 0.0014820098876953125,
  },
  {
    x: 2205.810000000236,
    y: 0.0015316009521484375,
  },
  {
    x: 2205.820000000236,
    y: 0.001583099365234375,
  },
  {
    x: 2205.8300000002364,
    y: 0.0016384124755859375,
  },
  {
    x: 2205.8400000002366,
    y: 0.0016965866088867188,
  },
  {
    x: 2205.850000000237,
    y: 0.001758575439453125,
  },
  {
    x: 2205.860000000237,
    y: 0.0018243789672851562,
  },
  {
    x: 2205.8700000002373,
    y: 0.0018939971923828125,
  },
  {
    x: 2205.8800000002375,
    y: 0.0019683837890625,
  },
  {
    x: 2205.8900000002377,
    y: 0.0020465850830078125,
  },
  {
    x: 2205.900000000238,
    y: 0.002132415771484375,
  },
  {
    x: 2205.910000000238,
    y: 0.0022220611572265625,
  },
  {
    x: 2205.9200000002384,
    y: 0.0023193359375,
  },
  {
    x: 2205.9300000002386,
    y: 0.002422332763671875,
  },
  {
    x: 2205.940000000239,
    y: 0.002532958984375,
  },
  {
    x: 2205.950000000239,
    y: 0.002651214599609375,
  },
  {
    x: 2205.9600000002392,
    y: 0.0027790069580078125,
  },
  {
    x: 2205.9700000002395,
    y: 0.002918243408203125,
  },
  {
    x: 2205.9800000002397,
    y: 0.0030670166015625,
  },
  {
    x: 2205.99000000024,
    y: 0.00322723388671875,
  },
  {
    x: 2206.00000000024,
    y: 0.0034027099609375,
  },
  {
    x: 2206.0100000002403,
    y: 0.00359344482421875,
  },
  {
    x: 2206.0200000002405,
    y: 0.0038013458251953125,
  },
  {
    x: 2206.0300000002408,
    y: 0.0040283203125,
  },
  {
    x: 2206.040000000241,
    y: 0.004276275634765625,
  },
  {
    x: 2206.050000000241,
    y: 0.004547119140625,
  },
  {
    x: 2206.0600000002414,
    y: 0.00484466552734375,
  },
  {
    x: 2206.0700000002416,
    y: 0.005176544189453125,
  },
  {
    x: 2206.080000000242,
    y: 0.005542755126953125,
  },
  {
    x: 2206.090000000242,
    y: 0.005950927734375,
  },
  {
    x: 2206.1000000002423,
    y: 0.006404876708984375,
  },
  {
    x: 2206.1100000002425,
    y: 0.006916046142578125,
  },
  {
    x: 2206.1200000002427,
    y: 0.007488250732421875,
  },
  {
    x: 2206.130000000243,
    y: 0.0081329345703125,
  },
  {
    x: 2206.140000000243,
    y: 0.0088653564453125,
  },
  {
    x: 2206.1500000002434,
    y: 0.00970458984375,
  },
  {
    x: 2206.1600000002436,
    y: 0.0106658935546875,
  },
  {
    x: 2206.170000000244,
    y: 0.01177215576171875,
  },
  {
    x: 2206.180000000244,
    y: 0.0130615234375,
  },
  {
    x: 2206.1900000002443,
    y: 0.01456451416015625,
  },
  {
    x: 2206.2000000002445,
    y: 0.016326904296875,
  },
  {
    x: 2206.2100000002447,
    y: 0.0184326171875,
  },
  {
    x: 2206.220000000245,
    y: 0.0209503173828125,
  },
  {
    x: 2206.230000000245,
    y: 0.02398681640625,
  },
  {
    x: 2206.2400000002453,
    y: 0.027679443359375,
  },
  {
    x: 2206.2500000002456,
    y: 0.0322265625,
  },
  {
    x: 2206.260000000246,
    y: 0.037841796875,
  },
  {
    x: 2206.270000000246,
    y: 0.044891357421875,
  },
  {
    x: 2206.280000000246,
    y: 0.0537109375,
  },
  {
    x: 2206.2900000002464,
    y: 0.06475830078125,
  },
  {
    x: 2206.3000000002467,
    y: 0.078369140625,
  },
  {
    x: 2206.310000000247,
    y: 0.09478759765625,
  },
  {
    x: 2206.320000000247,
    y: 0.11328125,
  },
  {
    x: 2206.3300000002473,
    y: 0.1318359375,
  },
  {
    x: 2206.3400000002475,
    y: 0.1463623046875,
  },
  {
    x: 2206.3500000002477,
    y: 0.1524658203125,
  },
  {
    x: 2206.360000000248,
    y: 0.147705078125,
  },
  {
    x: 2206.370000000248,
    y: 0.134033203125,
  },
  {
    x: 2206.3800000002484,
    y: 0.11578369140625,
  },
  {
    x: 2206.3900000002486,
    y: 0.09710693359375,
  },
  {
    x: 2206.400000000249,
    y: 0.08038330078125,
  },
  {
    x: 2206.410000000249,
    y: 0.06634521484375,
  },
  {
    x: 2206.4200000002493,
    y: 0.05499267578125,
  },
  {
    x: 2206.4300000002495,
    y: 0.045928955078125,
  },
  {
    x: 2206.4400000002497,
    y: 0.038665771484375,
  },
  {
    x: 2206.45000000025,
    y: 0.03289794921875,
  },
  {
    x: 2206.46000000025,
    y: 0.0282135009765625,
  },
  {
    x: 2206.4700000002504,
    y: 0.0244140625,
  },
  {
    x: 2206.4800000002506,
    y: 0.02130126953125,
  },
  {
    x: 2206.490000000251,
    y: 0.01873779296875,
  },
  {
    x: 2206.500000000251,
    y: 0.0165863037109375,
  },
  {
    x: 2206.5100000002512,
    y: 0.0147705078125,
  },
  {
    x: 2206.5200000002515,
    y: 0.01323699951171875,
  },
  {
    x: 2206.5300000002517,
    y: 0.01192474365234375,
  },
  {
    x: 2206.540000000252,
    y: 0.01079559326171875,
  },
  {
    x: 2206.550000000252,
    y: 0.0098114013671875,
  },
  {
    x: 2206.5600000002523,
    y: 0.00896453857421875,
  },
  {
    x: 2206.5700000002525,
    y: 0.00821685791015625,
  },
  {
    x: 2206.5800000002528,
    y: 0.007556915283203125,
  },
  {
    x: 2206.590000000253,
    y: 0.006977081298828125,
  },
  {
    x: 2206.600000000253,
    y: 0.006458282470703125,
  },
  {
    x: 2206.6100000002534,
    y: 0.0059967041015625,
  },
  {
    x: 2206.6200000002536,
    y: 0.005584716796875,
  },
  {
    x: 2206.630000000254,
    y: 0.00521087646484375,
  },
  {
    x: 2206.640000000254,
    y: 0.00487518310546875,
  },
  {
    x: 2206.6500000002543,
    y: 0.004573822021484375,
  },
  {
    x: 2206.6600000002545,
    y: 0.00429534912109375,
  },
  {
    x: 2206.6700000002547,
    y: 0.004047393798828125,
  },
  {
    x: 2206.680000000255,
    y: 0.0038166046142578125,
  },
  {
    x: 2206.690000000255,
    y: 0.0036067962646484375,
  },
  {
    x: 2206.7000000002554,
    y: 0.003414154052734375,
  },
  {
    x: 2206.7100000002556,
    y: 0.0032367706298828125,
  },
  {
    x: 2206.720000000256,
    y: 0.0030727386474609375,
  },
  {
    x: 2206.730000000256,
    y: 0.00292205810546875,
  },
  {
    x: 2206.7400000002563,
    y: 0.0027828216552734375,
  },
  {
    x: 2206.7500000002565,
    y: 0.0026531219482421875,
  },
  {
    x: 2206.7600000002567,
    y: 0.0025310516357421875,
  },
  {
    x: 2206.770000000257,
    y: 0.0024204254150390625,
  },
  {
    x: 2206.780000000257,
    y: 0.002315521240234375,
  },
  {
    x: 2206.7900000002574,
    y: 0.0022182464599609375,
  },
  {
    x: 2206.8000000002576,
    y: 0.0021266937255859375,
  },
  {
    x: 2206.810000000258,
    y: 0.002040863037109375,
  },
  {
    x: 2206.820000000258,
    y: 0.00196075439453125,
  },
  {
    x: 2206.830000000258,
    y: 0.0018854141235351562,
  },
  {
    x: 2206.8400000002584,
    y: 0.0018148422241210938,
  },
  {
    x: 2206.8500000002587,
    y: 0.0017480850219726562,
  },
  {
    x: 2206.860000000259,
    y: 0.0016851425170898438,
  },
  {
    x: 2206.870000000259,
    y: 0.0016260147094726562,
  },
  {
    x: 2206.8800000002593,
    y: 0.0015707015991210938,
  },
  {
    x: 2206.8900000002595,
    y: 0.0015172958374023438,
  },
  {
    x: 2206.9000000002598,
    y: 0.0014677047729492188,
  },
  {
    x: 2206.91000000026,
    y: 0.0014200210571289062,
  },
  {
    x: 2206.92000000026,
    y: 0.0013751983642578125,
  },
  {
    x: 2206.9300000002604,
    y: 0.0013322830200195312,
  },
  {
    x: 2206.9400000002606,
    y: 0.0012922286987304688,
  },
  {
    x: 2206.950000000261,
    y: 0.0012540817260742188,
  },
  {
    x: 2206.960000000261,
    y: 0.001216888427734375,
  },
  {
    x: 2206.9700000002613,
    y: 0.00118255615234375,
  },
  {
    x: 2206.9800000002615,
    y: 0.0011491775512695312,
  },
  {
    x: 2206.9900000002617,
    y: 0.001117706298828125,
  },
  {
    x: 2207.000000000262,
    y: 0.0010881423950195312,
  },
  {
    x: 2207.010000000262,
    y: 0.0010595321655273438,
  },
  {
    x: 2207.0200000002624,
    y: 0.0010318756103515625,
  },
  {
    x: 2207.0300000002626,
    y: 0.0010061264038085938,
  },
  {
    x: 2207.040000000263,
    y: 0.0009813308715820312,
  },
  {
    x: 2207.050000000263,
    y: 0.0009570121765136719,
  },
  {
    x: 2207.0600000002632,
    y: 0.0009336471557617188,
  },
  {
    x: 2207.0700000002635,
    y: 0.0009112358093261719,
  },
  {
    x: 2207.0800000002637,
    y: 0.0008897781372070312,
  },
  {
    x: 2207.090000000264,
    y: 0.0008692741394042969,
  },
  {
    x: 2207.100000000264,
    y: 0.0008497238159179688,
  },
  {
    x: 2207.1100000002643,
    y: 0.0008306503295898438,
  },
  {
    x: 2207.1200000002646,
    y: 0.000812530517578125,
  },
  {
    x: 2207.1300000002648,
    y: 0.0007953643798828125,
  },
  {
    x: 2207.140000000265,
    y: 0.0007786750793457031,
  },
  {
    x: 2207.150000000265,
    y: 0.0007624626159667969,
  },
  {
    x: 2207.1600000002654,
    y: 0.0007472038269042969,
  },
  {
    x: 2207.1700000002656,
    y: 0.0007319450378417969,
  },
  {
    x: 2207.180000000266,
    y: 0.0007176399230957031,
  },
  {
    x: 2207.190000000266,
    y: 0.0007042884826660156,
  },
  {
    x: 2207.2000000002663,
    y: 0.0006909370422363281,
  },
  {
    x: 2207.2100000002665,
    y: 0.0006780624389648438,
  },
  {
    x: 2207.2200000002667,
    y: 0.0006656646728515625,
  },
  {
    x: 2207.230000000267,
    y: 0.0006537437438964844,
  },
  {
    x: 2207.240000000267,
    y: 0.0006422996520996094,
  },
  {
    x: 2207.2500000002674,
    y: 0.0006313323974609375,
  },
  {
    x: 2207.2600000002676,
    y: 0.0006203651428222656,
  },
  {
    x: 2207.270000000268,
    y: 0.0006103515625,
  },
  {
    x: 2207.280000000268,
    y: 0.0006003379821777344,
  },
  {
    x: 2207.2900000002683,
    y: 0.0005908012390136719,
  },
  {
    x: 2207.3000000002685,
    y: 0.0005812644958496094,
  },
  {
    x: 2207.3100000002687,
    y: 0.00057220458984375,
  },
  {
    x: 2207.320000000269,
    y: 0.0005636215209960938,
  },
  {
    x: 2207.330000000269,
    y: 0.0005550384521484375,
  },
  {
    x: 2207.3400000002694,
    y: 0.0005469322204589844,
  },
  {
    x: 2207.3500000002696,
    y: 0.0005393028259277344,
  },
  {
    x: 2207.36000000027,
    y: 0.0005316734313964844,
  },
  {
    x: 2207.37000000027,
    y: 0.0005240440368652344,
  },
  {
    x: 2207.3800000002702,
    y: 0.0005168914794921875,
  },
  {
    x: 2207.3900000002704,
    y: 0.0005102157592773438,
  },
  {
    x: 2207.4000000002707,
    y: 0.0005035400390625,
  },
  {
    x: 2207.410000000271,
    y: 0.0004968643188476562,
  },
  {
    x: 2207.420000000271,
    y: 0.0004906654357910156,
  },
  {
    x: 2207.4300000002713,
    y: 0.00048470497131347656,
  },
  {
    x: 2207.4400000002715,
    y: 0.0004787445068359375,
  },
  {
    x: 2207.4500000002718,
    y: 0.0004730224609375,
  },
  {
    x: 2207.460000000272,
    y: 0.00046753883361816406,
  },
  {
    x: 2207.470000000272,
    y: 0.0004622936248779297,
  },
  {
    x: 2207.4800000002724,
    y: 0.0004570484161376953,
  },
  {
    x: 2207.4900000002726,
    y: 0.0004520416259765625,
  },
  {
    x: 2207.500000000273,
    y: 0.00044727325439453125,
  },
  {
    x: 2207.510000000273,
    y: 0.0004425048828125,
  },
  {
    x: 2207.5200000002733,
    y: 0.0004379749298095703,
  },
  {
    x: 2207.5300000002735,
    y: 0.0004334449768066406,
  },
  {
    x: 2207.5400000002737,
    y: 0.00042939186096191406,
  },
  {
    x: 2207.550000000274,
    y: 0.00042510032653808594,
  },
  {
    x: 2207.560000000274,
    y: 0.0004210472106933594,
  },
  {
    x: 2207.5700000002744,
    y: 0.0004172325134277344,
  },
  {
    x: 2207.5800000002746,
    y: 0.00041365623474121094,
  },
  {
    x: 2207.590000000275,
    y: 0.00040984153747558594,
  },
  {
    x: 2207.600000000275,
    y: 0.00040650367736816406,
  },
  {
    x: 2207.6100000002752,
    y: 0.0004031658172607422,
  },
  {
    x: 2207.6200000002755,
    y: 0.0003998279571533203,
  },
  {
    x: 2207.6300000002757,
    y: 0.000396728515625,
  },
  {
    x: 2207.640000000276,
    y: 0.0003936290740966797,
  },
  {
    x: 2207.650000000276,
    y: 0.0003905296325683594,
  },
  {
    x: 2207.6600000002763,
    y: 0.0003879070281982422,
  },
  {
    x: 2207.6700000002766,
    y: 0.00038504600524902344,
  },
  {
    x: 2207.680000000277,
    y: 0.00038242340087890625,
  },
  {
    x: 2207.690000000277,
    y: 0.00037980079650878906,
  },
  {
    x: 2207.700000000277,
    y: 0.00037741661071777344,
  },
  {
    x: 2207.7100000002774,
    y: 0.0003750324249267578,
  },
  {
    x: 2207.7200000002777,
    y: 0.00037288665771484375,
  },
  {
    x: 2207.730000000278,
    y: 0.0003707408905029297,
  },
  {
    x: 2207.740000000278,
    y: 0.0003685951232910156,
  },
  {
    x: 2207.7500000002783,
    y: 0.00036644935607910156,
  },
  {
    x: 2207.7600000002785,
    y: 0.00036454200744628906,
  },
  {
    x: 2207.7700000002787,
    y: 0.0003628730773925781,
  },
  {
    x: 2207.780000000279,
    y: 0.0003609657287597656,
  },
  {
    x: 2207.790000000279,
    y: 0.0003592967987060547,
  },
  {
    x: 2207.8000000002794,
    y: 0.0003578662872314453,
  },
  {
    x: 2207.8100000002796,
    y: 0.0003561973571777344,
  },
  {
    x: 2207.82000000028,
    y: 0.000354766845703125,
  },
  {
    x: 2207.83000000028,
    y: 0.0003535747528076172,
  },
  {
    x: 2207.8400000002803,
    y: 0.0003521442413330078,
  },
  {
    x: 2207.8500000002805,
    y: 0.0003509521484375,
  },
  {
    x: 2207.8600000002807,
    y: 0.00034999847412109375,
  },
  {
    x: 2207.870000000281,
    y: 0.00034880638122558594,
  },
  {
    x: 2207.880000000281,
    y: 0.0003478527069091797,
  },
  {
    x: 2207.8900000002814,
    y: 0.000347137451171875,
  },
  {
    x: 2207.9000000002816,
    y: 0.00034618377685546875,
  },
  {
    x: 2207.910000000282,
    y: 0.00034546852111816406,
  },
  {
    x: 2207.920000000282,
    y: 0.00034499168395996094,
  },
  {
    x: 2207.9300000002822,
    y: 0.00034427642822265625,
  },
  {
    x: 2207.9400000002825,
    y: 0.0003437995910644531,
  },
  {
    x: 2207.9500000002827,
    y: 0.00034332275390625,
  },
  {
    x: 2207.960000000283,
    y: 0.0003428459167480469,
  },
  {
    x: 2207.970000000283,
    y: 0.00034236907958984375,
  },
  {
    x: 2207.9800000002833,
    y: 0.0003421306610107422,
  },
  {
    x: 2207.9900000002835,
    y: 0.0003418922424316406,
  },
  {
    x: 2208.0000000002838,
    y: 0.00034165382385253906,
  },
  {
    x: 2208.010000000284,
    y: 0.0003414154052734375,
  },
  {
    x: 2208.020000000284,
    y: 0.0003414154052734375,
  },
  {
    x: 2208.0300000002844,
    y: 0.0003414154052734375,
  },
  {
    x: 2208.0400000002846,
    y: 0.00034165382385253906,
  },
  {
    x: 2208.050000000285,
    y: 0.0003418922424316406,
  },
  {
    x: 2208.060000000285,
    y: 0.0003421306610107422,
  },
  {
    x: 2208.0700000002853,
    y: 0.00034236907958984375,
  },
  {
    x: 2208.0800000002855,
    y: 0.0003428459167480469,
  },
  {
    x: 2208.0900000002857,
    y: 0.00034332275390625,
  },
  {
    x: 2208.100000000286,
    y: 0.0003440380096435547,
  },
  {
    x: 2208.110000000286,
    y: 0.0003445148468017578,
  },
  {
    x: 2208.1200000002864,
    y: 0.0003452301025390625,
  },
  {
    x: 2208.1300000002866,
    y: 0.00034618377685546875,
  },
  {
    x: 2208.140000000287,
    y: 0.00034689903259277344,
  },
  {
    x: 2208.150000000287,
    y: 0.0003478527069091797,
  },
  {
    x: 2208.1600000002873,
    y: 0.0003490447998046875,
  },
  {
    x: 2208.1700000002875,
    y: 0.00034999847412109375,
  },
  {
    x: 2208.1800000002877,
    y: 0.00035119056701660156,
  },
  {
    x: 2208.190000000288,
    y: 0.00035262107849121094,
  },
  {
    x: 2208.200000000288,
    y: 0.00035381317138671875,
  },
  {
    x: 2208.2100000002883,
    y: 0.0003552436828613281,
  },
  {
    x: 2208.2200000002886,
    y: 0.00035691261291503906,
  },
  {
    x: 2208.230000000289,
    y: 0.00035858154296875,
  },
  {
    x: 2208.240000000289,
    y: 0.00036025047302246094,
  },
  {
    x: 2208.250000000289,
    y: 0.00036215782165527344,
  },
  {
    x: 2208.2600000002894,
    y: 0.0003638267517089844,
  },
  {
    x: 2208.2700000002897,
    y: 0.00036597251892089844,
  },
  {
    x: 2208.28000000029,
    y: 0.0003681182861328125,
  },
  {
    x: 2208.29000000029,
    y: 0.00037026405334472656,
  },
  {
    x: 2208.3000000002903,
    y: 0.0003724098205566406,
  },
  {
    x: 2208.3100000002905,
    y: 0.00037479400634765625,
  },
  {
    x: 2208.3200000002907,
    y: 0.00037741661071777344,
  },
  {
    x: 2208.330000000291,
    y: 0.0003800392150878906,
  },
  {
    x: 2208.340000000291,
    y: 0.0003826618194580078,
  },
  {
    x: 2208.3500000002914,
    y: 0.00038552284240722656,
  },
  {
    x: 2208.3600000002916,
    y: 0.0003886222839355469,
  },
  {
    x: 2208.370000000292,
    y: 0.0003917217254638672,
  },
  {
    x: 2208.380000000292,
    y: 0.0003948211669921875,
  },
  {
    x: 2208.3900000002923,
    y: 0.0003981590270996094,
  },
  {
    x: 2208.4000000002925,
    y: 0.0004017353057861328,
  },
  {
    x: 2208.4100000002927,
    y: 0.00040531158447265625,
  },
  {
    x: 2208.420000000293,
    y: 0.00040912628173828125,
  },
  {
    x: 2208.430000000293,
    y: 0.00041294097900390625,
  },
  {
    x: 2208.4400000002934,
    y: 0.0004169940948486328,
  },
  {
    x: 2208.4500000002936,
    y: 0.00042128562927246094,
  },
  {
    x: 2208.460000000294,
    y: 0.00042557716369628906,
  },
  {
    x: 2208.470000000294,
    y: 0.00043010711669921875,
  },
  {
    x: 2208.4800000002942,
    y: 0.00043487548828125,
  },
  {
    x: 2208.4900000002945,
    y: 0.0004398822784423828,
  },
  {
    x: 2208.5000000002947,
    y: 0.0004448890686035156,
  },
  {
    x: 2208.510000000295,
    y: 0.00045013427734375,
  },
  {
    x: 2208.520000000295,
    y: 0.00045561790466308594,
  },
  {
    x: 2208.5300000002953,
    y: 0.00046133995056152344,
  },
  {
    x: 2208.5400000002955,
    y: 0.0004673004150390625,
  },
  {
    x: 2208.5500000002958,
    y: 0.00047326087951660156,
  },
  {
    x: 2208.560000000296,
    y: 0.00047969818115234375,
  },
  {
    x: 2208.570000000296,
    y: 0.0004863739013671875,
  },
  {
    x: 2208.5800000002964,
    y: 0.0004930496215820312,
  },
  {
    x: 2208.5900000002966,
    y: 0.0005002021789550781,
  },
  {
    x: 2208.600000000297,
    y: 0.000507354736328125,
  },
  {
    x: 2208.610000000297,
    y: 0.0005154609680175781,
  },
  {
    x: 2208.6200000002973,
    y: 0.0005230903625488281,
  },
  {
    x: 2208.6300000002975,
    y: 0.0005316734313964844,
  },
  {
    x: 2208.6400000002977,
    y: 0.0005402565002441406,
  },
  {
    x: 2208.650000000298,
    y: 0.0005488395690917969,
  },
  {
    x: 2208.660000000298,
    y: 0.0005583763122558594,
  },
  {
    x: 2208.6700000002984,
    y: 0.0005679130554199219,
  },
  {
    x: 2208.6800000002986,
    y: 0.0005779266357421875,
  },
  {
    x: 2208.690000000299,
    y: 0.0005884170532226562,
  },
  {
    x: 2208.700000000299,
    y: 0.0005993843078613281,
  },
  {
    x: 2208.7100000002993,
    y: 0.0006108283996582031,
  },
  {
    x: 2208.7200000002995,
    y: 0.0006222724914550781,
  },
  {
    x: 2208.7300000002997,
    y: 0.0006346702575683594,
  },
  {
    x: 2208.7400000003,
    y: 0.0006470680236816406,
  },
  {
    x: 2208.7500000003,
    y: 0.0006604194641113281,
  },
  {
    x: 2208.7600000003004,
    y: 0.0006742477416992188,
  },
  {
    x: 2208.7700000003006,
    y: 0.0006885528564453125,
  },
  {
    x: 2208.780000000301,
    y: 0.0007038116455078125,
  },
  {
    x: 2208.790000000301,
    y: 0.0007195472717285156,
  },
  {
    x: 2208.800000000301,
    y: 0.0007357597351074219,
  },
  {
    x: 2208.8100000003014,
    y: 0.0007529258728027344,
  },
  {
    x: 2208.8200000003017,
    y: 0.0007710456848144531,
  },
  {
    x: 2208.830000000302,
    y: 0.000789642333984375,
  },
  {
    x: 2208.840000000302,
    y: 0.0008096694946289062,
  },
  {
    x: 2208.8500000003023,
    y: 0.0008301734924316406,
  },
  {
    x: 2208.8600000003025,
    y: 0.0008516311645507812,
  },
  {
    x: 2208.8700000003028,
    y: 0.0008745193481445312,
  },
  {
    x: 2208.880000000303,
    y: 0.0008978843688964844,
  },
  {
    x: 2208.890000000303,
    y: 0.00092315673828125,
  },
  {
    x: 2208.9000000003034,
    y: 0.0009493827819824219,
  },
  {
    x: 2208.9100000003036,
    y: 0.0009765625,
  },
  {
    x: 2208.920000000304,
    y: 0.0010061264038085938,
  },
  {
    x: 2208.930000000304,
    y: 0.0010366439819335938,
  },
  {
    x: 2208.9400000003043,
    y: 0.001068115234375,
  },
  {
    x: 2208.9500000003045,
    y: 0.001102447509765625,
  },
  {
    x: 2208.9600000003047,
    y: 0.0011386871337890625,
  },
  {
    x: 2208.970000000305,
    y: 0.0011758804321289062,
  },
  {
    x: 2208.980000000305,
    y: 0.0012159347534179688,
  },
  {
    x: 2208.9900000003054,
    y: 0.00125885009765625,
  },
  {
    x: 2209.0000000003056,
    y: 0.0013036727905273438,
  },
  {
    x: 2209.010000000306,
    y: 0.0013513565063476562,
  },
  {
    x: 2209.020000000306,
    y: 0.0014019012451171875,
  },
  {
    x: 2209.0300000003062,
    y: 0.0014562606811523438,
  },
  {
    x: 2209.0400000003065,
    y: 0.0015134811401367188,
  },
  {
    x: 2209.0500000003067,
    y: 0.0015745162963867188,
  },
  {
    x: 2209.060000000307,
    y: 0.0016393661499023438,
  },
  {
    x: 2209.070000000307,
    y: 0.0017099380493164062,
  },
  {
    x: 2209.0800000003073,
    y: 0.0017843246459960938,
  },
  {
    x: 2209.0900000003076,
    y: 0.0018644332885742188,
  },
  {
    x: 2209.100000000308,
    y: 0.0019502639770507812,
  },
  {
    x: 2209.110000000308,
    y: 0.0020427703857421875,
  },
  {
    x: 2209.120000000308,
    y: 0.0021419525146484375,
  },
  {
    x: 2209.1300000003084,
    y: 0.00225067138671875,
  },
  {
    x: 2209.1400000003086,
    y: 0.0023670196533203125,
  },
  {
    x: 2209.150000000309,
    y: 0.0024929046630859375,
  },
  {
    x: 2209.160000000309,
    y: 0.002628326416015625,
  },
  {
    x: 2209.1700000003093,
    y: 0.002777099609375,
  },
  {
    x: 2209.1800000003095,
    y: 0.002941131591796875,
  },
  {
    x: 2209.1900000003097,
    y: 0.0031185150146484375,
  },
  {
    x: 2209.20000000031,
    y: 0.0033130645751953125,
  },
  {
    x: 2209.21000000031,
    y: 0.0035266876220703125,
  },
  {
    x: 2209.2200000003104,
    y: 0.0037631988525390625,
  },
  {
    x: 2209.2300000003106,
    y: 0.004024505615234375,
  },
  {
    x: 2209.240000000311,
    y: 0.004314422607421875,
  },
  {
    x: 2209.250000000311,
    y: 0.004634857177734375,
  },
  {
    x: 2209.2600000003113,
    y: 0.00499725341796875,
  },
  {
    x: 2209.2700000003115,
    y: 0.005401611328125,
  },
  {
    x: 2209.2800000003117,
    y: 0.005859375,
  },
  {
    x: 2209.290000000312,
    y: 0.006374359130859375,
  },
  {
    x: 2209.300000000312,
    y: 0.006961822509765625,
  },
  {
    x: 2209.3100000003124,
    y: 0.00763702392578125,
  },
  {
    x: 2209.3200000003126,
    y: 0.0084075927734375,
  },
  {
    x: 2209.330000000313,
    y: 0.009307861328125,
  },
  {
    x: 2209.340000000313,
    y: 0.01035308837890625,
  },
  {
    x: 2209.3500000003132,
    y: 0.0115814208984375,
  },
  {
    x: 2209.3600000003134,
    y: 0.013031005859375,
  },
  {
    x: 2209.3700000003137,
    y: 0.01476287841796875,
  },
  {
    x: 2209.380000000314,
    y: 0.016845703125,
  },
  {
    x: 2209.390000000314,
    y: 0.0193634033203125,
  },
  {
    x: 2209.4000000003143,
    y: 0.0224609375,
  },
  {
    x: 2209.4100000003145,
    y: 0.026275634765625,
  },
  {
    x: 2209.4200000003148,
    y: 0.0310516357421875,
  },
  {
    x: 2209.430000000315,
    y: 0.037017822265625,
  },
  {
    x: 2209.440000000315,
    y: 0.0445556640625,
  },
  {
    x: 2209.4500000003154,
    y: 0.053985595703125,
  },
  {
    x: 2209.4600000003156,
    y: 0.0655517578125,
  },
  {
    x: 2209.470000000316,
    y: 0.07916259765625,
  },
  {
    x: 2209.480000000316,
    y: 0.0938720703125,
  },
  {
    x: 2209.4900000003163,
    y: 0.1072998046875,
  },
  {
    x: 2209.5000000003165,
    y: 0.11590576171875,
  },
  {
    x: 2209.5100000003167,
    y: 0.11651611328125,
  },
  {
    x: 2209.520000000317,
    y: 0.10888671875,
  },
  {
    x: 2209.530000000317,
    y: 0.095947265625,
  },
  {
    x: 2209.5400000003174,
    y: 0.08123779296875,
  },
  {
    x: 2209.5500000003176,
    y: 0.06732177734375,
  },
  {
    x: 2209.560000000318,
    y: 0.055450439453125,
  },
  {
    x: 2209.570000000318,
    y: 0.045745849609375,
  },
  {
    x: 2209.5800000003183,
    y: 0.037994384765625,
  },
  {
    x: 2209.5900000003185,
    y: 0.03179931640625,
  },
  {
    x: 2209.6000000003187,
    y: 0.026885986328125,
  },
  {
    x: 2209.610000000319,
    y: 0.02294921875,
  },
  {
    x: 2209.620000000319,
    y: 0.0197601318359375,
  },
  {
    x: 2209.6300000003193,
    y: 0.0171661376953125,
  },
  {
    x: 2209.6400000003196,
    y: 0.01502227783203125,
  },
  {
    x: 2209.65000000032,
    y: 0.01325225830078125,
  },
  {
    x: 2209.66000000032,
    y: 0.0117645263671875,
  },
  {
    x: 2209.67000000032,
    y: 0.01050567626953125,
  },
  {
    x: 2209.6800000003204,
    y: 0.00943756103515625,
  },
  {
    x: 2209.6900000003207,
    y: 0.00852203369140625,
  },
  {
    x: 2209.700000000321,
    y: 0.007732391357421875,
  },
  {
    x: 2209.710000000321,
    y: 0.007045745849609375,
  },
  {
    x: 2209.7200000003213,
    y: 0.00644683837890625,
  },
  {
    x: 2209.7300000003215,
    y: 0.00592041015625,
  },
  {
    x: 2209.7400000003217,
    y: 0.00545501708984375,
  },
  {
    x: 2209.750000000322,
    y: 0.00504302978515625,
  },
  {
    x: 2209.760000000322,
    y: 0.00467681884765625,
  },
  {
    x: 2209.7700000003224,
    y: 0.0043487548828125,
  },
  {
    x: 2209.7800000003226,
    y: 0.004055023193359375,
  },
  {
    x: 2209.790000000323,
    y: 0.0037899017333984375,
  },
  {
    x: 2209.800000000323,
    y: 0.0035495758056640625,
  },
  {
    x: 2209.8100000003233,
    y: 0.0033321380615234375,
  },
  {
    x: 2209.8200000003235,
    y: 0.0031337738037109375,
  },
  {
    x: 2209.8300000003237,
    y: 0.0029544830322265625,
  },
  {
    x: 2209.840000000324,
    y: 0.002788543701171875,
  },
  {
    x: 2209.850000000324,
    y: 0.0026378631591796875,
  },
  {
    x: 2209.8600000003244,
    y: 0.002498626708984375,
  },
  {
    x: 2209.8700000003246,
    y: 0.0023708343505859375,
  },
  {
    x: 2209.880000000325,
    y: 0.002254486083984375,
  },
  {
    x: 2209.890000000325,
    y: 0.00214385986328125,
  },
  {
    x: 2209.9000000003252,
    y: 0.0020427703857421875,
  },
  {
    x: 2209.9100000003255,
    y: 0.001949310302734375,
  },
  {
    x: 2209.9200000003257,
    y: 0.0018625259399414062,
  },
  {
    x: 2209.930000000326,
    y: 0.001781463623046875,
  },
  {
    x: 2209.940000000326,
    y: 0.001705169677734375,
  },
  {
    x: 2209.9500000003263,
    y: 0.0016345977783203125,
  },
  {
    x: 2209.9600000003265,
    y: 0.0015687942504882812,
  },
  {
    x: 2209.9700000003268,
    y: 0.0015058517456054688,
  },
  {
    x: 2209.980000000327,
    y: 0.0014476776123046875,
  },
  {
    x: 2209.990000000327,
    y: 0.0013933181762695312,
  },
  {
    x: 2210.0000000003274,
    y: 0.0013418197631835938,
  },
  {
    x: 2210.0100000003276,
    y: 0.001293182373046875,
  },
  {
    x: 2210.020000000328,
    y: 0.001247406005859375,
  },
  {
    x: 2210.030000000328,
    y: 0.0012044906616210938,
  },
  {
    x: 2210.0400000003283,
    y: 0.001163482666015625,
  },
  {
    x: 2210.0500000003285,
    y: 0.001125335693359375,
  },
  {
    x: 2210.0600000003287,
    y: 0.0010890960693359375,
  },
  {
    x: 2210.070000000329,
    y: 0.0010538101196289062,
  },
  {
    x: 2210.080000000329,
    y: 0.0010213851928710938,
  },
  {
    x: 2210.0900000003294,
    y: 0.0009899139404296875,
  },
  {
    x: 2210.1000000003296,
    y: 0.0009603500366210938,
  },
  {
    x: 2210.11000000033,
    y: 0.0009322166442871094,
  },
  {
    x: 2210.12000000033,
    y: 0.0009055137634277344,
  },
  {
    x: 2210.1300000003303,
    y: 0.0008802413940429688,
  },
  {
    x: 2210.1400000003305,
    y: 0.0008559226989746094,
  },
  {
    x: 2210.1500000003307,
    y: 0.0008325576782226562,
  },
  {
    x: 2210.160000000331,
    y: 0.0008106231689453125,
  },
  {
    x: 2210.170000000331,
    y: 0.0007891654968261719,
  },
  {
    x: 2210.1800000003313,
    y: 0.0007691383361816406,
  },
  {
    x: 2210.1900000003316,
    y: 0.0007500648498535156,
  },
  {
    x: 2210.200000000332,
    y: 0.0007314682006835938,
  },
  {
    x: 2210.210000000332,
    y: 0.0007138252258300781,
  },
  {
    x: 2210.220000000332,
    y: 0.0006966590881347656,
  },
  {
    x: 2210.2300000003324,
    y: 0.0006804466247558594,
  },
  {
    x: 2210.2400000003327,
    y: 0.0006651878356933594,
  },
  {
    x: 2210.250000000333,
    y: 0.0006499290466308594,
  },
  {
    x: 2210.260000000333,
    y: 0.0006356239318847656,
  },
  {
    x: 2210.2700000003333,
    y: 0.0006222724914550781,
  },
  {
    x: 2210.2800000003335,
    y: 0.0006089210510253906,
  },
  {
    x: 2210.2900000003337,
    y: 0.0005960464477539062,
  },
  {
    x: 2210.300000000334,
    y: 0.0005841255187988281,
  },
  {
    x: 2210.310000000334,
    y: 0.00057220458984375,
  },
  {
    x: 2210.3200000003344,
    y: 0.000560760498046875,
  },
  {
    x: 2210.3300000003346,
    y: 0.0005497932434082031,
  },
  {
    x: 2210.340000000335,
    y: 0.0005393028259277344,
  },
  {
    x: 2210.350000000335,
    y: 0.0005292892456054688,
  },
  {
    x: 2210.3600000003353,
    y: 0.0005192756652832031,
  },
  {
    x: 2210.3700000003355,
    y: 0.0005097389221191406,
  },
  {
    x: 2210.3800000003357,
    y: 0.0005006790161132812,
  },
  {
    x: 2210.390000000336,
    y: 0.000492095947265625,
  },
  {
    x: 2210.400000000336,
    y: 0.00048351287841796875,
  },
  {
    x: 2210.4100000003364,
    y: 0.0004754066467285156,
  },
  {
    x: 2210.4200000003366,
    y: 0.00046753883361816406,
  },
  {
    x: 2210.430000000337,
    y: 0.00045990943908691406,
  },
  {
    x: 2210.440000000337,
    y: 0.0004525184631347656,
  },
  {
    x: 2210.4500000003372,
    y: 0.0004456043243408203,
  },
  {
    x: 2210.4600000003375,
    y: 0.000438690185546875,
  },
  {
    x: 2210.4700000003377,
    y: 0.00043201446533203125,
  },
  {
    x: 2210.480000000338,
    y: 0.00042557716369628906,
  },
  {
    x: 2210.490000000338,
    y: 0.00041937828063964844,
  },
  {
    x: 2210.5000000003383,
    y: 0.0004134178161621094,
  },
  {
    x: 2210.5100000003386,
    y: 0.0004076957702636719,
  },
  {
    x: 2210.5200000003388,
    y: 0.0004019737243652344,
  },
  {
    x: 2210.530000000339,
    y: 0.00039649009704589844,
  },
  {
    x: 2210.540000000339,
    y: 0.00039124488830566406,
  },
  {
    x: 2210.5500000003394,
    y: 0.00038623809814453125,
  },
  {
    x: 2210.5600000003396,
    y: 0.00038123130798339844,
  },
  {
    x: 2210.57000000034,
    y: 0.0003764629364013672,
  },
  {
    x: 2210.58000000034,
    y: 0.0003719329833984375,
  },
  {
    x: 2210.5900000003403,
    y: 0.0003674030303955078,
  },
  {
    x: 2210.6000000003405,
    y: 0.0003631114959716797,
  },
  {
    x: 2210.6100000003407,
    y: 0.00035881996154785156,
  },
  {
    x: 2210.620000000341,
    y: 0.000354766845703125,
  },
  {
    x: 2210.630000000341,
    y: 0.0003509521484375,
  },
  {
    x: 2210.6400000003414,
    y: 0.000347137451171875,
  },
  {
    x: 2210.6500000003416,
    y: 0.00034332275390625,
  },
  {
    x: 2210.660000000342,
    y: 0.00033974647521972656,
  },
  {
    x: 2210.670000000342,
    y: 0.0003364086151123047,
  },
  {
    x: 2210.6800000003423,
    y: 0.0003330707550048828,
  },
  {
    x: 2210.6900000003425,
    y: 0.00032973289489746094,
  },
  {
    x: 2210.7000000003427,
    y: 0.0003266334533691406,
  },
  {
    x: 2210.710000000343,
    y: 0.0003235340118408203,
  },
  {
    x: 2210.720000000343,
    y: 0.00032067298889160156,
  },
  {
    x: 2210.7300000003434,
    y: 0.0003178119659423828,
  },
  {
    x: 2210.7400000003436,
    y: 0.00031495094299316406,
  },
  {
    x: 2210.750000000344,
    y: 0.0003123283386230469,
  },
  {
    x: 2210.760000000344,
    y: 0.00030994415283203125,
  },
  {
    x: 2210.770000000344,
    y: 0.00030732154846191406,
  },
  {
    x: 2210.7800000003444,
    y: 0.00030493736267089844,
  },
  {
    x: 2210.7900000003447,
    y: 0.0003025531768798828,
  },
  {
    x: 2210.800000000345,
    y: 0.00030040740966796875,
  },
  {
    x: 2210.810000000345,
    y: 0.0002982616424560547,
  },
  {
    x: 2210.8200000003453,
    y: 0.0002961158752441406,
  },
  {
    x: 2210.8300000003455,
    y: 0.0002942085266113281,
  },
  {
    x: 2210.8400000003458,
    y: 0.0002923011779785156,
  },
  {
    x: 2210.850000000346,
    y: 0.0002903938293457031,
  },
  {
    x: 2210.860000000346,
    y: 0.0002887248992919922,
  },
  {
    x: 2210.8700000003464,
    y: 0.0002868175506591797,
  },
  {
    x: 2210.8800000003466,
    y: 0.0002853870391845703,
  },
  {
    x: 2210.890000000347,
    y: 0.0002837181091308594,
  },
  {
    x: 2210.900000000347,
    y: 0.00028228759765625,
  },
  {
    x: 2210.9100000003473,
    y: 0.0002808570861816406,
  },
  {
    x: 2210.9200000003475,
    y: 0.00027942657470703125,
  },
  {
    x: 2210.9300000003477,
    y: 0.0002779960632324219,
  },
  {
    x: 2210.940000000348,
    y: 0.00027680397033691406,
  },
  {
    x: 2210.950000000348,
    y: 0.00027561187744140625,
  },
  {
    x: 2210.9600000003484,
    y: 0.00027441978454589844,
  },
  {
    x: 2210.9700000003486,
    y: 0.0002734661102294922,
  },
  {
    x: 2210.980000000349,
    y: 0.0002722740173339844,
  },
  {
    x: 2210.990000000349,
    y: 0.0002713203430175781,
  },
  {
    x: 2211.0000000003492,
    y: 0.00027060508728027344,
  },
  {
    x: 2211.0100000003495,
    y: 0.0002696514129638672,
  },
  {
    x: 2211.0200000003497,
    y: 0.0002689361572265625,
  },
  {
    x: 2211.03000000035,
    y: 0.0002682209014892578,
  },
  {
    x: 2211.04000000035,
    y: 0.0002675056457519531,
  },
  {
    x: 2211.0500000003503,
    y: 0.00026679039001464844,
  },
  {
    x: 2211.0600000003506,
    y: 0.0002663135528564453,
  },
  {
    x: 2211.070000000351,
    y: 0.0002658367156982422,
  },
  {
    x: 2211.080000000351,
    y: 0.00026535987854003906,
  },
  {
    x: 2211.090000000351,
    y: 0.00026488304138183594,
  },
  {
    x: 2211.1000000003514,
    y: 0.0002646446228027344,
  },
  {
    x: 2211.1100000003516,
    y: 0.0002644062042236328,
  },
  {
    x: 2211.120000000352,
    y: 0.00026416778564453125,
  },
  {
    x: 2211.130000000352,
    y: 0.0002639293670654297,
  },
  {
    x: 2211.1400000003523,
    y: 0.0002636909484863281,
  },
  {
    x: 2211.1500000003525,
    y: 0.0002636909484863281,
  },
  {
    x: 2211.1600000003527,
    y: 0.0002636909484863281,
  },
  {
    x: 2211.170000000353,
    y: 0.0002636909484863281,
  },
  {
    x: 2211.180000000353,
    y: 0.0002639293670654297,
  },
  {
    x: 2211.1900000003534,
    y: 0.0002639293670654297,
  },
  {
    x: 2211.2000000003536,
    y: 0.00026416778564453125,
  },
  {
    x: 2211.210000000354,
    y: 0.0002644062042236328,
  },
  {
    x: 2211.220000000354,
    y: 0.00026488304138183594,
  },
  {
    x: 2211.2300000003543,
    y: 0.0002651214599609375,
  },
  {
    x: 2211.2400000003545,
    y: 0.0002655982971191406,
  },
  {
    x: 2211.2500000003547,
    y: 0.00026607513427734375,
  },
  {
    x: 2211.260000000355,
    y: 0.0002665519714355469,
  },
  {
    x: 2211.270000000355,
    y: 0.00026726722717285156,
  },
  {
    x: 2211.2800000003554,
    y: 0.00026798248291015625,
  },
  {
    x: 2211.2900000003556,
    y: 0.00026869773864746094,
  },
  {
    x: 2211.300000000356,
    y: 0.0002694129943847656,
  },
  {
    x: 2211.310000000356,
    y: 0.0002703666687011719,
  },
  {
    x: 2211.3200000003562,
    y: 0.0002713203430175781,
  },
  {
    x: 2211.3300000003564,
    y: 0.0002722740173339844,
  },
  {
    x: 2211.3400000003567,
    y: 0.0002732276916503906,
  },
  {
    x: 2211.350000000357,
    y: 0.00027441978454589844,
  },
  {
    x: 2211.360000000357,
    y: 0.00027561187744140625,
  },
  {
    x: 2211.3700000003573,
    y: 0.00027680397033691406,
  },
  {
    x: 2211.3800000003575,
    y: 0.00027823448181152344,
  },
  {
    x: 2211.3900000003578,
    y: 0.0002796649932861328,
  },
  {
    x: 2211.400000000358,
    y: 0.0002810955047607422,
  },
  {
    x: 2211.410000000358,
    y: 0.00028252601623535156,
  },
  {
    x: 2211.4200000003584,
    y: 0.0002841949462890625,
  },
  {
    x: 2211.4300000003586,
    y: 0.000286102294921875,
  },
  {
    x: 2211.440000000359,
    y: 0.00028777122497558594,
  },
  {
    x: 2211.450000000359,
    y: 0.00028967857360839844,
  },
  {
    x: 2211.4600000003593,
    y: 0.00029158592224121094,
  },
  {
    x: 2211.4700000003595,
    y: 0.000293731689453125,
  },
  {
    x: 2211.4800000003597,
    y: 0.00029587745666503906,
  },
  {
    x: 2211.49000000036,
    y: 0.0002980232238769531,
  },
  {
    x: 2211.50000000036,
    y: 0.0003001689910888672,
  },
  {
    x: 2211.5100000003604,
    y: 0.0003025531768798828,
  },
  {
    x: 2211.5200000003606,
    y: 0.00030517578125,
  },
  {
    x: 2211.530000000361,
    y: 0.0003077983856201172,
  },
  {
    x: 2211.540000000361,
    y: 0.0003104209899902344,
  },
  {
    x: 2211.5500000003613,
    y: 0.0003132820129394531,
  },
  {
    x: 2211.5600000003615,
    y: 0.0003161430358886719,
  },
  {
    x: 2211.5700000003617,
    y: 0.0003192424774169922,
  },
  {
    x: 2211.580000000362,
    y: 0.00032258033752441406,
  },
  {
    x: 2211.590000000362,
    y: 0.0003256797790527344,
  },
  {
    x: 2211.6000000003623,
    y: 0.0003292560577392578,
  },
  {
    x: 2211.6100000003626,
    y: 0.00033283233642578125,
  },
  {
    x: 2211.620000000363,
    y: 0.0003364086151123047,
  },
  {
    x: 2211.630000000363,
    y: 0.00034046173095703125,
  },
  {
    x: 2211.640000000363,
    y: 0.00034427642822265625,
  },
  {
    x: 2211.6500000003634,
    y: 0.0003485679626464844,
  },
  {
    x: 2211.6600000003637,
    y: 0.0003528594970703125,
  },
  {
    x: 2211.670000000364,
    y: 0.0003573894500732422,
  },
  {
    x: 2211.680000000364,
    y: 0.0003619194030761719,
  },
  {
    x: 2211.6900000003643,
    y: 0.0003666877746582031,
  },
  {
    x: 2211.7000000003645,
    y: 0.00037169456481933594,
  },
  {
    x: 2211.7100000003647,
    y: 0.0003769397735595703,
  },
  {
    x: 2211.720000000365,
    y: 0.00038242340087890625,
  },
  {
    x: 2211.730000000365,
    y: 0.00038814544677734375,
  },
  {
    x: 2211.7400000003654,
    y: 0.00039386749267578125,
  },
  {
    x: 2211.7500000003656,
    y: 0.0004000663757324219,
  },
  {
    x: 2211.760000000366,
    y: 0.0004062652587890625,
  },
  {
    x: 2211.770000000366,
    y: 0.00041294097900390625,
  },
  {
    x: 2211.7800000003663,
    y: 0.00041961669921875,
  },
  {
    x: 2211.7900000003665,
    y: 0.0004267692565917969,
  },
  {
    x: 2211.8000000003667,
    y: 0.0004341602325439453,
  },
  {
    x: 2211.810000000367,
    y: 0.0004417896270751953,
  },
  {
    x: 2211.820000000367,
    y: 0.00044989585876464844,
  },
  {
    x: 2211.8300000003674,
    y: 0.0004582405090332031,
  },
  {
    x: 2211.8400000003676,
    y: 0.0004668235778808594,
  },
  {
    x: 2211.850000000368,
    y: 0.00047588348388671875,
  },
  {
    x: 2211.860000000368,
    y: 0.00048542022705078125,
  },
  {
    x: 2211.8700000003682,
    y: 0.0004949569702148438,
  },
  {
    x: 2211.8800000003685,
    y: 0.0005054473876953125,
  },
  {
    x: 2211.8900000003687,
    y: 0.0005159378051757812,
  },
  {
    x: 2211.900000000369,
    y: 0.0005273818969726562,
  },
  {
    x: 2211.910000000369,
    y: 0.0005388259887695312,
  },
  {
    x: 2211.9200000003693,
    y: 0.0005512237548828125,
  },
  {
    x: 2211.9300000003695,
    y: 0.0005636215209960938,
  },
  {
    x: 2211.9400000003698,
    y: 0.0005769729614257812,
  },
  {
    x: 2211.95000000037,
    y: 0.000591278076171875,
  },
  {
    x: 2211.96000000037,
    y: 0.0006055831909179688,
  },
  {
    x: 2211.9700000003704,
    y: 0.0006208419799804688,
  },
  {
    x: 2211.9800000003706,
    y: 0.000637054443359375,
  },
  {
    x: 2211.990000000371,
    y: 0.0006537437438964844,
  },
  {
    x: 2212.000000000371,
    y: 0.00067138671875,
  },
  {
    x: 2212.0100000003713,
    y: 0.0006899833679199219,
  },
  {
    x: 2212.0200000003715,
    y: 0.00070953369140625,
  },
  {
    x: 2212.0300000003717,
    y: 0.0007300376892089844,
  },
  {
    x: 2212.040000000372,
    y: 0.000751495361328125,
  },
  {
    x: 2212.050000000372,
    y: 0.000774383544921875,
  },
  {
    x: 2212.0600000003724,
    y: 0.0007982254028320312,
  },
  {
    x: 2212.0700000003726,
    y: 0.0008234977722167969,
  },
  {
    x: 2212.080000000373,
    y: 0.000850677490234375,
  },
  {
    x: 2212.090000000373,
    y: 0.0008788108825683594,
  },
  {
    x: 2212.1000000003733,
    y: 0.0009083747863769531,
  },
  {
    x: 2212.1100000003735,
    y: 0.0009403228759765625,
  },
  {
    x: 2212.1200000003737,
    y: 0.0009737014770507812,
  },
  {
    x: 2212.130000000374,
    y: 0.0010099411010742188,
  },
  {
    x: 2212.140000000374,
    y: 0.0010471343994140625,
  },
  {
    x: 2212.1500000003743,
    y: 0.001087188720703125,
  },
  {
    x: 2212.1600000003746,
    y: 0.0011301040649414062,
  },
  {
    x: 2212.170000000375,
    y: 0.0011758804321289062,
  },
  {
    x: 2212.180000000375,
    y: 0.0012254714965820312,
  },
  {
    x: 2212.190000000375,
    y: 0.0012769699096679688,
  },
  {
    x: 2212.2000000003754,
    y: 0.0013332366943359375,
  },
  {
    x: 2212.2100000003757,
    y: 0.0013933181762695312,
  },
  {
    x: 2212.220000000376,
    y: 0.0014581680297851562,
  },
  {
    x: 2212.230000000376,
    y: 0.0015277862548828125,
  },
  {
    x: 2212.2400000003763,
    y: 0.0016021728515625,
  },
  {
    x: 2212.2500000003765,
    y: 0.0016832351684570312,
  },
  {
    x: 2212.2600000003767,
    y: 0.0017709732055664062,
  },
  {
    x: 2212.270000000377,
    y: 0.001865386962890625,
  },
  {
    x: 2212.280000000377,
    y: 0.0019683837890625,
  },
  {
    x: 2212.2900000003774,
    y: 0.0020809173583984375,
  },
  {
    x: 2212.3000000003776,
    y: 0.0022029876708984375,
  },
  {
    x: 2212.310000000378,
    y: 0.0023365020751953125,
  },
  {
    x: 2212.320000000378,
    y: 0.0024852752685546875,
  },
  {
    x: 2212.3300000003783,
    y: 0.00264739990234375,
  },
  {
    x: 2212.3400000003785,
    y: 0.0028247833251953125,
  },
  {
    x: 2212.3500000003787,
    y: 0.0030231475830078125,
  },
  {
    x: 2212.360000000379,
    y: 0.00324249267578125,
  },
  {
    x: 2212.370000000379,
    y: 0.0034885406494140625,
  },
  {
    x: 2212.3800000003794,
    y: 0.0037631988525390625,
  },
  {
    x: 2212.3900000003796,
    y: 0.004070281982421875,
  },
  {
    x: 2212.40000000038,
    y: 0.004421234130859375,
  },
  {
    x: 2212.41000000038,
    y: 0.004817962646484375,
  },
  {
    x: 2212.4200000003802,
    y: 0.005268096923828125,
  },
  {
    x: 2212.4300000003805,
    y: 0.0057830810546875,
  },
  {
    x: 2212.4400000003807,
    y: 0.006381988525390625,
  },
  {
    x: 2212.450000000381,
    y: 0.00707244873046875,
  },
  {
    x: 2212.460000000381,
    y: 0.00788116455078125,
  },
  {
    x: 2212.4700000003813,
    y: 0.0088348388671875,
  },
  {
    x: 2212.4800000003816,
    y: 0.0099639892578125,
  },
  {
    x: 2212.4900000003818,
    y: 0.01131439208984375,
  },
  {
    x: 2212.500000000382,
    y: 0.012939453125,
  },
  {
    x: 2212.510000000382,
    y: 0.01493072509765625,
  },
  {
    x: 2212.5200000003824,
    y: 0.0173797607421875,
  },
  {
    x: 2212.5300000003826,
    y: 0.020416259765625,
  },
  {
    x: 2212.540000000383,
    y: 0.0242156982421875,
  },
  {
    x: 2212.550000000383,
    y: 0.02899169921875,
  },
  {
    x: 2212.5600000003833,
    y: 0.0350341796875,
  },
  {
    x: 2212.5700000003835,
    y: 0.0426025390625,
  },
  {
    x: 2212.5800000003837,
    y: 0.051849365234375,
  },
  {
    x: 2212.590000000384,
    y: 0.06256103515625,
  },
  {
    x: 2212.600000000384,
    y: 0.07379150390625,
  },
  {
    x: 2212.6100000003844,
    y: 0.08331298828125,
  },
  {
    x: 2212.6200000003846,
    y: 0.08819580078125,
  },
  {
    x: 2212.630000000385,
    y: 0.08660888671875,
  },
  {
    x: 2212.640000000385,
    y: 0.07916259765625,
  },
  {
    x: 2212.6500000003853,
    y: 0.0684814453125,
  },
  {
    x: 2212.6600000003855,
    y: 0.05731201171875,
  },
  {
    x: 2212.6700000003857,
    y: 0.047210693359375,
  },
  {
    x: 2212.680000000386,
    y: 0.038787841796875,
  },
  {
    x: 2212.690000000386,
    y: 0.031982421875,
  },
  {
    x: 2212.7000000003864,
    y: 0.0265655517578125,
  },
  {
    x: 2212.7100000003866,
    y: 0.02227783203125,
  },
  {
    x: 2212.720000000387,
    y: 0.0188751220703125,
  },
  {
    x: 2212.730000000387,
    y: 0.016143798828125,
  },
  {
    x: 2212.740000000387,
    y: 0.01392364501953125,
  },
  {
    x: 2212.7500000003874,
    y: 0.01212310791015625,
  },
  {
    x: 2212.7600000003877,
    y: 0.0106353759765625,
  },
  {
    x: 2212.770000000388,
    y: 0.00939178466796875,
  },
  {
    x: 2212.780000000388,
    y: 0.00835418701171875,
  },
  {
    x: 2212.7900000003883,
    y: 0.007472991943359375,
  },
  {
    x: 2212.8000000003885,
    y: 0.00672149658203125,
  },
  {
    x: 2212.8100000003888,
    y: 0.00608062744140625,
  },
  {
    x: 2212.820000000389,
    y: 0.005523681640625,
  },
  {
    x: 2212.830000000389,
    y: 0.005039215087890625,
  },
  {
    x: 2212.8400000003894,
    y: 0.00461578369140625,
  },
  {
    x: 2212.8500000003896,
    y: 0.004241943359375,
  },
  {
    x: 2212.86000000039,
    y: 0.00391387939453125,
  },
  {
    x: 2212.87000000039,
    y: 0.003620147705078125,
  },
  {
    x: 2212.8800000003903,
    y: 0.003360748291015625,
  },
  {
    x: 2212.8900000003905,
    y: 0.0031280517578125,
  },
  {
    x: 2212.9000000003907,
    y: 0.002918243408203125,
  },
  {
    x: 2212.910000000391,
    y: 0.0027294158935546875,
  },
  {
    x: 2212.920000000391,
    y: 0.0025577545166015625,
  },
  {
    x: 2212.9300000003914,
    y: 0.00240325927734375,
  },
  {
    x: 2212.9400000003916,
    y: 0.002262115478515625,
  },
  {
    x: 2212.950000000392,
    y: 0.0021343231201171875,
  },
  {
    x: 2212.960000000392,
    y: 0.0020160675048828125,
  },
  {
    x: 2212.9700000003922,
    y: 0.0019083023071289062,
  },
  {
    x: 2212.9800000003925,
    y: 0.0018091201782226562,
  },
  {
    x: 2212.9900000003927,
    y: 0.0017175674438476562,
  },
  {
    x: 2213.000000000393,
    y: 0.0016326904296875,
  },
  {
    x: 2213.010000000393,
    y: 0.0015544891357421875,
  },
  {
    x: 2213.0200000003933,
    y: 0.0014820098876953125,
  },
  {
    x: 2213.0300000003936,
    y: 0.001415252685546875,
  },
  {
    x: 2213.040000000394,
    y: 0.0013523101806640625,
  },
  {
    x: 2213.050000000394,
    y: 0.0012941360473632812,
  },
  {
    x: 2213.060000000394,
    y: 0.001239776611328125,
  },
  {
    x: 2213.0700000003944,
    y: 0.0011882781982421875,
  },
  {
    x: 2213.0800000003946,
    y: 0.001140594482421875,
  },
  {
    x: 2213.090000000395,
    y: 0.0010957717895507812,
  },
  {
    x: 2213.100000000395,
    y: 0.0010547637939453125,
  },
  {
    x: 2213.1100000003953,
    y: 0.00101470947265625,
  },
  {
    x: 2213.1200000003955,
    y: 0.0009775161743164062,
  },
  {
    x: 2213.1300000003957,
    y: 0.0009427070617675781,
  },
  {
    x: 2213.140000000396,
    y: 0.0009098052978515625,
  },
  {
    x: 2213.150000000396,
    y: 0.0008788108825683594,
  },
  {
    x: 2213.1600000003964,
    y: 0.0008492469787597656,
  },
  {
    x: 2213.1700000003966,
    y: 0.0008215904235839844,
  },
  {
    x: 2213.180000000397,
    y: 0.0007953643798828125,
  },
  {
    x: 2213.190000000397,
    y: 0.0007700920104980469,
  },
  {
    x: 2213.2000000003973,
    y: 0.0007467269897460938,
  },
  {
    x: 2213.2100000003975,
    y: 0.0007238388061523438,
  },
  {
    x: 2213.2200000003977,
    y: 0.0007028579711914062,
  },
  {
    x: 2213.230000000398,
    y: 0.0006823539733886719,
  },
  {
    x: 2213.240000000398,
    y: 0.0006628036499023438,
  },
  {
    x: 2213.2500000003984,
    y: 0.000644683837890625,
  },
  {
    x: 2213.2600000003986,
    y: 0.0006270408630371094,
  },
  {
    x: 2213.270000000399,
    y: 0.0006103515625,
  },
  {
    x: 2213.280000000399,
    y: 0.0005941390991210938,
  },
  {
    x: 2213.2900000003992,
    y: 0.0005788803100585938,
  },
  {
    x: 2213.3000000003995,
    y: 0.0005640983581542969,
  },
  {
    x: 2213.3100000003997,
    y: 0.0005502700805664062,
  },
  {
    x: 2213.3200000004,
    y: 0.0005369186401367188,
  },
  {
    x: 2213.3300000004,
    y: 0.0005240440368652344,
  },
  {
    x: 2213.3400000004003,
    y: 0.0005116462707519531,
  },
  {
    x: 2213.3500000004005,
    y: 0.000499725341796875,
  },
  {
    x: 2213.3600000004008,
    y: 0.0004887580871582031,
  },
  {
    x: 2213.370000000401,
    y: 0.00047779083251953125,
  },
  {
    x: 2213.380000000401,
    y: 0.0004673004150390625,
  },
  {
    x: 2213.3900000004014,
    y: 0.00045752525329589844,
  },
  {
    x: 2213.4000000004016,
    y: 0.0004477500915527344,
  },
  {
    x: 2213.410000000402,
    y: 0.000438690185546875,
  },
  {
    x: 2213.420000000402,
    y: 0.0004296302795410156,
  },
  {
    x: 2213.4300000004023,
    y: 0.00042128562927246094,
  },
  {
    x: 2213.4400000004025,
    y: 0.00041294097900390625,
  },
  {
    x: 2213.4500000004027,
    y: 0.0004050731658935547,
  },
  {
    x: 2213.460000000403,
    y: 0.0003974437713623047,
  },
  {
    x: 2213.470000000403,
    y: 0.00039005279541015625,
  },
  {
    x: 2213.4800000004034,
    y: 0.0003829002380371094,
  },
  {
    x: 2213.4900000004036,
    y: 0.0003762245178222656,
  },
  {
    x: 2213.500000000404,
    y: 0.0003695487976074219,
  },
  {
    x: 2213.510000000404,
    y: 0.0003631114959716797,
  },
  {
    x: 2213.5200000004043,
    y: 0.0003571510314941406,
  },
  {
    x: 2213.5300000004045,
    y: 0.00035119056701660156,
  },
  {
    x: 2213.5400000004047,
    y: 0.00034546852111816406,
  },
  {
    x: 2213.550000000405,
    y: 0.0003399848937988281,
  },
  {
    x: 2213.560000000405,
    y: 0.0003345012664794922,
  },
  {
    x: 2213.5700000004053,
    y: 0.0003294944763183594,
  },
  {
    x: 2213.5800000004056,
    y: 0.00032448768615722656,
  },
  {
    x: 2213.590000000406,
    y: 0.0003197193145751953,
  },
  {
    x: 2213.600000000406,
    y: 0.00031495094299316406,
  },
  {
    x: 2213.610000000406,
    y: 0.0003104209899902344,
  },
  {
    x: 2213.6200000004064,
    y: 0.00030612945556640625,
  },
  {
    x: 2213.6300000004067,
    y: 0.0003018379211425781,
  },
  {
    x: 2213.640000000407,
    y: 0.00029778480529785156,
  },
  {
    x: 2213.650000000407,
    y: 0.00029397010803222656,
  },
  {
    x: 2213.6600000004073,
    y: 0.0002899169921875,
  },
  {
    x: 2213.6700000004075,
    y: 0.00028634071350097656,
  },
  {
    x: 2213.6800000004077,
    y: 0.0002827644348144531,
  },
  {
    x: 2213.690000000408,
    y: 0.0002791881561279297,
  },
  {
    x: 2213.700000000408,
    y: 0.0002758502960205078,
  },
  {
    x: 2213.7100000004084,
    y: 0.0002727508544921875,
  },
  {
    x: 2213.7200000004086,
    y: 0.0002696514129638672,
  },
  {
    x: 2213.730000000409,
    y: 0.0002665519714355469,
  },
  {
    x: 2213.740000000409,
    y: 0.00026345252990722656,
  },
  {
    x: 2213.7500000004093,
    y: 0.0002605915069580078,
  },
  {
    x: 2213.7600000004095,
    y: 0.0002579689025878906,
  },
  {
    x: 2213.7700000004097,
    y: 0.00025534629821777344,
  },
  {
    x: 2213.78000000041,
    y: 0.00025272369384765625,
  },
  {
    x: 2213.79000000041,
    y: 0.00025010108947753906,
  },
  {
    x: 2213.8000000004104,
    y: 0.00024771690368652344,
  },
  {
    x: 2213.8100000004106,
    y: 0.0002453327178955078,
  },
  {
    x: 2213.820000000411,
    y: 0.00024318695068359375,
  },
  {
    x: 2213.830000000411,
    y: 0.0002409219741821289,
  },
  {
    x: 2213.8400000004112,
    y: 0.00023877620697021484,
  },
  {
    x: 2213.8500000004115,
    y: 0.00023674964904785156,
  },
  {
    x: 2213.8600000004117,
    y: 0.00023472309112548828,
  },
  {
    x: 2213.870000000412,
    y: 0.00023281574249267578,
  },
  {
    x: 2213.880000000412,
    y: 0.00023102760314941406,
  },
  {
    x: 2213.8900000004123,
    y: 0.00022923946380615234,
  },
  {
    x: 2213.9000000004125,
    y: 0.00022745132446289062,
  },
  {
    x: 2213.9100000004128,
    y: 0.0002257823944091797,
  },
  {
    x: 2213.920000000413,
    y: 0.00022423267364501953,
  },
  {
    x: 2213.930000000413,
    y: 0.00022268295288085938,
  },
  {
    x: 2213.9400000004134,
    y: 0.00022113323211669922,
  },
  {
    x: 2213.9500000004136,
    y: 0.00021970272064208984,
  },
  {
    x: 2213.960000000414,
    y: 0.00021839141845703125,
  },
  {
    x: 2213.970000000414,
    y: 0.00021708011627197266,
  },
  {
    x: 2213.9800000004143,
    y: 0.00021576881408691406,
  },
  {
    x: 2213.9900000004145,
    y: 0.00021457672119140625,
  },
  {
    x: 2214.0000000004147,
    y: 0.00021338462829589844,
  },
  {
    x: 2214.010000000415,
    y: 0.00021219253540039062,
  },
  {
    x: 2214.020000000415,
    y: 0.0002111196517944336,
  },
  {
    x: 2214.0300000004154,
    y: 0.00021016597747802734,
  },
  {
    x: 2214.0400000004156,
    y: 0.0002090930938720703,
  },
  {
    x: 2214.050000000416,
    y: 0.00020825862884521484,
  },
  {
    x: 2214.060000000416,
    y: 0.0002073049545288086,
  },
  {
    x: 2214.0700000004163,
    y: 0.00020647048950195312,
  },
  {
    x: 2214.0800000004165,
    y: 0.00020575523376464844,
  },
  {
    x: 2214.0900000004167,
    y: 0.00020492076873779297,
  },
  {
    x: 2214.100000000417,
    y: 0.00020432472229003906,
  },
  {
    x: 2214.110000000417,
    y: 0.00020360946655273438,
  },
  {
    x: 2214.1200000004173,
    y: 0.00020301342010498047,
  },
  {
    x: 2214.1300000004176,
    y: 0.00020241737365722656,
  },
  {
    x: 2214.140000000418,
    y: 0.00020194053649902344,
  },
  {
    x: 2214.150000000418,
    y: 0.0002014636993408203,
  },
  {
    x: 2214.160000000418,
    y: 0.0002009868621826172,
  },
  {
    x: 2214.1700000004184,
    y: 0.00020062923431396484,
  },
  {
    x: 2214.1800000004187,
    y: 0.00020015239715576172,
  },
  {
    x: 2214.190000000419,
    y: 0.00019991397857666016,
  },
  {
    x: 2214.200000000419,
    y: 0.0001995563507080078,
  },
  {
    x: 2214.2100000004193,
    y: 0.00019931793212890625,
  },
  {
    x: 2214.2200000004195,
    y: 0.0001990795135498047,
  },
  {
    x: 2214.2300000004197,
    y: 0.0001989603042602539,
  },
  {
    x: 2214.24000000042,
    y: 0.00019884109497070312,
  },
  {
    x: 2214.25000000042,
    y: 0.00019872188568115234,
  },
  {
    x: 2214.2600000004204,
    y: 0.00019872188568115234,
  },
  {
    x: 2214.2700000004206,
    y: 0.00019872188568115234,
  },
  {
    x: 2214.280000000421,
    y: 0.00019872188568115234,
  },
  {
    x: 2214.290000000421,
    y: 0.00019884109497070312,
  },
  {
    x: 2214.3000000004213,
    y: 0.0001989603042602539,
  },
  {
    x: 2214.3100000004215,
    y: 0.00019919872283935547,
  },
  {
    x: 2214.3200000004217,
    y: 0.00019943714141845703,
  },
  {
    x: 2214.330000000422,
    y: 0.0001996755599975586,
  },
  {
    x: 2214.340000000422,
    y: 0.00020003318786621094,
  },
  {
    x: 2214.3500000004224,
    y: 0.00020039081573486328,
  },
  {
    x: 2214.3600000004226,
    y: 0.00020074844360351562,
  },
  {
    x: 2214.370000000423,
    y: 0.00020122528076171875,
  },
  {
    x: 2214.380000000423,
    y: 0.00020170211791992188,
  },
  {
    x: 2214.3900000004232,
    y: 0.000202178955078125,
  },
  {
    x: 2214.4000000004235,
    y: 0.0002027750015258789,
  },
  {
    x: 2214.4100000004237,
    y: 0.0002033710479736328,
  },
  {
    x: 2214.420000000424,
    y: 0.0002040863037109375,
  },
  {
    x: 2214.430000000424,
    y: 0.0002048015594482422,
  },
  {
    x: 2214.4400000004243,
    y: 0.00020563602447509766,
  },
  {
    x: 2214.4500000004246,
    y: 0.00020647048950195312,
  },
  {
    x: 2214.4600000004248,
    y: 0.0002073049545288086,
  },
  {
    x: 2214.470000000425,
    y: 0.00020825862884521484,
  },
  {
    x: 2214.480000000425,
    y: 0.00020933151245117188,
  },
  {
    x: 2214.4900000004254,
    y: 0.00021028518676757812,
  },
  {
    x: 2214.5000000004256,
    y: 0.00021147727966308594,
  },
  {
    x: 2214.510000000426,
    y: 0.00021255016326904297,
  },
  {
    x: 2214.520000000426,
    y: 0.00021386146545410156,
  },
  {
    x: 2214.5300000004263,
    y: 0.00021505355834960938,
  },
  {
    x: 2214.5400000004265,
    y: 0.00021648406982421875,
  },
  {
    x: 2214.5500000004267,
    y: 0.00021791458129882812,
  },
  {
    x: 2214.560000000427,
    y: 0.0002193450927734375,
  },
  {
    x: 2214.570000000427,
    y: 0.00022089481353759766,
  },
  {
    x: 2214.5800000004274,
    y: 0.0002224445343017578,
  },
  {
    x: 2214.5900000004276,
    y: 0.00022411346435546875,
  },
  {
    x: 2214.600000000428,
    y: 0.00022590160369873047,
  },
  {
    x: 2214.610000000428,
    y: 0.00022780895233154297,
  },
  {
    x: 2214.6200000004283,
    y: 0.00022971630096435547,
  },
  {
    x: 2214.6300000004285,
    y: 0.00023162364959716797,
  },
  {
    x: 2214.6400000004287,
    y: 0.00023376941680908203,
  },
  {
    x: 2214.650000000429,
    y: 0.0002359151840209961,
  },
  {
    x: 2214.660000000429,
    y: 0.00023818016052246094,
  },
  {
    x: 2214.6700000004294,
    y: 0.00024044513702392578,
  },
  {
    x: 2214.6800000004296,
    y: 0.0002429485321044922,
  },
  {
    x: 2214.69000000043,
    y: 0.0002453327178955078,
  },
  {
    x: 2214.70000000043,
    y: 0.000247955322265625,
  },
  {
    x: 2214.7100000004302,
    y: 0.00025081634521484375,
  },
  {
    x: 2214.7200000004304,
    y: 0.0002536773681640625,
  },
  {
    x: 2214.7300000004307,
    y: 0.00025653839111328125,
  },
  {
    x: 2214.740000000431,
    y: 0.00025963783264160156,
  },
  {
    x: 2214.750000000431,
    y: 0.0002627372741699219,
  },
  {
    x: 2214.7600000004313,
    y: 0.00026607513427734375,
  },
  {
    x: 2214.7700000004315,
    y: 0.0002696514129638672,
  },
  {
    x: 2214.7800000004318,
    y: 0.0002732276916503906,
  },
  {
    x: 2214.790000000432,
    y: 0.00027680397033691406,
  },
  {
    x: 2214.800000000432,
    y: 0.0002808570861816406,
  },
  {
    x: 2214.8100000004324,
    y: 0.0002846717834472656,
  },
  {
    x: 2214.8200000004326,
    y: 0.00028896331787109375,
  },
  {
    x: 2214.830000000433,
    y: 0.0002932548522949219,
  },
  {
    x: 2214.840000000433,
    y: 0.00029778480529785156,
  },
  {
    x: 2214.8500000004333,
    y: 0.0003025531768798828,
  },
  {
    x: 2214.8600000004335,
    y: 0.00030732154846191406,
  },
  {
    x: 2214.8700000004337,
    y: 0.00031256675720214844,
  },
  {
    x: 2214.880000000434,
    y: 0.0003178119659423828,
  },
  {
    x: 2214.890000000434,
    y: 0.00032329559326171875,
  },
  {
    x: 2214.9000000004344,
    y: 0.00032901763916015625,
  },
  {
    x: 2214.9100000004346,
    y: 0.0003349781036376953,
  },
  {
    x: 2214.920000000435,
    y: 0.00034117698669433594,
  },
  {
    x: 2214.930000000435,
    y: 0.0003476142883300781,
  },
  {
    x: 2214.9400000004352,
    y: 0.00035452842712402344,
  },
  {
    x: 2214.9500000004355,
    y: 0.00036144256591796875,
  },
  {
    x: 2214.9600000004357,
    y: 0.0003688335418701172,
  },
  {
    x: 2214.970000000436,
    y: 0.0003764629364013672,
  },
  {
    x: 2214.980000000436,
    y: 0.0003845691680908203,
  },
  {
    x: 2214.9900000004363,
    y: 0.000392913818359375,
  },
  {
    x: 2215.0000000004366,
    y: 0.0004017353057861328,
  },
  {
    x: 2215.010000000437,
    y: 0.00041103363037109375,
  },
  {
    x: 2215.020000000437,
    y: 0.00042057037353515625,
  },
  {
    x: 2215.030000000437,
    y: 0.0004305839538574219,
  },
  {
    x: 2215.0400000004374,
    y: 0.0004413127899169922,
  },
  {
    x: 2215.0500000004376,
    y: 0.00045228004455566406,
  },
  {
    x: 2215.060000000438,
    y: 0.0004639625549316406,
  },
  {
    x: 2215.070000000438,
    y: 0.0004761219024658203,
  },
  {
    x: 2215.0800000004383,
    y: 0.0004887580871582031,
  },
  {
    x: 2215.0900000004385,
    y: 0.0005021095275878906,
  },
  {
    x: 2215.1000000004387,
    y: 0.0005164146423339844,
  },
  {
    x: 2215.110000000439,
    y: 0.0005307197570800781,
  },
  {
    x: 2215.120000000439,
    y: 0.0005464553833007812,
  },
  {
    x: 2215.1300000004394,
    y: 0.0005626678466796875,
  },
  {
    x: 2215.1400000004396,
    y: 0.0005803108215332031,
  },
  {
    x: 2215.15000000044,
    y: 0.0005984306335449219,
  },
  {
    x: 2215.16000000044,
    y: 0.0006175041198730469,
  },
  {
    x: 2215.1700000004403,
    y: 0.0006380081176757812,
  },
  {
    x: 2215.1800000004405,
    y: 0.0006594657897949219,
  },
  {
    x: 2215.1900000004407,
    y: 0.0006823539733886719,
  },
  {
    x: 2215.200000000441,
    y: 0.0007066726684570312,
  },
  {
    x: 2215.210000000441,
    y: 0.000732421875,
  },
  {
    x: 2215.2200000004414,
    y: 0.0007600784301757812,
  },
  {
    x: 2215.2300000004416,
    y: 0.0007891654968261719,
  },
  {
    x: 2215.240000000442,
    y: 0.000820159912109375,
  },
  {
    x: 2215.250000000442,
    y: 0.0008530616760253906,
  },
  {
    x: 2215.2600000004422,
    y: 0.0008883476257324219,
  },
  {
    x: 2215.2700000004425,
    y: 0.0009264945983886719,
  },
  {
    x: 2215.2800000004427,
    y: 0.0009670257568359375,
  },
  {
    x: 2215.290000000443,
    y: 0.0010099411010742188,
  },
  {
    x: 2215.300000000443,
    y: 0.0010576248168945312,
  },
  {
    x: 2215.3100000004433,
    y: 0.0011072158813476562,
  },
  {
    x: 2215.3200000004435,
    y: 0.0011615753173828125,
  },
  {
    x: 2215.3300000004438,
    y: 0.001220703125,
  },
  {
    x: 2215.340000000444,
    y: 0.0012836456298828125,
  },
  {
    x: 2215.350000000444,
    y: 0.0013523101806640625,
  },
  {
    x: 2215.3600000004444,
    y: 0.0014276504516601562,
  },
  {
    x: 2215.3700000004446,
    y: 0.0015087127685546875,
  },
  {
    x: 2215.380000000445,
    y: 0.001598358154296875,
  },
  {
    x: 2215.390000000445,
    y: 0.0016956329345703125,
  },
  {
    x: 2215.4000000004453,
    y: 0.0018024444580078125,
  },
  {
    x: 2215.4100000004455,
    y: 0.0019207000732421875,
  },
  {
    x: 2215.4200000004457,
    y: 0.0020503997802734375,
  },
  {
    x: 2215.430000000446,
    y: 0.0021953582763671875,
  },
  {
    x: 2215.440000000446,
    y: 0.0023555755615234375,
  },
  {
    x: 2215.4500000004464,
    y: 0.0025348663330078125,
  },
  {
    x: 2215.4600000004466,
    y: 0.002735137939453125,
  },
  {
    x: 2215.470000000447,
    y: 0.002960205078125,
  },
  {
    x: 2215.480000000447,
    y: 0.0032138824462890625,
  },
  {
    x: 2215.4900000004473,
    y: 0.0035037994384765625,
  },
  {
    x: 2215.5000000004475,
    y: 0.003833770751953125,
  },
  {
    x: 2215.5100000004477,
    y: 0.00421142578125,
  },
  {
    x: 2215.520000000448,
    y: 0.004650115966796875,
  },
  {
    x: 2215.530000000448,
    y: 0.005157470703125,
  },
  {
    x: 2215.5400000004483,
    y: 0.0057525634765625,
  },
  {
    x: 2215.5500000004486,
    y: 0.0064544677734375,
  },
  {
    x: 2215.560000000449,
    y: 0.007289886474609375,
  },
  {
    x: 2215.570000000449,
    y: 0.0082855224609375,
  },
  {
    x: 2215.580000000449,
    y: 0.00949859619140625,
  },
  {
    x: 2215.5900000004494,
    y: 0.0109710693359375,
  },
  {
    x: 2215.6000000004497,
    y: 0.01279449462890625,
  },
  {
    x: 2215.61000000045,
    y: 0.0150604248046875,
  },
  {
    x: 2215.62000000045,
    y: 0.0178985595703125,
  },
  {
    x: 2215.6300000004503,
    y: 0.0214996337890625,
  },
  {
    x: 2215.6400000004505,
    y: 0.0260467529296875,
  },
  {
    x: 2215.6500000004507,
    y: 0.031768798828125,
  },
  {
    x: 2215.660000000451,
    y: 0.03875732421875,
  },
  {
    x: 2215.670000000451,
    y: 0.046844482421875,
  },
  {
    x: 2215.6800000004514,
    y: 0.055206298828125,
  },
  {
    x: 2215.6900000004516,
    y: 0.06207275390625,
  },
  {
    x: 2215.700000000452,
    y: 0.06524658203125,
  },
  {
    x: 2215.710000000452,
    y: 0.06341552734375,
  },
  {
    x: 2215.7200000004523,
    y: 0.057342529296875,
  },
  {
    x: 2215.7300000004525,
    y: 0.0491943359375,
  },
  {
    x: 2215.7400000004527,
    y: 0.040924072265625,
  },
  {
    x: 2215.750000000453,
    y: 0.0335693359375,
  },
  {
    x: 2215.760000000453,
    y: 0.0275115966796875,
  },
  {
    x: 2215.7700000004534,
    y: 0.0226593017578125,
  },
  {
    x: 2215.7800000004536,
    y: 0.0188140869140625,
  },
  {
    x: 2215.790000000454,
    y: 0.015777587890625,
  },
  {
    x: 2215.800000000454,
    y: 0.01336669921875,
  },
  {
    x: 2215.8100000004542,
    y: 0.01143646240234375,
  },
  {
    x: 2215.8200000004545,
    y: 0.0098724365234375,
  },
  {
    x: 2215.8300000004547,
    y: 0.00859832763671875,
  },
  {
    x: 2215.840000000455,
    y: 0.007541656494140625,
  },
  {
    x: 2215.850000000455,
    y: 0.0066680908203125,
  },
  {
    x: 2215.8600000004553,
    y: 0.005931854248046875,
  },
  {
    x: 2215.8700000004555,
    y: 0.00531005859375,
  },
  {
    x: 2215.8800000004558,
    y: 0.004779815673828125,
  },
  {
    x: 2215.890000000456,
    y: 0.004322052001953125,
  },
  {
    x: 2215.900000000456,
    y: 0.00392913818359375,
  },
  {
    x: 2215.9100000004564,
    y: 0.0035858154296875,
  },
  {
    x: 2215.9200000004566,
    y: 0.0032863616943359375,
  },
  {
    x: 2215.930000000457,
    y: 0.003021240234375,
  },
  {
    x: 2215.940000000457,
    y: 0.002788543701171875,
  },
  {
    x: 2215.9500000004573,
    y: 0.0025806427001953125,
  },
  {
    x: 2215.9600000004575,
    y: 0.0023956298828125,
  },
  {
    x: 2215.9700000004577,
    y: 0.002231597900390625,
  },
  {
    x: 2215.980000000458,
    y: 0.00208282470703125,
  },
  {
    x: 2215.990000000458,
    y: 0.0019483566284179688,
  },
  {
    x: 2216.0000000004584,
    y: 0.001827239990234375,
  },
  {
    x: 2216.0100000004586,
    y: 0.00171661376953125,
  },
  {
    x: 2216.020000000459,
    y: 0.0016164779663085938,
  },
  {
    x: 2216.030000000459,
    y: 0.0015249252319335938,
  },
  {
    x: 2216.0400000004593,
    y: 0.0014410018920898438,
  },
  {
    x: 2216.0500000004595,
    y: 0.0013637542724609375,
  },
  {
    x: 2216.0600000004597,
    y: 0.001293182373046875,
  },
  {
    x: 2216.07000000046,
    y: 0.00122833251953125,
  },
  {
    x: 2216.08000000046,
    y: 0.0011682510375976562,
  },
  {
    x: 2216.0900000004603,
    y: 0.0011129379272460938,
  },
  {
    x: 2216.1000000004606,
    y: 0.00106048583984375,
  },
  {
    x: 2216.110000000461,
    y: 0.0010128021240234375,
  },
  {
    x: 2216.120000000461,
    y: 0.0009684562683105469,
  },
  {
    x: 2216.130000000461,
    y: 0.0009264945983886719,
  },
  {
    x: 2216.1400000004614,
    y: 0.0008878707885742188,
  },
  {
    x: 2216.1500000004617,
    y: 0.0008516311645507812,
  },
  {
    x: 2216.160000000462,
    y: 0.0008172988891601562,
  },
  {
    x: 2216.170000000462,
    y: 0.00078582763671875,
  },
  {
    x: 2216.1800000004623,
    y: 0.0007557868957519531,
  },
  {
    x: 2216.1900000004625,
    y: 0.0007276535034179688,
  },
  {
    x: 2216.2000000004628,
    y: 0.0007009506225585938,
  },
  {
    x: 2216.210000000463,
    y: 0.0006761550903320312,
  },
  {
    x: 2216.220000000463,
    y: 0.0006527900695800781,
  },
  {
    x: 2216.2300000004634,
    y: 0.0006303787231445312,
  },
  {
    x: 2216.2400000004636,
    y: 0.0006093978881835938,
  },
  {
    x: 2216.250000000464,
    y: 0.0005893707275390625,
  },
  {
    x: 2216.260000000464,
    y: 0.0005707740783691406,
  },
  {
    x: 2216.2700000004643,
    y: 0.000553131103515625,
  },
  {
    x: 2216.2800000004645,
    y: 0.0005359649658203125,
  },
  {
    x: 2216.2900000004647,
    y: 0.0005197525024414062,
  },
  {
    x: 2216.300000000465,
    y: 0.0005044937133789062,
  },
  {
    x: 2216.310000000465,
    y: 0.0004901885986328125,
  },
  {
    x: 2216.3200000004654,
    y: 0.0004761219024658203,
  },
  {
    x: 2216.3300000004656,
    y: 0.0004630088806152344,
  },
  {
    x: 2216.340000000466,
    y: 0.00045037269592285156,
  },
  {
    x: 2216.350000000466,
    y: 0.00043845176696777344,
  },
  {
    x: 2216.3600000004662,
    y: 0.00042700767517089844,
  },
  {
    x: 2216.3700000004665,
    y: 0.00041604042053222656,
  },
  {
    x: 2216.3800000004667,
    y: 0.0004055500030517578,
  },
  {
    x: 2216.390000000467,
    y: 0.0003955364227294922,
  },
  {
    x: 2216.400000000467,
    y: 0.0003859996795654297,
  },
  {
    x: 2216.4100000004673,
    y: 0.0003769397735595703,
  },
  {
    x: 2216.4200000004676,
    y: 0.0003681182861328125,
  },
  {
    x: 2216.4300000004678,
    y: 0.00035953521728515625,
  },
  {
    x: 2216.440000000468,
    y: 0.0003516674041748047,
  },
  {
    x: 2216.450000000468,
    y: 0.0003437995910644531,
  },
  {
    x: 2216.4600000004684,
    y: 0.0003364086151123047,
  },
  {
    x: 2216.4700000004686,
    y: 0.0003292560577392578,
  },
  {
    x: 2216.480000000469,
    y: 0.0003223419189453125,
  },
  {
    x: 2216.490000000469,
    y: 0.00031566619873046875,
  },
  {
    x: 2216.5000000004693,
    y: 0.0003094673156738281,
  },
  {
    x: 2216.5100000004695,
    y: 0.0003032684326171875,
  },
  {
    x: 2216.5200000004697,
    y: 0.00029730796813964844,
  },
  {
    x: 2216.53000000047,
    y: 0.00029158592224121094,
  },
  {
    x: 2216.54000000047,
    y: 0.000286102294921875,
  },
  {
    x: 2216.5500000004704,
    y: 0.0002808570861816406,
  },
  {
    x: 2216.5600000004706,
    y: 0.0002758502960205078,
  },
  {
    x: 2216.570000000471,
    y: 0.00027108192443847656,
  },
  {
    x: 2216.580000000471,
    y: 0.0002663135528564453,
  },
  {
    x: 2216.5900000004713,
    y: 0.0002617835998535156,
  },
  {
    x: 2216.6000000004715,
    y: 0.00025725364685058594,
  },
  {
    x: 2216.6100000004717,
    y: 0.0002529621124267578,
  },
  {
    x: 2216.620000000472,
    y: 0.00024890899658203125,
  },
  {
    x: 2216.630000000472,
    y: 0.0002448558807373047,
  },
  {
    x: 2216.6400000004724,
    y: 0.00024116039276123047,
  },
  {
    x: 2216.6500000004726,
    y: 0.00023746490478515625,
  },
  {
    x: 2216.660000000473,
    y: 0.0002338886260986328,
  },
  {
    x: 2216.670000000473,
    y: 0.00023043155670166016,
  },
  {
    x: 2216.6800000004732,
    y: 0.00022709369659423828,
  },
  {
    x: 2216.6900000004734,
    y: 0.0002238750457763672,
  },
  {
    x: 2216.7000000004737,
    y: 0.0002206563949584961,
  },
  {
    x: 2216.710000000474,
    y: 0.00021767616271972656,
  },
  {
    x: 2216.720000000474,
    y: 0.0002148151397705078,
  },
  {
    x: 2216.7300000004743,
    y: 0.00021195411682128906,
  },
  {
    x: 2216.7400000004745,
    y: 0.0002092123031616211,
  },
  {
    x: 2216.7500000004748,
    y: 0.0002065896987915039,
  },
  {
    x: 2216.760000000475,
    y: 0.00020396709442138672,
  },
  {
    x: 2216.770000000475,
    y: 0.0002014636993408203,
  },
  {
    x: 2216.7800000004754,
    y: 0.0001990795135498047,
  },
  {
    x: 2216.7900000004756,
    y: 0.00019681453704833984,
  },
  {
    x: 2216.800000000476,
    y: 0.000194549560546875,
  },
  {
    x: 2216.810000000476,
    y: 0.00019228458404541016,
  },
  {
    x: 2216.8200000004763,
    y: 0.00019025802612304688,
  },
  {
    x: 2216.8300000004765,
    y: 0.0001881122589111328,
  },
  {
    x: 2216.8400000004767,
    y: 0.0001862049102783203,
  },
  {
    x: 2216.850000000477,
    y: 0.00018417835235595703,
  },
  {
    x: 2216.860000000477,
    y: 0.0001823902130126953,
  },
  {
    x: 2216.8700000004774,
    y: 0.0001806020736694336,
  },
  {
    x: 2216.8800000004776,
    y: 0.00017881393432617188,
  },
  {
    x: 2216.890000000478,
    y: 0.00017714500427246094,
  },
  {
    x: 2216.900000000478,
    y: 0.00017547607421875,
  },
  {
    x: 2216.9100000004782,
    y: 0.00017392635345458984,
  },
  {
    x: 2216.9200000004785,
    y: 0.0001723766326904297,
  },
  {
    x: 2216.9300000004787,
    y: 0.0001709461212158203,
  },
  {
    x: 2216.940000000479,
    y: 0.00016951560974121094,
  },
  {
    x: 2216.950000000479,
    y: 0.00016808509826660156,
  },
  {
    x: 2216.9600000004793,
    y: 0.00016677379608154297,
  },
  {
    x: 2216.9700000004796,
    y: 0.00016546249389648438,
  },
  {
    x: 2216.98000000048,
    y: 0.00016427040100097656,
  },
  {
    x: 2216.99000000048,
    y: 0.00016307830810546875,
  },
  {
    x: 2217.00000000048,
    y: 0.00016188621520996094,
  },
  {
    x: 2217.0100000004804,
    y: 0.0001608133316040039,
  },
  {
    x: 2217.0200000004806,
    y: 0.00015974044799804688,
  },
  {
    x: 2217.030000000481,
    y: 0.00015866756439208984,
  },
  {
    x: 2217.040000000481,
    y: 0.0001577138900756836,
  },
  {
    x: 2217.0500000004813,
    y: 0.00015676021575927734,
  },
  {
    x: 2217.0600000004815,
    y: 0.00015592575073242188,
  },
  {
    x: 2217.0700000004817,
    y: 0.00015497207641601562,
  },
  {
    x: 2217.080000000482,
    y: 0.00015413761138916016,
  },
  {
    x: 2217.090000000482,
    y: 0.0001533031463623047,
  },
  {
    x: 2217.1000000004824,
    y: 0.000152587890625,
  },
  {
    x: 2217.1100000004826,
    y: 0.0001518726348876953,
  },
  {
    x: 2217.120000000483,
    y: 0.00015115737915039062,
  },
  {
    x: 2217.130000000483,
    y: 0.00015044212341308594,
  },
  {
    x: 2217.1400000004833,
    y: 0.00014984607696533203,
  },
  {
    x: 2217.1500000004835,
    y: 0.00014925003051757812,
  },
  {
    x: 2217.1600000004837,
    y: 0.00014865398406982422,
  },
  {
    x: 2217.170000000484,
    y: 0.0001481771469116211,
  },
  {
    x: 2217.180000000484,
    y: 0.00014770030975341797,
  },
  {
    x: 2217.1900000004844,
    y: 0.00014722347259521484,
  },
  {
    x: 2217.2000000004846,
    y: 0.00014674663543701172,
  },
  {
    x: 2217.210000000485,
    y: 0.00014638900756835938,
  },
  {
    x: 2217.220000000485,
    y: 0.00014603137969970703,
  },
  {
    x: 2217.2300000004852,
    y: 0.0001456737518310547,
  },
  {
    x: 2217.2400000004855,
    y: 0.00014531612396240234,
  },
  {
    x: 2217.2500000004857,
    y: 0.00014507770538330078,
  },
  {
    x: 2217.260000000486,
    y: 0.00014483928680419922,
  },
  {
    x: 2217.270000000486,
    y: 0.00014460086822509766,
  },
  {
    x: 2217.2800000004863,
    y: 0.0001443624496459961,
  },
  {
    x: 2217.2900000004865,
    y: 0.0001442432403564453,
  },
  {
    x: 2217.3000000004868,
    y: 0.00014412403106689453,
  },
  {
    x: 2217.310000000487,
    y: 0.00014400482177734375,
  },
  {
    x: 2217.320000000487,
    y: 0.00014400482177734375,
  },
  {
    x: 2217.3300000004874,
    y: 0.00014400482177734375,
  },
  {
    x: 2217.3400000004876,
    y: 0.00014400482177734375,
  },
  {
    x: 2217.350000000488,
    y: 0.00014400482177734375,
  },
  {
    x: 2217.360000000488,
    y: 0.00014412403106689453,
  },
  {
    x: 2217.3700000004883,
    y: 0.00014412403106689453,
  },
  {
    x: 2217.3800000004885,
    y: 0.0001442432403564453,
  },
  {
    x: 2217.3900000004887,
    y: 0.00014448165893554688,
  },
  {
    x: 2217.400000000489,
    y: 0.00014460086822509766,
  },
  {
    x: 2217.410000000489,
    y: 0.00014483928680419922,
  },
  {
    x: 2217.4200000004894,
    y: 0.00014519691467285156,
  },
  {
    x: 2217.4300000004896,
    y: 0.00014543533325195312,
  },
  {
    x: 2217.44000000049,
    y: 0.00014579296112060547,
  },
  {
    x: 2217.45000000049,
    y: 0.0001461505889892578,
  },
  {
    x: 2217.4600000004903,
    y: 0.00014650821685791016,
  },
  {
    x: 2217.4700000004905,
    y: 0.00014698505401611328,
  },
  {
    x: 2217.4800000004907,
    y: 0.0001474618911743164,
  },
  {
    x: 2217.490000000491,
    y: 0.00014793872833251953,
  },
  {
    x: 2217.500000000491,
    y: 0.00014853477478027344,
  },
  {
    x: 2217.5100000004913,
    y: 0.00014913082122802734,
  },
  {
    x: 2217.5200000004916,
    y: 0.00014972686767578125,
  },
  {
    x: 2217.530000000492,
    y: 0.00015044212341308594,
  },
  {
    x: 2217.540000000492,
    y: 0.00015103816986083984,
  },
  {
    x: 2217.550000000492,
    y: 0.0001518726348876953,
  },
  {
    x: 2217.5600000004924,
    y: 0.000152587890625,
  },
  {
    x: 2217.5700000004927,
    y: 0.00015342235565185547,
  },
  {
    x: 2217.580000000493,
    y: 0.00015437602996826172,
  },
  {
    x: 2217.590000000493,
    y: 0.00015532970428466797,
  },
  {
    x: 2217.6000000004933,
    y: 0.00015628337860107422,
  },
  {
    x: 2217.6100000004935,
    y: 0.00015723705291748047,
  },
  {
    x: 2217.6200000004937,
    y: 0.0001583099365234375,
  },
  {
    x: 2217.630000000494,
    y: 0.0001595020294189453,
  },
  {
    x: 2217.640000000494,
    y: 0.00016069412231445312,
  },
  {
    x: 2217.6500000004944,
    y: 0.00016200542449951172,
  },
  {
    x: 2217.6600000004946,
    y: 0.0001633167266845703,
  },
  {
    x: 2217.670000000495,
    y: 0.0001646280288696289,
  },
  {
    x: 2217.680000000495,
    y: 0.00016605854034423828,
  },
  {
    x: 2217.6900000004953,
    y: 0.00016748905181884766,
  },
  {
    x: 2217.7000000004955,
    y: 0.00016891956329345703,
  },
  {
    x: 2217.7100000004957,
    y: 0.0001704692840576172,
  },
  {
    x: 2217.720000000496,
    y: 0.00017213821411132812,
  },
  {
    x: 2217.730000000496,
    y: 0.00017380714416503906,
  },
  {
    x: 2217.7400000004964,
    y: 0.00017547607421875,
  },
  {
    x: 2217.7500000004966,
    y: 0.0001773834228515625,
  },
  {
    x: 2217.760000000497,
    y: 0.000179290771484375,
  },
  {
    x: 2217.770000000497,
    y: 0.00018131732940673828,
  },
  {
    x: 2217.7800000004972,
    y: 0.00018334388732910156,
  },
  {
    x: 2217.7900000004975,
    y: 0.00018548965454101562,
  },
  {
    x: 2217.8000000004977,
    y: 0.00018775463104248047,
  },
  {
    x: 2217.810000000498,
    y: 0.0001901388168334961,
  },
  {
    x: 2217.820000000498,
    y: 0.00019252300262451172,
  },
  {
    x: 2217.8300000004983,
    y: 0.0001951456069946289,
  },
  {
    x: 2217.8400000004985,
    y: 0.0001977682113647461,
  },
  {
    x: 2217.8500000004988,
    y: 0.00020051002502441406,
  },
  {
    x: 2217.860000000499,
    y: 0.0002033710479736328,
  },
  {
    x: 2217.870000000499,
    y: 0.00020635128021240234,
  },
  {
    x: 2217.8800000004994,
    y: 0.00020945072174072266,
  },
  {
    x: 2217.8900000004996,
    y: 0.00021266937255859375,
  },
  {
    x: 2217.9000000005,
    y: 0.0002161264419555664,
  },
  {
    x: 2217.9100000005,
    y: 0.00021958351135253906,
  },
  {
    x: 2217.9200000005003,
    y: 0.00022327899932861328,
  },
  {
    x: 2217.9300000005005,
    y: 0.00022709369659423828,
  },
  {
    x: 2217.9400000005007,
    y: 0.00023102760314941406,
  },
  {
    x: 2217.950000000501,
    y: 0.00023508071899414062,
  },
  {
    x: 2217.960000000501,
    y: 0.00023949146270751953,
  },
  {
    x: 2217.9700000005014,
    y: 0.00024390220642089844,
  },
  {
    x: 2217.9800000005016,
    y: 0.0002486705780029297,
  },
  {
    x: 2217.990000000502,
    y: 0.00025343894958496094,
  },
  {
    x: 2218.000000000502,
    y: 0.0002586841583251953,
  },
  {
    x: 2218.0100000005023,
    y: 0.0002639293670654297,
  },
  {
    x: 2218.0200000005025,
    y: 0.0002694129943847656,
  },
  {
    x: 2218.0300000005027,
    y: 0.0002753734588623047,
  },
  {
    x: 2218.040000000503,
    y: 0.00028133392333984375,
  },
  {
    x: 2218.050000000503,
    y: 0.00028777122497558594,
  },
  {
    x: 2218.0600000005034,
    y: 0.0002944469451904297,
  },
  {
    x: 2218.0700000005036,
    y: 0.000301361083984375,
  },
  {
    x: 2218.080000000504,
    y: 0.00030875205993652344,
  },
  {
    x: 2218.090000000504,
    y: 0.00031638145446777344,
  },
  {
    x: 2218.100000000504,
    y: 0.00032448768615722656,
  },
  {
    x: 2218.1100000005044,
    y: 0.00033283233642578125,
  },
  {
    x: 2218.1200000005047,
    y: 0.00034165382385253906,
  },
  {
    x: 2218.130000000505,
    y: 0.0003509521484375,
  },
  {
    x: 2218.140000000505,
    y: 0.00036072731018066406,
  },
  {
    x: 2218.1500000005053,
    y: 0.0003712177276611328,
  },
  {
    x: 2218.1600000005055,
    y: 0.0003819465637207031,
  },
  {
    x: 2218.1700000005058,
    y: 0.0003933906555175781,
  },
  {
    x: 2218.180000000506,
    y: 0.0004055500030517578,
  },
  {
    x: 2218.190000000506,
    y: 0.0004181861877441406,
  },
  {
    x: 2218.2000000005064,
    y: 0.0004317760467529297,
  },
  {
    x: 2218.2100000005066,
    y: 0.0004458427429199219,
  },
  {
    x: 2218.220000000507,
    y: 0.0004608631134033203,
  },
  {
    x: 2218.230000000507,
    y: 0.000476837158203125,
  },
  {
    x: 2218.2400000005073,
    y: 0.0004940032958984375,
  },
  {
    x: 2218.2500000005075,
    y: 0.0005116462707519531,
  },
  {
    x: 2218.2600000005077,
    y: 0.0005307197570800781,
  },
  {
    x: 2218.270000000508,
    y: 0.0005512237548828125,
  },
  {
    x: 2218.280000000508,
    y: 0.0005726814270019531,
  },
  {
    x: 2218.2900000005084,
    y: 0.0005960464477539062,
  },
  {
    x: 2218.3000000005086,
    y: 0.0006208419799804688,
  },
  {
    x: 2218.310000000509,
    y: 0.0006470680236816406,
  },
  {
    x: 2218.320000000509,
    y: 0.000675201416015625,
  },
  {
    x: 2218.3300000005092,
    y: 0.000705718994140625,
  },
  {
    x: 2218.3400000005095,
    y: 0.0007381439208984375,
  },
  {
    x: 2218.3500000005097,
    y: 0.0007734298706054688,
  },
  {
    x: 2218.36000000051,
    y: 0.0008111000061035156,
  },
  {
    x: 2218.37000000051,
    y: 0.0008521080017089844,
  },
  {
    x: 2218.3800000005103,
    y: 0.000896453857421875,
  },
  {
    x: 2218.3900000005106,
    y: 0.0009446144104003906,
  },
  {
    x: 2218.4000000005108,
    y: 0.0009965896606445312,
  },
  {
    x: 2218.410000000511,
    y: 0.0010538101196289062,
  },
  {
    x: 2218.420000000511,
    y: 0.0011157989501953125,
  },
  {
    x: 2218.4300000005114,
    y: 0.0011835098266601562,
  },
  {
    x: 2218.4400000005116,
    y: 0.0012578964233398438,
  },
  {
    x: 2218.450000000512,
    y: 0.0013399124145507812,
  },
  {
    x: 2218.460000000512,
    y: 0.0014314651489257812,
  },
  {
    x: 2218.4700000005123,
    y: 0.0015316009521484375,
  },
  {
    x: 2218.4800000005125,
    y: 0.0016431808471679688,
  },
  {
    x: 2218.4900000005127,
    y: 0.0017681121826171875,
  },
  {
    x: 2218.500000000513,
    y: 0.0019073486328125,
  },
  {
    x: 2218.510000000513,
    y: 0.002063751220703125,
  },
  {
    x: 2218.5200000005134,
    y: 0.0022411346435546875,
  },
  {
    x: 2218.5300000005136,
    y: 0.0024433135986328125,
  },
  {
    x: 2218.540000000514,
    y: 0.0026721954345703125,
  },
  {
    x: 2218.550000000514,
    y: 0.00293731689453125,
  },
  {
    x: 2218.5600000005143,
    y: 0.0032405853271484375,
  },
  {
    x: 2218.5700000005145,
    y: 0.00359344482421875,
  },
  {
    x: 2218.5800000005147,
    y: 0.004009246826171875,
  },
  {
    x: 2218.590000000515,
    y: 0.00449371337890625,
  },
  {
    x: 2218.600000000515,
    y: 0.00507354736328125,
  },
  {
    x: 2218.6100000005154,
    y: 0.005767822265625,
  },
  {
    x: 2218.6200000005156,
    y: 0.0066070556640625,
  },
  {
    x: 2218.630000000516,
    y: 0.007633209228515625,
  },
  {
    x: 2218.640000000516,
    y: 0.0088958740234375,
  },
  {
    x: 2218.6500000005162,
    y: 0.01047515869140625,
  },
  {
    x: 2218.6600000005164,
    y: 0.01245880126953125,
  },
  {
    x: 2218.6700000005167,
    y: 0.0149688720703125,
  },
  {
    x: 2218.680000000517,
    y: 0.018157958984375,
  },
  {
    x: 2218.690000000517,
    y: 0.022186279296875,
  },
  {
    x: 2218.7000000005173,
    y: 0.02716064453125,
  },
  {
    x: 2218.7100000005175,
    y: 0.032989501953125,
  },
  {
    x: 2218.7200000005178,
    y: 0.039154052734375,
  },
  {
    x: 2218.730000000518,
    y: 0.044403076171875,
  },
  {
    x: 2218.740000000518,
    y: 0.047088623046875,
  },
  {
    x: 2218.7500000005184,
    y: 0.046112060546875,
  },
  {
    x: 2218.7600000005186,
    y: 0.041900634765625,
  },
  {
    x: 2218.770000000519,
    y: 0.035980224609375,
  },
  {
    x: 2218.780000000519,
    y: 0.029876708984375,
  },
  {
    x: 2218.7900000005193,
    y: 0.0244598388671875,
  },
  {
    x: 2218.8000000005195,
    y: 0.0199737548828125,
  },
  {
    x: 2218.8100000005197,
    y: 0.0164031982421875,
  },
  {
    x: 2218.82000000052,
    y: 0.01358795166015625,
  },
  {
    x: 2218.83000000052,
    y: 0.0113677978515625,
  },
  {
    x: 2218.8400000005204,
    y: 0.00960540771484375,
  },
  {
    x: 2218.8500000005206,
    y: 0.00820159912109375,
  },
  {
    x: 2218.860000000521,
    y: 0.00707244873046875,
  },
  {
    x: 2218.870000000521,
    y: 0.006145477294921875,
  },
  {
    x: 2218.8800000005212,
    y: 0.0053863525390625,
  },
  {
    x: 2218.8900000005215,
    y: 0.004756927490234375,
  },
  {
    x: 2218.9000000005217,
    y: 0.0042266845703125,
  },
  {
    x: 2218.910000000522,
    y: 0.003780364990234375,
  },
  {
    x: 2218.920000000522,
    y: 0.003398895263671875,
  },
  {
    x: 2218.9300000005223,
    y: 0.003070831298828125,
  },
  {
    x: 2218.9400000005226,
    y: 0.002788543701171875,
  },
  {
    x: 2218.950000000523,
    y: 0.0025424957275390625,
  },
  {
    x: 2218.960000000523,
    y: 0.0023288726806640625,
  },
  {
    x: 2218.970000000523,
    y: 0.002140045166015625,
  },
  {
    x: 2218.9800000005234,
    y: 0.0019741058349609375,
  },
  {
    x: 2218.9900000005237,
    y: 0.0018253326416015625,
  },
  {
    x: 2219.000000000524,
    y: 0.0016937255859375,
  },
  {
    x: 2219.010000000524,
    y: 0.001575469970703125,
  },
  {
    x: 2219.0200000005243,
    y: 0.0014696121215820312,
  },
  {
    x: 2219.0300000005245,
    y: 0.0013742446899414062,
  },
  {
    x: 2219.0400000005247,
    y: 0.0012874603271484375,
  },
  {
    x: 2219.050000000525,
    y: 0.001209259033203125,
  },
  {
    x: 2219.060000000525,
    y: 0.0011377334594726562,
  },
  {
    x: 2219.0700000005254,
    y: 0.0010728836059570312,
  },
  {
    x: 2219.0800000005256,
    y: 0.0010128021240234375,
  },
  {
    x: 2219.090000000526,
    y: 0.0009579658508300781,
  },
  {
    x: 2219.100000000526,
    y: 0.00090789794921875,
  },
  {
    x: 2219.1100000005263,
    y: 0.0008616447448730469,
  },
  {
    x: 2219.1200000005265,
    y: 0.0008187294006347656,
  },
  {
    x: 2219.1300000005267,
    y: 0.0007791519165039062,
  },
  {
    x: 2219.140000000527,
    y: 0.0007424354553222656,
  },
  {
    x: 2219.150000000527,
    y: 0.0007081031799316406,
  },
  {
    x: 2219.1600000005274,
    y: 0.0006766319274902344,
  },
  {
    x: 2219.1700000005276,
    y: 0.0006470680236816406,
  },
  {
    x: 2219.180000000528,
    y: 0.0006194114685058594,
  },
  {
    x: 2219.190000000528,
    y: 0.0005931854248046875,
  },
  {
    x: 2219.2000000005282,
    y: 0.0005693435668945312,
  },
  {
    x: 2219.2100000005285,
    y: 0.0005464553833007812,
  },
  {
    x: 2219.2200000005287,
    y: 0.0005254745483398438,
  },
  {
    x: 2219.230000000529,
    y: 0.0005054473876953125,
  },
  {
    x: 2219.240000000529,
    y: 0.0004863739013671875,
  },
  {
    x: 2219.2500000005293,
    y: 0.0004687309265136719,
  },
  {
    x: 2219.2600000005295,
    y: 0.00045180320739746094,
  },
  {
    x: 2219.2700000005298,
    y: 0.0004360675811767578,
  },
  {
    x: 2219.28000000053,
    y: 0.0004210472106933594,
  },
  {
    x: 2219.29000000053,
    y: 0.0004067420959472656,
  },
  {
    x: 2219.3000000005304,
    y: 0.0003933906555175781,
  },
  {
    x: 2219.3100000005306,
    y: 0.0003807544708251953,
  },
  {
    x: 2219.320000000531,
    y: 0.0003685951232910156,
  },
  {
    x: 2219.330000000531,
    y: 0.0003571510314941406,
  },
  {
    x: 2219.3400000005313,
    y: 0.00034618377685546875,
  },
  {
    x: 2219.3500000005315,
    y: 0.000335693359375,
  },
  {
    x: 2219.3600000005317,
    y: 0.00032591819763183594,
  },
  {
    x: 2219.370000000532,
    y: 0.00031638145446777344,
  },
  {
    x: 2219.380000000532,
    y: 0.00030732154846191406,
  },
  {
    x: 2219.3900000005324,
    y: 0.0002987384796142578,
  },
  {
    x: 2219.4000000005326,
    y: 0.0002906322479248047,
  },
  {
    x: 2219.410000000533,
    y: 0.0002827644348144531,
  },
  {
    x: 2219.420000000533,
    y: 0.0002751350402832031,
  },
  {
    x: 2219.4300000005333,
    y: 0.00026798248291015625,
  },
  {
    x: 2219.4400000005335,
    y: 0.00026106834411621094,
  },
  {
    x: 2219.4500000005337,
    y: 0.0002543926239013672,
  },
  {
    x: 2219.460000000534,
    y: 0.00024819374084472656,
  },
  {
    x: 2219.470000000534,
    y: 0.00024199485778808594,
  },
  {
    x: 2219.4800000005343,
    y: 0.00023615360260009766,
  },
  {
    x: 2219.4900000005346,
    y: 0.00023055076599121094,
  },
  {
    x: 2219.500000000535,
    y: 0.00022518634796142578,
  },
  {
    x: 2219.510000000535,
    y: 0.0002199411392211914,
  },
  {
    x: 2219.520000000535,
    y: 0.0002149343490600586,
  },
  {
    x: 2219.5300000005354,
    y: 0.00021016597747802734,
  },
  {
    x: 2219.5400000005357,
    y: 0.00020551681518554688,
  },
  {
    x: 2219.550000000536,
    y: 0.0002009868621826172,
  },
  {
    x: 2219.560000000536,
    y: 0.00019669532775878906,
  },
  {
    x: 2219.5700000005363,
    y: 0.00019252300262451172,
  },
  {
    x: 2219.5800000005365,
    y: 0.00018846988677978516,
  },
  {
    x: 2219.5900000005367,
    y: 0.00018465518951416016,
  },
  {
    x: 2219.600000000537,
    y: 0.00018084049224853516,
  },
  {
    x: 2219.610000000537,
    y: 0.00017726421356201172,
  },
  {
    x: 2219.6200000005374,
    y: 0.00017380714416503906,
  },
  {
    x: 2219.6300000005376,
    y: 0.0001703500747680664,
  },
  {
    x: 2219.640000000538,
    y: 0.0001671314239501953,
  },
  {
    x: 2219.650000000538,
    y: 0.00016391277313232422,
  },
  {
    x: 2219.6600000005383,
    y: 0.0001609325408935547,
  },
  {
    x: 2219.6700000005385,
    y: 0.00015795230865478516,
  },
  {
    x: 2219.6800000005387,
    y: 0.00015497207641601562,
  },
  {
    x: 2219.690000000539,
    y: 0.00015223026275634766,
  },
  {
    x: 2219.700000000539,
    y: 0.0001494884490966797,
  },
  {
    x: 2219.7100000005394,
    y: 0.0001468658447265625,
  },
  {
    x: 2219.7200000005396,
    y: 0.0001443624496459961,
  },
  {
    x: 2219.73000000054,
    y: 0.0001418590545654297,
  },
  {
    x: 2219.74000000054,
    y: 0.00013947486877441406,
  },
  {
    x: 2219.7500000005402,
    y: 0.00013709068298339844,
  },
  {
    x: 2219.7600000005405,
    y: 0.0001348257064819336,
  },
  {
    x: 2219.7700000005407,
    y: 0.00013267993927001953,
  },
  {
    x: 2219.780000000541,
    y: 0.00013053417205810547,
  },
  {
    x: 2219.790000000541,
    y: 0.0001285076141357422,
  },
  {
    x: 2219.8000000005413,
    y: 0.0001264810562133789,
  },
  {
    x: 2219.8100000005415,
    y: 0.00012445449829101562,
  },
  {
    x: 2219.8200000005418,
    y: 0.00012254714965820312,
  },
  {
    x: 2219.830000000542,
    y: 0.00012069940567016602,
  },
  {
    x: 2219.840000000542,
    y: 0.0001188516616821289,
  },
  {
    x: 2219.8500000005424,
    y: 0.00011706352233886719,
  },
  {
    x: 2219.8600000005426,
    y: 0.00011533498764038086,
  },
  {
    x: 2219.870000000543,
    y: 0.00011366605758666992,
  },
  {
    x: 2219.880000000543,
    y: 0.00011205673217773438,
  },
  {
    x: 2219.8900000005433,
    y: 0.00011044740676879883,
  },
  {
    x: 2219.9000000005435,
    y: 0.00010889768600463867,
  },
  {
    x: 2219.9100000005437,
    y: 0.00010734796524047852,
  },
  {
    x: 2219.920000000544,
    y: 0.00010591745376586914,
  },
  {
    x: 2219.930000000544,
    y: 0.00010442733764648438,
  },
  {
    x: 2219.9400000005444,
    y: 0.00010305643081665039,
  },
  {
    x: 2219.9500000005446,
    y: 0.0001016855239868164,
  },
  {
    x: 2219.960000000545,
    y: 0.00010031461715698242,
  },
  {
    x: 2219.970000000545,
    y: 0.00009900331497192383,
  },
  {
    x: 2219.9800000005453,
    y: 0.00009769201278686523,
  },
  {
    x: 2219.9900000005455,
    y: 0.00009644031524658203,
  },
];