import React, { useState, useEffect } from "react"
import { Grid, Card, CardContent, Typography, Paper, Button, Snackbar, Alert } from "@mui/material";
import { SpecInputTextField } from "../SpecInputTextField";
import { input, blackbodyPlot } from "../../Functions/types";
import { waveLengthConversion, sendInputsToBackendBlackbody, convertToSigFigs } from "../../Functions/DataFunctions";
import useSpectraplotBlackbodyState from "../../Functions/BlackbodyStore";

const inputJSON = {
    url: "https://dg2kzkviqxwbgde5u4zgdedagm0cdtip.lambda-url.us-west-1.on.aws/",
    inputs: [
        "temperature",
        "emissivity",
        "nu_start",
        "nu_end",
    ]
};

//
export const BlackbodyInput: React.FC = () => {

    const { storeNewInputs, addOutputToMatchingInput, clearPlots, swapPlot, clearSwapPlot, addErrorToMatchingInputs } = useSpectraplotBlackbodyState();


    const [inputValues, setInputValues] = useState({});
    const [inputArray, setInputArray] = useState<input[]>();
    const [throwInputError, setThrowInputError] = useState<string[]>([]);
    const [snackBarMessage, setSnackBarMessage] = useState<string>('');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    useEffect(() => {
        if (swapPlot.inputs) {
            setInputArray(swapPlot.inputs);
            const tmp = {} as any;
            swapPlot.inputs.forEach(e => {
                let name = e.name;
                tmp[name] = (e.value ? e.value.toString() : '')
            });

            tmp['nu_end'] = convertToSigFigs((10000 / parseFloat(tmp.lend)), 6);


            tmp['nu_start'] = convertToSigFigs((10000 / parseFloat(tmp.lstart)), 6);
            //  console.log(tmp);
            setInputValues(tmp);
            clearSwapPlot();
        }
    }, [swapPlot]);

    const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        if (['lstart', 'lend', 'nu_start', 'nu_end'].includes(name)) {
            const convertedObject = waveLengthConversion(name, value);
            setInputValues(prevState => ({
                ...prevState,
                [name]: value,
                [convertedObject.name]: convertedObject.value
            }));
        }
        else {
            setInputValues(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    useEffect(() => {
        setInputArray(inputJSON.inputs.map(n => ({ name: n, value: null } as input)));
        let tmpInputValues = {} as any;
        inputJSON.inputs.forEach(n => {
            if (n !== 'xsec_ID') {
                if (Object.keys(inputValues).includes(n)) {// this value is already inputted.
                    tmpInputValues[n] = inputValues[n as keyof typeof inputValues];
                }
                else {
                    tmpInputValues[n] = 0;
                }
            }
        });
        tmpInputValues['lend'] = (inputValues['lend' as keyof typeof inputValues] ? inputValues['lend' as keyof typeof inputValues] : '');
        tmpInputValues['lstart'] = (inputValues['lstart' as keyof typeof inputValues] ? inputValues['lstart' as keyof typeof inputValues] : '');
        setInputValues(tmpInputValues);
    }, []);

    const handleAddToPlot = (e: any) => {
        //input validation. Essentially, everything needs to be filled in except possibly for lstart and lend. I think.
        const tmp: string[] = [];
        Object.keys(inputValues).forEach(key => {
            if (inputValues[key as keyof typeof inputValues] === '' || typeof inputValues[key as keyof typeof inputValues] === 'number') {
                tmp.push(key);
            }
        });
        if (tmp.length === 0) {
            setThrowInputError([]);
            getOutputFromInputs(inputValues);
        }
        else {
            setThrowInputError(tmp);
            setOpenErrorSnackbar(true);
        }
    }
    const clearOptions = () => {

        clearPlots();
        // setSelectedSpecies('');
        // setCrossSections([]);
        // setInputArray([]);
        // setDatabases([]);
    }

    const getOutputFromInputs = (inputs: any) => {//eventually move this somewhere else
        const url = inputJSON.url;
        const temporaryInputs = inputs;

        const inputObject = {
            inputData: {
                inputs: [
                    {
                        name: 'temperature',
                        value: parseFloat(temporaryInputs['temperature'])
                    },
                    {
                        name: 'emissivity',
                        value: parseFloat(temporaryInputs['emissivity'])
                    },
                    {
                        name: 'lstart',
                        value: parseFloat(temporaryInputs['lstart'])
                    },
                    {
                        name: 'lend',
                        value: parseFloat(temporaryInputs['lend'])
                    }
                ],
                isVisible: true,
            }
        };
        const tmp2 = inputObject;
        console.log(tmp2);
        storeNewInputs(inputObject.inputData as blackbodyPlot);
        sendInputsToBackendBlackbody(inputObject, url, snackBarFunction, addOutputToMatchingInput, addErrorToMatchingInputs)
    }

    // helper function to act as a callback for sendInputsToBackend
    const snackBarFunction = (message: string) => {
        setSnackBarMessage(message)
        setOpenSnackBar(true);
    }
    //Snackbar functions
    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    return <>
        <Paper sx={{ padding: '1em', marginLeft: '1em', marginRight: '1em' }}  >
            <Grid container>

                <Grid item xs={12} sm={12} md={3} lg={2} order={{md: 2, lg:2}} >
                            {
                                Object.keys(inputValues) && Object.keys(inputValues).map((key, index) => {

                                    if (!["nu_start", "nu_end", "nu_step", "lstart", "lend", "xsec_ID"].includes(key)) {
                                        return <>
                                            <SpecInputTextField key={key + index} valueChange={valueChange} label={key} inputValues={inputValues} margin=".3em" waveConversion={false} throwError={throwInputError} />
                                        </>
                                    }
                                })
                            }

                </Grid>
                <Grid xs={12} sm={12} md={3} lg={2} order={{md: 3, lg: 3}}>

                            {inputArray &&
                                <div className="animate__animated animate__fadeIn" style={{}} >

                                    <SpecInputTextField valueChange={valueChange} label={"lstart"} inputValues={inputValues} margin=".3em" waveConversion={true} throwError={throwInputError} />

                                    <SpecInputTextField valueChange={valueChange} label={"lend"} inputValues={inputValues} margin=".3em" waveConversion={true} throwError={throwInputError} />
                                </div>
                            }
                            {inputArray &&
                                <div className="animate__animated animate__fadeIn" style={{}} >
                                    {
                                        ["nu_start", "nu_end"].map((key, index) => {
                                            return <SpecInputTextField key={key + index} valueChange={valueChange} label={key} inputValues={inputValues} margin=".3em" waveConversion={false} throwError={throwInputError} />
                                        })
                                    }
                                </div>
                            }

                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center',  }} order={{lg: 3, md: 3, xs: 3, sm: 3}}>

                        <Button variant="outlined" onClick={handleAddToPlot}>Add to Plot</Button>
                     {/*   <Button onClick={clearOptions}>Clear Plots</Button>*/}
                        {/*   <FormGroup>
                                <FormControlLabel control={<Checkbox checked={showVoronoi} onChange={(e) => setShowVoronoi(e.target.checked)} />} label="Show Points at Cursor" />
                            </FormGroup> */}


              
                </Grid>


            </Grid>
        </Paper>
        <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            message={snackBarMessage}
            onClose={handleCloseSnackBar}
        />
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={openErrorSnackbar}
            onClose={(e) => setOpenErrorSnackbar(false)}
            autoHideDuration={4000}
            key={'top-center'}>
            <Alert severity="error" sx={{ width: '100%' }}>Please enter all required inputs.</Alert>
        </Snackbar>
    </>
}