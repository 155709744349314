import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


type props = {
    message: string
}

export const SpecConsole: React.FC<props> = ({message}) => {
    const [consoleExpanded, setConsoleExpanded] = useState<boolean>(true);

 /*   console.log('hey');
    console.log(message.split('\n\n'));
    console.log(message);*/
    return <>
    <Accordion sx={{marginBottom: '4em'}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="console"
            id="console"
        >
            <Typography>Console</Typography>
        </AccordionSummary>
        <AccordionDetails>
     
            <Typography variant="caption" sx={{lineHeight: 1}}>
            
                
                {message.split('\n').map((str, index) => <p key={'console'+index}>{str}</p>)}
            
            </Typography>

        </AccordionDetails>
    </Accordion>
    </>
}