import React, { useState, useEffect, useRef } from "react";
import useSpectraplotBlackbodyState from "../../Functions/BlackbodyStore";
import { createGraphOptionsBlackbody } from '../../Functions/DataFunctions';
import { TextField, Checkbox, } from "@mui/material";

import ReactEcharts from "echarts-for-react";


export const SpectraplotGraphBlackbody: React.FC = () => {

    const [option, setOption] = useState<any>({});
    const eChartsRef = useRef(null as any);
    const { plots, consoleLog, visibilityToggle, yMax } = useSpectraplotBlackbodyState();
    const [customYMax, setCustomYMax] = useState<boolean>(false);

    const handleYMaxChange = (e: any /*FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>*/) => {
        if (e.target.value === '') {
            setCustomYMax(false);
        }
        else if (eChartsRef && eChartsRef.current) {
            eChartsRef.current?.getEchartsInstance().setOption(
                {
                    yAxis: {
                        max: parseFloat(e.target.value),
                        type: "value",
                    }
                });
        }
    }
    const onKeyPress = (e: any) => {
        if (e.key === "Enter") {
            if (e.target.value === '') {
                setCustomYMax(false);
            }
            else if (eChartsRef && eChartsRef.current) {
                eChartsRef.current?.getEchartsInstance().setOption(
                    {
                        yAxis: {
                            max: parseFloat(e.target.value),
                            type: "value",
                        }
                    });
            }
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (!customYMax) {
            if (eChartsRef && eChartsRef.current) {
                eChartsRef.current?.getEchartsInstance().setOption(option, true);
            }
        }
    }, [customYMax]);
    useEffect(() => {
        if (yMax === 0) {
            if (eChartsRef && eChartsRef.current) {
                eChartsRef.current?.getEchartsInstance().setOption(option, true);
            }
        }
        else {
            if (eChartsRef && eChartsRef.current) {
                eChartsRef.current?.getEchartsInstance().setOption(
                    {
                        yAxis: {
                            max: yMax,
                            type: "value",
                        }
                    });
            }
        }
    }, [yMax]);

    useEffect(() => {
        if (eChartsRef && eChartsRef.current) {
            // eChartsRef.current?.getEchartsInstance().setOption(options);
        }
        console.log(option);
        setOption(createGraphOptionsBlackbody(plots));
    }, [plots, consoleLog, visibilityToggle]);

    useEffect(() => {
        if (eChartsRef && eChartsRef.current) {
            eChartsRef.current?.getEchartsInstance().setOption(option, true);
        }
    }, [option]);
    return <>
        {
            <ReactEcharts
                option={option}
                lazyUpdate={true}
                style={{ height: '30em' }}
                ref={eChartsRef} />
        }
        {
            plots.some(e => e.output && e.output.length > 0) &&
            plots.map((plot, index) => {
                if (plot.output && plot.isVisible) {
                    return <div key={"dummy" + index}></div>
                }
            })
        }
    </>
}