import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GitHubIcon from '@mui/icons-material/GitHub';
import ChatIcon from '@mui/icons-material/Chat';
import TwitterIcon from '@mui/icons-material/Twitter';
import FlareIcon from '@mui/icons-material/Flare';
import TungstenIcon from '@mui/icons-material/Tungsten';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import InfoIcon from '@mui/icons-material/Info';
import LightModeIcon from '@mui/icons-material/LightMode';
import PollIcon from '@mui/icons-material/Poll';
import { Link } from 'react-router-dom';
import { Link as MLink } from '@mui/material';


const menuLinkStyle = {
  display: 'flex',
  alignItems: 'flext-start'
}

export const BasicMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ color: 'white' }}
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <MenuItem onClick={handleClose}><MLink underline='none' style={{ display:'flex', alignItems: 'center' }}><CloudOffIcon sx={{ marginRight: '.5em' }} />Absorption</MLink></MenuItem></Link>
        <Link to="/blackbody" style={{ textDecoration: 'none' }}><MenuItem onClick={handleClose}><MLink underline='none' style={{ display:'flex', alignItems: 'center' }}><TungstenIcon sx={{ marginRight: '.5em' }} />Blackbody</MLink></MenuItem></Link>
        <Link to="/about" style={{ textDecoration: 'none'}}><MenuItem onClick={handleClose}><MLink underline='none' style={{ display:'flex', alignItems: 'center' }}><InfoIcon sx={{ marginRight: '.5em' }} />About</MLink></MenuItem></Link>
        <a href="https://github.com/SpectraPlot" rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}><MenuItem onClick={handleClose}><MLink underline='none' sx={menuLinkStyle}> <GitHubIcon sx={{ marginRight: '.5em' }} />GitHub</MLink></MenuItem></a>
        <a href="https://join.slack.com/t/spectraplot/shared_invite/zt-1fqc7ujqd-o6oEZ98FEipNtp8dixyVwA" rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}><MenuItem onClick={handleClose}><MLink underline='none' sx={menuLinkStyle}> <ChatIcon sx={{ marginRight: '.5em' }} />Slack</MLink></MenuItem></a>
        <a href="https://twitter.com/spectraplot" rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}><MenuItem onClick={handleClose}><MLink underline='none' sx={menuLinkStyle}> <TwitterIcon sx={{ marginRight: '.5em' }} />Twitter</MLink></MenuItem></a>
        <a href="https://spectraplot.com/" rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}><MenuItem onClick={handleClose}><MLink underline='none' sx={menuLinkStyle}> <FlareIcon sx={{ marginRight: '.5em' }} />Old SpectraPlot</MLink></MenuItem></a>
        <hr />
        <MenuItem><LightModeIcon sx={{ marginRight: '.5em' }} />Coming Soon - Emission</MenuItem>
        <MenuItem><PollIcon sx={{ marginRight: '.5em' }} />Coming Soon - Line Survey</MenuItem>
      </Menu>
    </div>
  );
}