import { Card, CardContent, Grid } from "@mui/material"

import { SpecHeader } from "../Components/SpecHeader"
import GitHubIcon from '@mui/icons-material/GitHub';
import ChatIcon from '@mui/icons-material/Chat';
import TwitterIcon from '@mui/icons-material/Twitter';
import FlareIcon from '@mui/icons-material/Flare';
import EmailIcon from '@mui/icons-material/Email';
import './diagram.png';

const diagram = require('./diagram.png');
export const AboutPage: React.FC = () => {
    return <>
        <SpecHeader Title={"About"} />
        <div>
            <Card>
                <CardContent>
                <h1>SpectraPlot v2.0</h1>   
                <p>SpectraPlot's mission has always been twofold: 1) strengthen the spectroscopy community and 2) organize spectral data. </p> 
                <p>After 8 years and over a million simulations with <a href="https://www.sciencedirect.com/science/article/abs/pii/S0022407317302996" target="_blank">SpectraPlot v1</a>, we've realized it was time to rebuild SpectraPlot from the ground up.  Thank you for bearing with us and enduring all of the extremely long page loads, lack of error messages, and intermittent simulation successes.</p>
                <p>Taking what we've learned, we're rebuilding in the cloud with a contemporary stack and an open source <a href="/governance">governance</a> and <a href="https://github.com/SpectraPlot" target="_blank">contribution</a> structure.</p>
                <h1>Architecture Overview</h1>
                <Grid container alignItems="top" justifyItems="middle" textAlign="left">
                <Grid item xs={12} md={7}>
                    <p>Our new architecture is built on top of two products from Amazon Web Services  - Relational Database Service (RDS) and AWS Lambda</p>
                    <p><a href="https://aws.amazon.com/free/database/" target="_blank">RDS</a> is a fully managed database service.  We refactor spectral databases (e.g., HITRAN) into a format compatible with a relational database (e.g., MySQL).
                    We then load this spectral data into a database managed by RDS, which removes most of the burden of instantiating, managing, and maintaining the spectral data in queryable database available over the internet.  
                    Spectral data is a public asset, and it should live in the cloud, freely available to anyone who wants to use it; migrating the CSVs we're all familiar with to managed relational databases is how we can achieve this.</p>
                    <p><a href="https://aws.amazon.com/lambda/" target="_blank">AWS Lambda</a> is a serverless runtime that can run python scripts on demand.  (Other programming languages and runtimes are also available.)
                    For a given simulation (e.g., a HITRAN absorption simulation), we write a python script that performs the simulation when it receives a request via a call to its API endpoint.  This script will retrieve the spectral data from the spectral database managed by RDS (e.g., HITRAN), and then the lambda function will compute the simulation and return the result.
                    AWS Lambda lets us write a separate function for each simulation type (e.g., absorption using the HITRAN cross section data), and the compute resources used to run the simulation are allocated only when the function is called by the frontend.
                    This allows for effectively unlimited concurrency and scalability, as well as scaling down to zero during idle times. </p>
                    <p>The front end of the application is written in React, the popular and contemporary componentized javascript framework.  And we use <a href="https://echarts.apache.org" target="_blank">Apache Echarts</a> for plotting.
                    We've learned some hard lessons from SpectraPlot v1 - specifically about isolation and the brittleness that results when you get it wrong.  This time, we've designed the frontend (i.e., the user interface) and backend (i.e., the simulation code) so that we can continue to improve existing capabilities and, more importantly, extend our capabilities and databases without breaking what we already have.</p>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img src={diagram} alt="nope" width="490" height="400"/>
                </Grid>
                </Grid>
                </CardContent>
                
                <CardContent>
                <h1>Databases</h1>
                <p>
                    We currently support: </p>
                    <Grid container alignItems="top" justifyItems="middle" textAlign="center">
                        <Grid item xs={12} md={2}>
                            <p><b>HITRAN, HITEMP, HITRAN x-sec</b></p>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/0/0b/HITRAN_Logo.jpg" alt="nope" width="100" height="100"/>
                            
                            <p>L.S. Rothman, History of the HITRAN database, <em>Nature Review Physics</em> <strong>3</strong>, 302-304 (2021) [<a target="_blank" href="https://doi.org/10.1038/s42254-021-00309-2">Link to article</a>] [<a target="_blank" href="http://adsabs.harvard.edu/abs/2021NatRP...3..302R">ADS</a>]</p>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <p><b>Atomic Spectral Database</b></p>
                            <img src="https://ev0awwmgj4-flywheel.netdna-ssl.com/wp-content/uploads/images/products/products-NIST-Emblem.png" alt="nope" width="100" height="100"/>
                            
                            <p>Kramida,&nbsp;A., Ralchenko,&nbsp;Yu., Reader,&nbsp;J. and <a href="/PhysRefData/ASD/index.html#Team">NIST ASD Team</a> (2021).
                            <i>NIST Atomic Spectra Database</i> (version&nbsp;5.9),
                            [Online]. Available: <a href="https://physics.nist.gov/asd">https://physics.nist.gov/asd</a>
                            [10/30/2021].
                            National Institute of Standards and Technology, Gaithersburg, MD.
                            DOI: <a href="https://doi.org/10.18434/T4W30F">https://doi.org/10.18434/T4W30F</a></p>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <p><b>ExoMol</b></p>
                            <img src="https://avatars.githubusercontent.com/u/36135908?s=200&v=4" alt="nope" width="100" height="100"/>
                            
                            <p>J. Tennyson and S. N. Yurchenko, "The ExoMol project: Software for computing large molecular line lists", <em>International Journal of Quantum Chemistry</em> <strong>117</strong>, 92–103, (2017). [DOI: 10.1002/qua.25190][<a href="http://onlinelibrary.wiley.com/doi/10.1002/qua.25190/abstract">journal link</a>][<a href="https://arxiv.org/abs/1607.01220">arXiv</a>]</p>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <p><b>PNNL</b></p>
                            <img src="https://cms.jibecdn.com/prod/pnnl/assets/OPENGRAPH-IMAGE-en-us-1622659766633.png" alt="nope" width="100" height="100"/>
                            
                            <p>Brauer C.S., T.J. Johnson, T.A. Blake, S.W. Sharpe, R.L. Sams, and R.G. Tonkyn. 2014.
                            The Northwest Infrared (NWIR) gas-phase spectral database of industrial and environmental chemicals: Recent updates.
                            In Advanced Environmental, Chemical, and Biological Sensing Technologies XI, May 5, 2014, Baltimore, Maryland. Proceedings of the SPIE [<a href="https://www.doi.org/10.1117/12.2053591" target="_blank">doi:10.1117/12.2053591</a>]</p>
                        </Grid>
                    </Grid>
                    
                </CardContent>
                <CardContent>
                <h1>Supported by</h1>
                    <Grid container alignItems="center" justifyItems="middle" textAlign="center">
                        <Grid item xs={12} md={3}>
                            
                        </Grid>
                        <Grid item xs={12} md={3}>
                            
                        </Grid>
                        <Grid item xs={12} md={3}>
                            
                        </Grid>
                        <Grid item xs={12} md={3}>
                            
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                <h1>Links</h1>
                    <Grid container alignItems="center" justifyItems="middle" textAlign="center">
                        <Grid item xs={12} md={3}>
                            <a href="https://github.com/SpectraPlot" target="_blank" style={{textDecoration: 'none', color:'black'}}><GitHubIcon sx={{ fontSize: '2em', color: 'black'}}/><p>The code!</p></a>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <a href="https://twitter.com/spectraplot" target="_blank" style={{textDecoration: 'none'}}><TwitterIcon sx={{ fontSize: '2em',color: '#1DA1F2'}}/></a><p>Sometimes we tweet</p>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <a href="https://join.slack.com/t/spectraplot/shared_invite/zt-1fqc7ujqd-o6oEZ98FEipNtp8dixyVwA" target="_blank" style={{textDecoration: 'none'}}><ChatIcon sx={{ fontSize: '2em',color: 'black'}}/></a><p>Join the Slack</p>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <a href="mailto:info@spectraplot.com" style={{textDecoration: 'none'}}><EmailIcon sx={{ fontSize: '2em',color: 'black'}}/></a><p>Email us (..you should also join the Slack)</p>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    </>
}