import React from "react";
import { Grid } from "@mui/material";
import { BasicMenu } from "./BasicMenu";
import { Link } from "react-router-dom";

type props = {
    Title: string;
};

export const SpecHeader: React.FC<props> = ({Title}) => {

    return <>
        <div style={{ backgroundColor: '#171c68', height: '10%', width: '100%', paddingLeft: '2em', paddingTop: '.5em', paddingBottom: '.5em', marginBottom: '1em' }}>
            <Grid container alignItems="center">
                <Grid item xs={9} md={10} lg={11}>
                    <div style={{ fontSize: '1.5em', color: 'white', margin: '0', textAlign: 'left' }}>
                        <Link style={{color: 'white', textDecoration: 'none'}} to='/'>
                            <strong>Spectra</strong>Plot</Link>&nbsp;&nbsp;&nbsp; <span style={{ fontSize: '.5em'}}>{Title}</span>
                        
                        
                        
                    </div>
                </Grid>
                <Grid item xs={3} md={2} lg={1}>
                    <BasicMenu />
                </Grid>
            </Grid>
        </div></>
}