import React from 'react';
import { Grid, SpeedDial, SpeedDialAction, Card } from '@mui/material';
import { useState, useRef } from 'react';
import { CurrentPlotsDisplayBlackbody } from '../Components/Blackbody/CurrentPlotsDisplayBlackbody';
import { SpectraplotGraphBlackbody } from '../Components/Blackbody/SpectraplotGraphBlackbody';
import SaveIcon from '@mui/icons-material/Save';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import * as htmlToImage from 'html-to-image';
import FileSaver from 'file-saver';
import './style.css';
import useSpectraplotBlackbodyState from '../Functions/BlackbodyStore';
import { SpecConsole } from '../Components/SpecConsole';
import { SpecHeader } from '../Components/SpecHeader';
import { BlackbodyInput } from '../Components/Blackbody/BlackbodyInput';
import { BlackbodyGraphControl } from '../Components/Blackbody/BlackbodyGraphControl';




type props = {};


/**
 * 
 * @returns Component for absorption. At this point, this does nothing except for call the graph. It's morese a placeholder for when other kinds are
 * implemented.
 */
export const Blackbody: React.FC<props> = () => {

    const { consoleLog } = useSpectraplotBlackbodyState();
    const ref = useRef<HTMLDivElement>(null);
    const [openDial, setOpenDial] = useState(false);
    const handleOpen = () => setOpenDial(true);
    const handleClose = () => setOpenDial(false);

    // const [getPng, { ref, isLoading }] = useCurrentPng();

    const handleDialClose = () => setOpenDial(false);

    const handleDownload = () => {
        if (ref) {
            htmlToImage.toBlob(ref.current!, { cacheBust: true, })
                .then(function (dataUrl) {
                    if (dataUrl) {
                        FileSaver.saveAs(dataUrl, 'my-node.png');
                    }
                });
            /*  toPng(ref.current!)
                  .then((dataUrl) => {
                      const link = document.createElement('a')
                      link.download = 'my-image-name.png'
                      link.href = dataUrl
                      link.click()
                  })
                  .catch((err) => {
                      console.log(err)
                  });*/
        }
        /*  const tmp = document.getElementById("png-div");
          if (tmp) {
              htmlToImage.toBlob(tmp)
                  .then(function (dataUrl) {
                      if (dataUrl) {
                          FileSaver.saveAs(dataUrl, 'my-node.png');
                      }
                  });
  
          }*/
    }
    const actions = [

        { icon: <InsertPhotoIcon />, name: 'Save PNG', action: handleDownload },
        { icon: <TextSnippetIcon />, name: 'Save CSV', action: handleClose }
    ];

    return <>
        <SpecHeader Title={"Blackbody"} />
        <Grid container>
            <Grid item xs={12}>

                <BlackbodyInput />

            </Grid>
        </Grid>
        <Card sx={{ margin: "1em", paddingBottom: '1em' }}>
            <Grid container>
                <Grid item md={10.5} xs={12} ref={ref} >


                    <SpectraplotGraphBlackbody />


                </Grid>
                <Grid item md={1.5} xs={12} >

                    <BlackbodyGraphControl savePNGCallback={handleDownload} />


                </Grid>
                <Grid item xs={12}>
                    <CurrentPlotsDisplayBlackbody />
                </Grid>
                <Grid item xs={12}>
                    <SpecConsole message={consoleLog} />
                </Grid>

            </Grid>
        </Card>
        <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'fixed', bottom: 16, right: 30 }}
            icon={<SaveIcon />}
            onClose={handleDialClose}
            onOpen={handleOpen}
            open={openDial}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => action.action()}
                />
            ))}
        </SpeedDial>

    </>


}